import classNames from 'classnames';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui-components/Alert';
import InfoCaptiveAndFirmwareModal from 'src/components/InfoCaptiveAndFirmwareModal';
import styles from './SetUpMethod.module.css';
import { CardOfCaptivePortalType } from './CardOfCaptivePortalType';
import { ICaptivePortal } from '../types';
import { IMethodsTypes } from './types';

interface ISetUpMethod {
  defaultCaptivePortal: ICaptivePortal;
  methodsTypes: IMethodsTypes;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
}

const SetUpMethod = ({
  defaultCaptivePortal,
  methodsTypes,
  isFetchedCaptivePortal,
  isLoadingFormCaptive
}: ISetUpMethod) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.setUpMethod'
  });

  const [showDeprecatedFirmwareModal, setShowDeprecatedFirmwareModal] =
    React.useState(false);

  return (
    <Grid fluid>
      <InfoCaptiveAndFirmwareModal
        showModal={showDeprecatedFirmwareModal}
        setShowModal={setShowDeprecatedFirmwareModal}
      />
      <Row>
        <Col xs={12} className="mb-7 d-flex flex-column">
          <div>
            <h2
              className={classNames([
                'title-xl-base text-uppercase',
                styles.titlePage
              ])}
              style={{ color: 'var(--color-neutral-dark-3)' }}>
              {t('Configurar Método')}
            </h2>
          </div>
          <div className="mt-3">
            <span className={classNames(['text-base-lg', styles.subtitlePage])}>
              {t(
                "O método escolhido, quando ativo, determinará a forma de autenticação dos clientes nas redes wireless deste ambiente definidas em 'Wireless vinculadas'."
              )}
            </span>
          </div>
          {!defaultCaptivePortal.mode && (
            <div className="mt-3">
              <Alert type="info">
                <div>
                  <span>
                    {t(
                      'Você não tem nenhum Captive ativo neste ambiente. O Captive só pode ser ativado se os equipamentos estiverem com o firmware atualizado'
                    )}
                  </span>
                  {'. '}
                  <span
                    className={classNames([styles.customLink])}
                    aria-hidden
                    onClick={() => {
                      setShowDeprecatedFirmwareModal(true);
                    }}>
                    {t('Saiba Mais')}
                  </span>
                </div>
              </Alert>
            </div>
          )}
        </Col>
      </Row>
      <Row className="d-flex">
        <Col xs={6} className="pr-2 pb-4">
          <CardOfCaptivePortalType
            {...methodsTypes?.password}
            descriptionType={t(
              'Gere uma senha única como método de autenticação para todos seus clientes'
            )}
            id="password"
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            isLoadingFormCaptive={isLoadingFormCaptive}
            redirectTo={`/captive-portal/${defaultCaptivePortal?.id}/simple-password/`}
            showWarningFirmware={
              !defaultCaptivePortal.mode &&
              defaultCaptivePortal.is_blocked_to_activate
            }
          />
        </Col>
        {/* <Col xs={6} className="pl-2 pb-4">
          <CardOfCaptivePortalType
            {...methodsTypes?.individual}
            descriptionType={t(
              'Crie cadastros individuais detalhados como método de autenticação e gerencie seu acesso à rede'
            )}
            id="individual"
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            isLoadingFormCaptive={isLoadingFormCaptive}
            redirectTo={`/captive-portal/${defaultCaptivePortal?.id}/individual-record/`}
            showWarningFirmware={
              !defaultCaptivePortal.mode &&
              defaultCaptivePortal.is_blocked_to_activate
            }
          />
        </Col> */}
      </Row>
      {/* <Row className="d-flex">
        <Col xs={6} className="pr-2 pb-4">
          <CardOfCaptivePortalType
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            {...methodsTypes?.individual}
            redirectTo={`/captive-portal/${defaultCaptivePortal?.id}/individual-record/`}
            isLoadingFormCaptive={isLoadingFormCaptive}
          />
        </Col>
        <Col xs={6} className="pl-2 pb-4 ">
          <CardOfCaptivePortalType
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            {...methodsTypes?.voucher}
            redirectTo={`/captive-portal/${defaultCaptivePortal?.id}/voucher/`}
            isLoadingFormCaptive={isLoadingFormCaptive}
          />
        </Col>
      </Row> */}
    </Grid>
  );
};

export { SetUpMethod };

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import { IDataPlace } from 'src/services/api/urls/sites/types';
import api from 'src/services/api';
import Modal from 'ui-components/Modal';
import InputWithValidation from 'src/components/InputWithValidation';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { IErrorResponse } from '../../Sites';
import { ISelectedSiteData } from '../../Sites/types';
import { IPlaceData, IPlaceItemPage, IResponsePutPlaceData } from '../types';

interface IModalCreateOrEditPlace {
  selectedSite: ISelectedSiteData;
  namePlace: string;
  createOrEditModalType: 'edit' | 'create';
  setCreateOrEditModalType: Dispatch<SetStateAction<'edit' | 'create'>>;
  idOfPlaceToEdit: string;
  setIdOfPlaceToEdit: Dispatch<SetStateAction<string>>;
  showModalCreateOrEditPlace: boolean;
  setShowModalCreateOrEditPlace: Dispatch<SetStateAction<boolean>>;
  setIsAddingPlace: Dispatch<SetStateAction<boolean>>;
  onCreatingPlace: () => void;
  onEditingPlace: () => void;
  setPlaceIdBeingEditedOrDeleted: Dispatch<SetStateAction<string | null>>;
  setPlaces: React.Dispatch<React.SetStateAction<IPlaceItemPage[]>>;
}

const ModalCreateOrEditPlace = (props: IModalCreateOrEditPlace) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const {
    selectedSite,
    namePlace,
    createOrEditModalType,
    setCreateOrEditModalType,
    idOfPlaceToEdit,
    setIdOfPlaceToEdit,
    showModalCreateOrEditPlace,
    setShowModalCreateOrEditPlace,
    setIsAddingPlace,
    onCreatingPlace,
    onEditingPlace,
    setPlaceIdBeingEditedOrDeleted,
    setPlaces
  } = props;
  const { t } = useTranslation('translations', { keyPrefix: 'site.place' });
  const { addToast } = useToast();
  const {
    control,
    clearErrors,
    setValue,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    watch
  } = useForm<IDataPlace>({
    defaultValues: {
      place: {
        name: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const addPlaceMutation = useMutation(
    (data: IDataPlace) => {
      if (createOrEditModalType === 'create') {
        return api.sites.places.post(data, selectedSite.id);
      }

      return api.sites.places.put(data, selectedSite.id, idOfPlaceToEdit);
    },
    {
      onSuccess: ({ data }: { data: IResponsePutPlaceData }) => {
        if (createOrEditModalType === 'create') {
          onCreatingPlace();
          addToast('success', t('Ambiente criado com sucesso'));
        } else {
          setPlaces((prev) => {
            const newPlaces = prev.map((place) => {
              if (place.id === idOfPlaceToEdit) {
                return {
                  ...place,
                  name: data.place.name
                };
              }

              return place;
            });

            return newPlaces;
          });
          addToast('success', t('Ambiente editado com sucesso'));
          onEditingPlace();
        }
        setCreateOrEditModalType('create');
        setIdOfPlaceToEdit('');
        reset();
        setShowModalCreateOrEditPlace(false);
        setDisableSubmitButton(false);
      },
      onError: (error: IErrorResponse) => {
        setIsAddingPlace(false);
        setDisableSubmitButton(false);
        const { status, data } = error.response;

        if (status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (status === 304 || status === 404) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (status >= 400 && status <= 600 && data.message) {
          for (const item of data.message) {
            const field = item.attr.pop() as 'place.name';
            setError(`place.${field as keyof IPlaceData}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }
        }
      }
    }
  );

  const isFormEquals =
    useFormCompare({
      initialValues: {
        name: namePlace
      },
      currentValues: watch('place')
    }) && createOrEditModalType === 'edit';

  const setInitialValues = () => {
    if (createOrEditModalType === 'edit') {
      setValue('place.name', namePlace);
    } else {
      reset();
    }
  };

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePlace, createOrEditModalType]);

  const onSubmit = handleSubmit((data: IDataPlace) => {
    if (createOrEditModalType === 'create') {
      setIsAddingPlace(true);
    } else {
      setPlaceIdBeingEditedOrDeleted(idOfPlaceToEdit);
    }

    setDisableSubmitButton(true);
    addPlaceMutation.mutate(data);
  });

  const modalCreateOrEditTexts = {
    edit: {
      title: t('EDITAR AMBIENTE')
    },
    create: {
      title: t('CRIAR AMBIENTE')
    }
  };

  return (
    <Modal
      show={showModalCreateOrEditPlace}
      title={modalCreateOrEditTexts[createOrEditModalType].title}
      width="550px"
      actions={[
        {
          disabled: addPlaceMutation.isLoading,
          label: t('CANCELAR'),
          action: () => {
            clearErrors();
            setShowModalCreateOrEditPlace(false);
            setCreateOrEditModalType('create');
            setValue('place.name', '');
          }
        },
        {
          label: t(modalCreateOrEditTexts[createOrEditModalType].title),
          action: onSubmit,
          isLoading: disableSubmitButton,
          disabled: isFormEquals || disableSubmitButton || errors.place
        }
      ]}>
      <form onSubmit={onSubmit}>
        <InputWithValidation
          control={control}
          controllerProps={{
            name: 'place.name',
            rules: {
              required: {
                value: true,
                message: t('Campo obrigatório')
              },
              maxLength: {
                value: 32,
                message: t(
                  'O nome do ambiente precisa ter entre 1 e 32 caracteres'
                )
              },
              validate: {
                notWhitespaceOnly: (value) =>
                  value.trim().length > 0 || (t('Campo obrigatório') as string),
                notStartsOrEndsWithWhitespace: (value) =>
                  (!value.startsWith(' ') && !value.endsWith(' ')) ||
                  (t(
                    'O nome do ambiente não pode começar nem terminar com um espaço'
                  ) as string)
              }
            }
          }}
          inputProps={{
            id: 'input-place-name'
          }}
          errors={errors?.place?.name}
          label={t('Nome do ambiente:')}
          placeholder={t('Ex.: Sala de reunião')}
        />
      </form>
    </Modal>
  );
};

export { ModalCreateOrEditPlace };

import React from 'react';
import api from 'src/services/api';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useTemplate } from 'src/hooks/useTemplate';
import { IAccessToken } from 'src/services/api/urls/auth/types';
import { useNavigate } from 'react-router-dom';
import { IPlaceResponseUnique } from 'src/services/api/urls/sites/types';
import { IErrorResponse } from '../Sites';

const FirstDevice = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translations', { keyPrefix: 'devices' });
  const [accessToken] = useLocalStorage<IAccessToken | undefined>(
    '@manager/access_token'
  );
  useTemplate('fullNavbarWithPlacesSelector');

  const getPlaceUnique = () => {
    return api.sites.hefesto.places.details.get(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const { isFetchedAfterMount } = useQuery('placeUnique', getPlaceUnique, {
    onSuccess: ({ data }: { data: IPlaceResponseUnique }) => {
      if (
        data &&
        data.place &&
        data.place.device_count &&
        data.place.device_count > 0
      ) {
        navigate('/devices');
      }
    },
    onError: (error: IErrorResponse) => {
      if (error.response.status === 404) {
        navigate('/error/not_found');
      }
    }
  });

  return (
    <div>
      {isFetchedAfterMount && (
        <EmptyMessage
          height="calc(100vh - 150px)"
          title={t('Nenhum equipamento')}
          subtitle={t(
            'Ainda não há equipamento disponível neste ambiente. Experimente adicionar um novo'
          )}
          action={() => navigate('/first-device/add')}
          id="add-first-device"
          buttonText={t('ADICIONAR EQUIPAMENTO')}
        />
      )}
    </div>
  );
};

export { FirstDevice };

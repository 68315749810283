import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { isNull } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import styles from './PreViewCaptivePortal.module.css';
import { IPersonalizeForm } from '../types';
import { ICaptivePortal } from '../../types';
import { IMethodsTypes } from '../../SetUpMethod/types';
import { FormForPreviewCaptive } from '../../FormForPreviewCaptive';

interface IProps {
  defaultCaptivePortal: ICaptivePortal;
  viewFormData: IPersonalizeForm;
  isFetchedCaptivePortal: boolean;
  methodsTypes: IMethodsTypes;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const PreViewCaptivePortal = ({
  defaultCaptivePortal,
  viewFormData,
  isFetchedCaptivePortal,
  methodsTypes,
  setActiveTab
}: IProps) => {
  const METHOD_TAB = 0;

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.personalize.viewCaptivePortal'
  });

  const navigate = useNavigate();

  const checkHasCaptiveAndMethod =
    !isNull(defaultCaptivePortal) && !isNull(defaultCaptivePortal.mode);

  const redirectToMethodType = () => {
    switch (defaultCaptivePortal && defaultCaptivePortal.mode) {
      case 'password':
        return navigate(
          `/captive-portal/${defaultCaptivePortal.id}/simple-password`
        );
      case 'individual':
        return navigate(
          `/captive-portal/${defaultCaptivePortal.id}/individual-record`
        );
      case 'voucher':
        return navigate(`/captive-portal/${defaultCaptivePortal.id}/voucher`);
      case 'cpf':
        return navigate(
          `/captive-portal/${defaultCaptivePortal.id}/cpf-record`
        );
      default:
        return navigate('/captive-portal');
    }
  };

  return (
    <>
      <div className="d-flex align-center">
        <span className="text-sm-sm">{t('Método ativo')}: </span>
        {isFetchedCaptivePortal ? (
          <div>
            {defaultCaptivePortal?.mode ? (
              <span
                id="btnRedirectMethodActiveCaptivePortal"
                onClick={() => redirectToMethodType()}
                className={classNames(
                  styles.btnRedirect,
                  'ml-1',
                  'text-bold',
                  'text-sm-sm'
                )}
                aria-hidden="true">
                {methodsTypes[defaultCaptivePortal?.mode].nameType}
              </span>
            ) : (
              <span className="ml-1 text-sm-sm text-bold">{t('nenhum')}</span>
            )}
          </div>
        ) : (
          <Skeleton width={100} height={20} />
        )}
      </div>
      {checkHasCaptiveAndMethod ? (
        <FormForPreviewCaptive
          viewFormData={viewFormData}
          methodCaptive={defaultCaptivePortal.mode || null}
          isFetchedCaptivePortal={isFetchedCaptivePortal}
        />
      ) : (
        <EmptyMessage
          title={t('Nenhuma visualização')}
          subtitle={
            <div>
              <span>{t('Acesse a aba')}</span>
              <span
                id="btnRedirectSetUpMethodCaptivePortal"
                onClick={() => setActiveTab(METHOD_TAB)}
                className={classNames(styles.btnRedirect, 'ml-1', 'text-bold')}
                aria-hidden="true">
                {t('Configurar método')}{' '}
              </span>
              <span>{t('e ative um dos métodos de Captive Portal')}</span>
            </div>
          }
          id="empty-message-default-view-form"
          minHeight="190px"
          height="460px"
          width="100%"
        />
      )}
    </>
  );
};

export { PreViewCaptivePortal };

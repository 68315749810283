import React, { useState } from 'react';
import classNames from 'classnames';
import { Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdDelete, MdKeyboardBackspace } from 'react-icons/md';
import { Breadcrumbs, Crumb } from 'ui-components/Breadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useTemplate } from 'src/hooks/useTemplate';
import { useForm } from 'react-hook-form';
import Card from 'ui-components/Card';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import { useToast } from 'src/hooks/useToast';
import Steps from 'ui-components/Steps';
import Modal from 'ui-components/Modal';
import { useMutation } from 'react-query';
import api from 'src/services/api';
import { AclType, IAddAcl } from 'src/services/api/urls/devices/types';
import { ActiveTabSitesEnum } from '../../Sites/types';
import styles from './AddAccessLists.module.css';
import CustomersTab from './CustomersTab';
import WirelessTab from './WirelessTab';
import { ICustomer } from './CustomersTab/types';
import CustomerAccessModal from './CustomersTab/CustomerAccessModal';
import AddCustomersModal from './AddCustomersModal';
import AddWirelessModal from './AddWirelessModal';
import { IWireless } from './WirelessTab/types';
import DeleteCustomersModal from './DeleteCustomersModal';
import DeleteWirelessModal from './DeleteWirelessModal';
import InfoStep from './Steps/InfoStep';
import CustomersStep from './Steps/CustomersStep';
import WirelessStep from './Steps/WirelessStep';
import SummaryStep from './Steps/SummaryStep';
import PlaceListModal from './WirelessTab/PlaceListModal';

interface IErrorResponseMessage {
  attr: string[];
  msg: string;
}
export interface IErrorResponse {
  response: {
    status: number;
    data: { message: IErrorResponseMessage[] };
  };
}

const AddAccessLists = () => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const navigate = useNavigate();
  const [activeTabSiteIndex] = useLocalStorage('@manager/activeTabSite');
  const { id: idSite } = useParams();

  const [step, setStep] = useState(0);

  const [showCustomerAccessModal, setShowCustomerAccessModal] = useState(false);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const [showAddWirelessModal, setShowAddWirelessModal] = useState(false);
  const [showPlaceListModal, setShowPlaceListModal] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [wirelessList, setWirelessList] = useState<IWireless[]>([]);

  const [selectedCustomers, setSelectedCustomers] = useState<ICustomer[]>([]);
  const [selectedWireless, setSelectedWireless] = useState<IWireless[]>([]);

  const [showDeleteCustomersModal, setShowDeleteCustomersModal] =
    useState(false);
  const [showDeleteWirelessModal, setShowDeleteWirelessModal] = useState(false);

  const {
    control,
    setValue,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    setError
  } = useForm({
    defaultValues: {
      acl: {
        name: '',
        type: AclType.ALLOW_LIST
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const addAclMutation = useMutation(
    (data: IAddAcl) => {
      return api.devices.acls.post(data, idSite);
    },
    {
      onSuccess: () => {
        navigate(`/sites/${idSite}/access-lists`);
        addToast('success', t('Lista de acesso adicionada com sucesso'));
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          for (const item of error.response.data.message) {
            const field = item.attr.pop() as 'name';
            setError(`acl.${field}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }
        }
      }
    }
  );

  const onSubmit = handleSubmit((data) => {
    addAclMutation.mutate({
      acl: data.acl,
      client_list: customers,
      wireless_list: wirelessList
    });
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('acl.type', event.target.defaultValue as AclType);
  };

  const comeBackAccessLists = () => {
    navigate(`/sites/${idSite}/access-lists`, { replace: true });
  };

  const tabs = [
    {
      label: t('Wireless'),
      component: (
        <WirelessTab
          wirelessList={wirelessList}
          selectedWireless={selectedWireless}
          setSelectedWireless={setSelectedWireless}
          setShowDeleteWirelessModal={setShowDeleteWirelessModal}
          setShowAddWirelessModal={setShowAddWirelessModal}
        />
      )
    },
    {
      label: t('Clientes'),
      component: (
        <CustomersTab
          customers={customers}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          setShowDeleteCustomersModal={setShowDeleteCustomersModal}
          setShowAddCustomerModal={setShowAddCustomerModal}
        />
      )
    }
  ];

  useTemplate('avatarNavbar');

  const nextStep = () => {
    setStep((prev) => prev + 1);
    setSelectedCustomers([]);
    setSelectedWireless([]);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
    setSelectedCustomers([]);
    setSelectedWireless([]);
  };

  const renderConfirmCancelModal = () => {
    return (
      <Modal
        width="465px"
        show={showCancelModal}
        actions={[
          {
            label: t('NÃO'),
            action: () => setShowCancelModal(false)
          },
          {
            label: t('SIM'),
            action: () => comeBackAccessLists()
          }
        ]}>
        <span>{t('Você realmente deseja cancelar a adição da lista')}?</span>
        <span className="text-bold">{t('Todo progresso será perdido')}.</span>
      </Modal>
    );
  };

  const renderBackAndCancelButton = () => {
    return (
      <>
        <Button
          ghost
          className="mr-3"
          id="btn-cancel"
          onClick={() => setShowCancelModal(true)}>
          {t('CANCELAR')}
        </Button>

        <Button id="button-info-next-step" onClick={prevStep} secondary>
          {t('VOLTAR')}
        </Button>
      </>
    );
  };

  const stepsPages = [
    <InfoStep
      control={control}
      errors={errors}
      isValid={isValid}
      handleRadioChange={handleRadioChange}
      nextStep={nextStep}
      setShowCancelModal={setShowCancelModal}
      watch={watch}
      setError={setError}
    />,
    <WirelessStep
      nextStep={nextStep}
      renderBackAndCancelButton={renderBackAndCancelButton}
      selectedWireless={selectedWireless}
      setSelectedWireless={setSelectedWireless}
      setShowAddWirelessModal={setShowAddWirelessModal}
      setShowPlaceListModal={setShowPlaceListModal}
      wirelessList={wirelessList}
    />,
    <CustomersStep
      customers={customers}
      nextStep={nextStep}
      renderBackAndCancelButton={renderBackAndCancelButton}
      selectedCustomers={selectedCustomers}
      setSelectedCustomers={setSelectedCustomers}
      setShowAddCustomerModal={setShowAddCustomerModal}
      setShowCustomerAccessModal={setShowCustomerAccessModal}
    />,
    <SummaryStep
      control={control}
      customers={customers}
      errors={errors}
      isValid={isValid}
      handleRadioChange={handleRadioChange}
      setShowCancelModal={setShowCancelModal}
      tabs={tabs}
      wirelessList={wirelessList}
      onSubmit={onSubmit}
      isLoading={addAclMutation.isLoading}
    />
  ];

  const isSummaryStep = step === stepsPages.length - 1;

  return (
    <div className={styles.shellAccessLists}>
      {renderConfirmCancelModal()}

      <CustomerAccessModal
        show={showCustomerAccessModal}
        onClose={() => setShowCustomerAccessModal(false)}
      />

      <AddCustomersModal
        show={showAddCustomerModal}
        onClose={() => setShowAddCustomerModal(false)}
        listName={watch('acl.name')}
        setCustomers={setCustomers}
        customers={customers}
      />

      <PlaceListModal
        show={showPlaceListModal}
        onClose={() => setShowPlaceListModal(false)}
      />

      <AddWirelessModal
        show={showAddWirelessModal}
        onClose={() => setShowAddWirelessModal(false)}
        listName={watch('acl.name')}
        setWirelessList={setWirelessList}
        wirelessList={wirelessList}
      />

      <DeleteCustomersModal
        show={showDeleteCustomersModal}
        listName={watch('acl.name')}
        onClose={() => setShowDeleteCustomersModal(false)}
        selectedCustomers={selectedCustomers}
        setCustomersList={setCustomers}
        onDeleted={() => setSelectedCustomers([])}
      />

      <DeleteWirelessModal
        show={showDeleteWirelessModal}
        listName={watch('acl.name')}
        onClose={() => setShowDeleteWirelessModal(false)}
        selectedWireless={selectedWireless}
        setWirelessList={setWirelessList}
        onDeleted={() => setSelectedWireless([])}
      />

      <Grid fluid>
        <Breadcrumbs>
          <Crumb>
            <div className="d-flex justify-start fit-width align-center">
              <button
                type="button"
                id="btn-back-to-sites"
                aria-label="Voltar para página de Locais"
                className={classNames([styles.backToAccessListsButton, 'mr-3'])}
                onClick={() => comeBackAccessLists()}>
                <MdKeyboardBackspace size={16} />
              </button>

              <Link to="/sites">
                {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  activeTabSiteIndex === ActiveTabSitesEnum.SHARED_WITH_ME
                    ? t('Compartilhados comigo')
                    : t('Meus locais')
                }
              </Link>
            </div>
          </Crumb>
          <Crumb>
            <Link to={`/sites/${idSite}/access-lists`}>
              {t('Listas de Acesso (ACL)')}
            </Link>
          </Crumb>
          <Crumb>
            {isSummaryStep
              ? t('Resumo Lista de Acesso')
              : t('Adicionar Lista de acesso')}
          </Crumb>
        </Breadcrumbs>

        <Row className="mt-7 mb-11">
          <div className="d-flex justify-between align-center">
            <h1
              className={classNames(['title-sm-lg', styles.title])}
              id="add-access-list-title-page">
              {isSummaryStep ? t('Resumo') : t('Adicionar Lista de Acesso')}
            </h1>
          </div>
        </Row>

        {!isSummaryStep && (
          <div className="fit-width pt-3">
            <div className={styles.shellSteps}>
              <Steps
                current={step}
                items={[
                  t('Informações da lista'),
                  t('Adicionar Wireless'),
                  t('Adicionar Clientes'),
                  t('Resumo')
                ]}
              />
            </div>
          </div>
        )}

        <div className={classNames([!isSummaryStep && styles.shellCard])}>
          {step === 1 &&
            wirelessList.length > 0 &&
            selectedWireless.length > 0 && (
              <ButtonTextOutside
                value={`${t('Excluir wireless')} (${selectedWireless?.length})`}
                className="text-uppercase mb-4 ml-7">
                <Button
                  onClick={() => setShowDeleteWirelessModal(true)}
                  id="button-delete-wireless"
                  rounded
                  color="var(--color-status-critical-base)">
                  <MdDelete size={22} />
                </Button>
              </ButtonTextOutside>
            )}

          {step === 1 &&
            wirelessList.length > 0 &&
            selectedWireless.length === 0 && (
              <div className="d-flex justify-end mb-4">
                <ButtonTextOutside
                  id="text-btn-open-modal-to-create-wireless"
                  value={t('ADICIONAR WIRELESS')}
                  className="text-uppercase mr-7">
                  <Button
                    id="btn-open-modal-to-create-wireless"
                    onClick={() => setShowAddWirelessModal(true)}
                    disabled={false}
                    rounded>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              </div>
            )}
          {step === 2 &&
            customers.length > 0 &&
            selectedCustomers.length === 0 && (
              <div className="d-flex justify-end mb-4">
                <ButtonTextOutside
                  id="text-btn-open-modal-to-create-customer"
                  value={t('ADICIONAR CLIENTE')}
                  className="text-uppercase mr-7">
                  <Button
                    id="btn-open-modal-to-create-customer"
                    onClick={() => setShowAddCustomerModal(true)}
                    disabled={false}
                    rounded>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              </div>
            )}
          {step === 2 &&
            customers.length > 0 &&
            selectedCustomers.length > 0 && (
              <ButtonTextOutside
                value={`${
                  selectedCustomers.length > 1
                    ? t('Excluir clientes')
                    : t('Excluir cliente')
                } (${selectedCustomers?.length})`}
                className="text-uppercase mb-4 ml-7">
                <Button
                  onClick={() => setShowDeleteCustomersModal(true)}
                  id="button-delete-customer"
                  rounded
                  color="var(--color-status-critical-base)">
                  <MdDelete size={22} />
                </Button>
              </ButtonTextOutside>
            )}

          <Card>{stepsPages[step]}</Card>
        </div>
      </Grid>
    </div>
  );
};

export default AddAccessLists;

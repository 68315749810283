import React from 'react';
import classNames from 'classnames';
import styles from './Alert.module.css';
import { MdCheckCircle, MdInfo, MdWarning } from 'react-icons/md';

const icons = {
  success: (
    <MdCheckCircle
      color="var(--color-status-ok-base)"
      className={styles.icon}
    />
  ),
  warning: (
    <MdWarning color="var(--color-status-alert-base)" className={styles.icon} />
  ),
  error: (
    <MdWarning
      size={24}
      color="var(--color-status-critical-base)"
      className={styles.icon}
    />
  ),
  info: <MdInfo color="var(--color-product-light)" className={styles.icon} />
};

const Alert = ({
  type = 'info',
  text = false,
  width = '',
  children,
  ...props
}) => {
  const convertWidth = (widthString) => {
    if (`${widthString}`.includes('%') || `${widthString}`.includes('px')) {
      return widthString;
    }

    return `${widthString}px`;
  };

  return (
    <div
      {...props}
      className={classNames([styles.alert, text && styles.text, styles[type]])}
      style={{ width: !!width ? convertWidth(width) : '100%' }}>
      {icons[type]}
      {children}
    </div>
  );
};

export { Alert };

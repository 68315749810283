import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';

interface IInfoCaptiveAndFirmwareModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const InfoCaptiveAndFirmwareModal = ({
  showModal,
  setShowModal
}: IInfoCaptiveAndFirmwareModal) => {
  const MIN_FIRMWARE_CAPTIVE_V3 =
    process.env.REACT_APP_MIN_SUPPORTED_CAPTIVE_V3_FIRMWARE_VERSION;

  const { t } = useTranslation('translations', {
    keyPrefix: 'infoCaptiveAndFirmwareModal'
  });

  const onCloseModal = () => {
    setShowModal(false);
  };

  const actionsModal = [
    {
      label: t('ok'),
      action: onCloseModal
    }
  ];
  return (
    <Modal
      closable
      actions={actionsModal}
      show={showModal}
      title={t('Captive Portal e Versões de firmware')}
      onClose={onCloseModal}
      width="550px"
      height="auto"
      key="deprecated-firmware-to-captive">
      <p className="mb-5">
        {t(
          'Para ativar o Captive Portal no ambiente é necessário que todos os equipamentos adicionados nele estejam com a versão de firmware atualizada para'
        )}
        :
      </p>
      <p>
        {t('Linha AC versão')} {MIN_FIRMWARE_CAPTIVE_V3} {t('ou superior')};
      </p>
      <p>{t('Linha AX ainda não compatível')}.</p>
      <p className="mt-5 mb-5">
        {t(
          'Ao tentar adicionar um equipamento com uma versão de firmware inferior à esta, em um ambiente com o Captive ativo, sua adição será recusada até que o seu firmware seja devidamente atualizado. Portanto, sugerimos dois modos para você conseguir atualizar o firmware deste equipamento'
        )}
        :
      </p>
      <ol className="pl-4">
        <li>
          {t(
            'Acessando com o IP do equipamento na sua web local, e então atualizando a sua versão de firmware. Ou'
          )}
          ;
        </li>
        <li>
          {t(
            'Pelo próprio inMaster, ao desativar o Captive no ambiente em questão, você pode adicionar o equipamento neste ambiente, atualizar o firmware e depois voltar a ativar o Captive novamente'
          )}
          .
        </li>
      </ol>
    </Modal>
  );
};

export default InfoCaptiveAndFirmwareModal;

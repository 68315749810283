import { ICPFCaptiveForm } from 'src/pages/CaptivePortal/CPFRecord/DrawerCPF/types';
import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';
import {
  IUpdateCaptivePortal,
  ICreateSimplePassword,
  IGetActivePassword,
  IGetCaptivePortal,
  IGetSimplePasswordHistoric
} from './types';

export class CaptivePortalAPI implements IRequestMethods {
  url = '/captive-portals';

  captivePortalUrl = (siteId: string, placeId: string) =>
    `${this.url}/sites/${siteId}/places/${placeId}/captive-portals`;

  hefestoCaptivePortalUrl = (siteId: string, placeId: string) =>
    `/hefesto/captive_portals/sites/${siteId}/places/${placeId}/captive-portals`;

  simplePasswordUrl = (siteId: string, placeId: string, captiveId: string) =>
    `${this.captivePortalUrl(siteId, placeId)}/${captiveId}/simple-passwords`;

  captive = {
    getOrCreate: (siteId: string, placeId: string) => {
      return axiosInstance.get<IGetCaptivePortal>(
        `${this.hefestoCaptivePortalUrl(siteId, placeId)}/first/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    put: (
      siteId: string,
      placeId: string,
      data: IUpdateCaptivePortal,
      captive_id = '',
      update_header_image = false,
      restore_header_image_to_default = false
    ) => {
      return axiosInstance.put(
        `${this.hefestoCaptivePortalUrl(siteId, placeId)}/${captive_id}/`,
        data,
        {
          headers: getAuthHeaders(),
          params: {
            update_header_image,
            restore_header_image_to_default
          }
        }
      );
    }
  };

  simplePassword = {
    get: (siteId: string, placeId: string, captiveId: string) => {
      return axiosInstance.get<IGetSimplePasswordHistoric>(
        `${this.simplePasswordUrl(siteId, placeId, captiveId)}/history/`,
        {
          headers: getAuthHeaders()
        }
      );
    },

    deleteListOfSimplePasswords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      passwordsToDelete: string[]
    ) => {
      const urlString = `${this.simplePasswordUrl(
        siteId,
        placeId,
        captiveId
      )}/history/`;
      return axiosInstance.delete(urlString, {
        data: {
          history_ids: passwordsToDelete
        },
        headers: getAuthHeaders()
      });
    },

    getActivePassword: (siteId: string, placeId: string, captiveId: string) => {
      return axiosInstance.get<IGetActivePassword>(
        `${this.simplePasswordUrl(siteId, placeId, captiveId)}/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    post: (
      data: ICreateSimplePassword,
      siteId: string,
      placeId: string,
      captiveId: string
    ) => {
      return axiosInstance.post(
        `${this.simplePasswordUrl(siteId, placeId, captiveId)}/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },
    updateActivePassword: (
      siteId: string,
      placeId: string,
      captiveId: string,
      passwordId: string
    ) => {
      return axiosInstance.post(
        `${this.simplePasswordUrl(
          siteId,
          placeId,
          captiveId
        )}/history/${passwordId}/renew/`,
        {},
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  CPFcaptive(data: ICPFCaptiveForm) {
    return axiosInstance.post(`${this.url}/cpfcaptive/`, data, {
      headers: getAuthHeaders()
    });
  }
}

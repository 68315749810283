import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { useTemplate } from 'src/hooks/useTemplate';
import Card from 'ui-components/Card';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Button } from 'ui-components/Button';
import { useMutation, useQuery } from 'react-query';
import api from 'src/services/api';
import { useToast } from 'src/hooks/useToast';
import Skeleton from 'react-loading-skeleton';
import _, { isNull } from 'lodash';
import { useNotification } from 'src/hooks/useNotification';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { Tooltip } from 'inmaster-ui';
import {
  IAdvancedWirelessData,
  IAdvancedWirelessForm,
  IUpdateAdvancedWireless,
  defaultValuesAdvancedWireless
} from './types';

import styles from './AdvancedWireless.module.css';
import { FastRoamingSection } from './FastRoamingSection';
import { FactoryStandardSection } from './FactoryStandardSection';
import { SmartHandoverSection } from './SmartHandoverSection';
import { BandSteeringSection } from './BandSteeringSection';
import { BeamformingSection } from './BeamformingSection';
import { IDevicePaginationTable } from '../Devices/types';
import { IErrorResponse } from '../Sites';

const AdvancedWireless = () => {
  useTemplate('menuAndFullNavbar');

  const { accessToken } = useAuth();

  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless'
  });

  const { addToast } = useToast();

  const { triggerNotificationAnimationWithText } = useNotification();

  const navigate = useNavigate();

  const [showCancelModal, setShowCancelModal] = useState(false);

  const [
    countDevicesWithAssociatedWireless,
    setCountDevicesWithAssociatedWireless
  ] = useState(0);

  const [showFactoryStandardModal, setShowFactoryStandardModal] =
    useState(false);

  const [hasWireless, setHasWireless] = useState(false);
  const bandSteeringMethodDefault = 'loadbalance';

  const [cachedAdvancedWireless, setCachedAdvancedWireless] =
    useState<IAdvancedWirelessForm>({} as IAdvancedWirelessForm);

  const { control, watch, reset, getValues } = useForm<IAdvancedWirelessForm>({
    defaultValues: {
      ...defaultValuesAdvancedWireless
    }
  });

  const getAdvancedWireless = () => {
    return api.wireless.advanced.get();
  };

  const { isFetchedAfterMount, isLoading } = useQuery(
    ['advencedWirelessQuery', accessToken?.place_id],
    getAdvancedWireless,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: IAdvancedWirelessData }) => {
        const advancedWirelessData = {
          assisted_roaming: false,
          fast_roaming: data.wireless_advanced.fast_roaming,
          smart_handover: false,
          main_acess_point: undefined,
          band_steering: !isNull(data.wireless_advanced.band_steering),
          band_steering_method: !isNull(data.wireless_advanced.band_steering)
            ? data.wireless_advanced.band_steering
            : bandSteeringMethodDefault,
          mu_mimo: data.wireless_advanced.mu_mimo,
          beamforming: data.wireless_advanced.beamforming
        };
        reset(advancedWirelessData);
        setCachedAdvancedWireless(advancedWirelessData);
      }
    }
  );

  useQuery(['getDevices', accessToken?.place_id], api.devices.hefesto.get, {
    retry: false,
    onSuccess: ({ data }: { data: IDevicePaginationTable }) => {
      const devicesWithAssociatedWirelessList = data.devices.filter(
        (device) => device.has_associated_wireless
      );

      setCountDevicesWithAssociatedWireless(
        devicesWithAssociatedWirelessList.length
      );
    }
  });

  const getWireless = () => {
    return api.wireless.get();
  };

  const {
    isLoading: isLoadingWireless,
    isFetchedAfterMount: isFetchedAfterMountWireless
  } = useQuery(['getWireless'], getWireless, {
    onSuccess: ({ data }) => {
      if (data.wireless.length > 0) {
        setHasWireless(true);
      }
    }
  });

  const genereteSingularOrPluralAnimationText = () => {
    return countDevicesWithAssociatedWireless === 1
      ? t('CONFIGURANDO EQUIPAMENTO')
      : t('CONFIGURANDO EQUIPAMENTOS');
  };

  const updateAdvancedWireless = (data: IUpdateAdvancedWireless) => {
    return api.wireless.advanced.put(data);
  };

  const updateAdvancedWirelessMutation = useMutation(updateAdvancedWireless, {
    onSuccess: ({ data }: { data: IAdvancedWirelessData }) => {
      const advancedWirelessData = {
        assisted_roaming: false,
        fast_roaming: data.wireless_advanced.fast_roaming,
        smart_handover: false,
        main_acess_point: undefined,
        band_steering: !isNull(data.wireless_advanced.band_steering),
        band_steering_method: !isNull(data.wireless_advanced.band_steering)
          ? data.wireless_advanced.band_steering
          : bandSteeringMethodDefault,
        mu_mimo: data.wireless_advanced.mu_mimo,
        beamforming: data.wireless_advanced.beamforming
      };
      reset(advancedWirelessData);
      setCachedAdvancedWireless(advancedWirelessData);
      addToast('success', t('Configurações alteradas com sucesso'));

      if (countDevicesWithAssociatedWireless > 0) {
        triggerNotificationAnimationWithText(
          genereteSingularOrPluralAnimationText()
        );
      }
    },
    onError(error: IErrorResponse) {
      if (error.response.status === 304) {
        addToast(
          'error',
          t(
            'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
          )
        );
      } else {
        addToast(
          'error',
          t(
            'Estamos passando por uma instabilidade, tente novamente mais tarde'
          )
        );
      }
    }
  });

  const onHandleSubmit = () => {
    const data = {
      wireless_advanced: {
        fast_roaming: watch('fast_roaming'),
        /* O beamforming tem uma dependencia com o mu_mimo, que quando o mu_mimo ativado
         *  o beamforming tem que permanecer ativado também e não pode mudar de estado.
         */
        beamforming: watch('mu_mimo') || watch('beamforming'),
        mu_mimo: watch('mu_mimo'),
        band_steering: !watch('band_steering')
          ? null
          : watch('band_steering_method')
      }
    } as IUpdateAdvancedWireless;
    updateAdvancedWirelessMutation.mutate(data);
  };

  const putFactoryStandard = () => {
    return api.wireless.advanced.putFactoryStandard(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const restoreFactoryStandardMutation = useMutation(putFactoryStandard, {
    onSuccess: ({ data }: { data: IAdvancedWirelessData }) => {
      const advancedWirelessData = {
        assisted_roaming: false,
        fast_roaming: data.wireless_advanced.fast_roaming,
        smart_handover: false,
        main_acess_point: undefined,
        band_steering: !isNull(data.wireless_advanced.band_steering),
        band_steering_method: !isNull(data.wireless_advanced.band_steering)
          ? data.wireless_advanced.band_steering
          : bandSteeringMethodDefault,
        mu_mimo: data.wireless_advanced.mu_mimo,
        beamforming: data.wireless_advanced.beamforming
      };

      reset(advancedWirelessData);

      setCachedAdvancedWireless(advancedWirelessData);

      addToast('success', t('Configurações alteradas com sucesso'));

      if (countDevicesWithAssociatedWireless > 0) {
        triggerNotificationAnimationWithText(
          genereteSingularOrPluralAnimationText()
        );
      }
    },
    onError(error: IErrorResponse) {
      if (error.response.status === 304) {
        addToast(
          'error',
          t(
            'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
          )
        );
      } else {
        addToast(
          'error',
          t(
            'Estamos passando por uma instabilidade, tente novamente mais tarde'
          )
        );
      }
    }
  });

  const onCancelFactoryStandardModal = () => {
    setShowFactoryStandardModal(false);
  };

  const onRestoreFactoryStandardModal = () => {
    restoreFactoryStandardMutation.mutate();
    setShowFactoryStandardModal(false);
  };

  const isFormEquals = useFormCompare({
    initialValues: cachedAdvancedWireless,
    currentValues: watch()
  });

  const cancelModal = () => {
    return (
      <ConfirmModal
        showModal={showCancelModal}
        width="550px"
        isLoadingAction={false}
        onCancel={() => {
          setShowCancelModal(false);
        }}
        onConfirm={() => {
          reset();
          setShowCancelModal(false);
        }}>
        {t(
          'Todas as alterações realizadas serão perdidas. Deseja realmente continuar?'
        )}
      </ConfirmModal>
    );
  };

  const compareWithDefaultValues = () => {
    const getValuesForm = getValues();
    return _.isEqual(getValuesForm, defaultValuesAdvancedWireless);
  };

  const renderButtonsForm = () => {
    return (
      <div className="d-flex justify-end">
        {!isFormEquals ? (
          <>
            <Button
              disabled={updateAdvancedWirelessMutation.isLoading}
              ghost
              id="btn-cancel"
              onClick={() => {
                setShowCancelModal(true);
              }}>
              {t('CANCELAR')}
            </Button>
            <Button
              isLoading={updateAdvancedWirelessMutation.isLoading}
              className="ml-2"
              id="btn-save"
              onClick={onHandleSubmit}>
              {t('SALVAR')}
            </Button>
          </>
        ) : (
          <Tooltip
            disabled={!compareWithDefaultValues()}
            place="top"
            id="tooltip-no-changes"
            content={t('Não há alterações para restaurar')}>
            <Button
              outline
              type="button"
              className="ml-2"
              id="btn-factory-standard"
              disabled={compareWithDefaultValues()}
              onClick={() => {
                setShowFactoryStandardModal(true);
              }}>
              {t('Retornar ao padrão')}
            </Button>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderSkeletonForm = (numberOfSkeletons: number) => {
    const skeletons = [];
    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div key={`skeleton-form-${i}`}>
          <div>
            <div className="my-2">
              <Skeleton height={24} width={80} />
            </div>
            <div className="d-flex">
              <Skeleton height={80} width={450} className="mr-3" />
              <Skeleton height={40} width={250} />
            </div>
          </div>
          <hr className={classNames(['mt-7 mb-7', styles.dividerCard])} />
        </div>
      );
    }
    return <div className="d-flex flex-column">{skeletons}</div>;
  };

  return (
    <Grid fluid>
      {cancelModal()}
      <div>
        <Row>
          <Col xs={12} className="mb-7">
            <h2
              className="title-xl-base text-uppercase"
              style={{ color: 'var(--color-neutral-dark-3)' }}>
              {t('OPÇÕES AVANÇADAS')}
            </h2>
          </Col>
          <div className="fit-width">
            {!isLoading &&
              isFetchedAfterMount &&
              !isLoadingWireless &&
              isFetchedAfterMountWireless &&
              !hasWireless && (
                <EmptyMessage
                  title={t('Nenhuma wireless')}
                  subtitle={t(
                    'Ainda não há wireless disponível neste ambiente. Experimente adicionar uma nova'
                  )}
                  id="add-first-wireless"
                  buttonText={t('ADICIONAR WIRELESS')}
                  action={() => navigate('/wireless/add')}
                  height="calc(100vh - 255px)"
                />
              )}

            {isFetchedAfterMount &&
              !isLoading &&
              !isLoadingWireless &&
              hasWireless && (
                <Card className="fit-width d-flex align-center mb-7">
                  <div>
                    <FastRoamingSection controlForm={control} />
                    <hr
                      className={classNames(['mt-7 mb-7', styles.dividerCard])}
                    />
                    <FactoryStandardSection
                      show={showFactoryStandardModal}
                      onCancel={onCancelFactoryStandardModal}
                      onRestore={onRestoreFactoryStandardModal}
                    />
                    <SmartHandoverSection />
                    <BandSteeringSection
                      controlForm={control}
                      valueBandSteering={watch('band_steering')}
                      restoreToCachedOnActive={() => {
                        const bandSteeringMethodIsDifferent =
                          cachedAdvancedWireless.band_steering_method !==
                          watch('band_steering_method');
                        if (bandSteeringMethodIsDifferent) {
                          reset({
                            ...watch(),
                            band_steering_method:
                              cachedAdvancedWireless.band_steering_method
                          });
                        }
                      }}
                    />
                    <hr
                      className={classNames(['mt-7 mb-7', styles.dividerCard])}
                    />
                    <BeamformingSection watch={watch} controlForm={control} />
                    <hr
                      className={classNames(['mt-7 mb-7', styles.dividerCard])}
                    />

                    {renderButtonsForm()}
                  </div>
                </Card>
              )}

            {(!isFetchedAfterMount || isLoading || isLoadingWireless) && (
              <Card className="fit-width d-flex align-center mb-7">
                {renderSkeletonForm(4)}
              </Card>
            )}
          </div>
        </Row>
      </div>
    </Grid>
  );
};

export { AdvancedWireless };

import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useNavigate } from 'react-router-dom';
import { useTemplate } from 'src/hooks/useTemplate';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { MdSearch, MdSettings, MdAdd, MdDelete, MdSync } from 'react-icons/md';
import { InputIconInside } from 'ui-components/InputWrapper';
import { Input } from 'ui-components/Input';
import Label from 'ui-components/Label';
import { useTranslation } from 'react-i18next';
import api from 'src/services/api';
import { IDevice } from 'src/services/api/urls/devices/types';
import Tag from 'ui-components/Tags';
import { useDebounce } from 'use-debounce';
import { generateColumns } from 'src/utils/tableUtils';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import Loading from 'ui-components/Loading';
import {
  IPlace,
  IPlaceResponseUnique
} from 'src/services/api/urls/sites/types';
import TableWithPagination from 'src/components/TableWithPagination';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import { useAuth } from 'src/hooks/useAuth';
import { useNotification } from 'src/hooks/useNotification';
import { Tooltip } from 'inmaster-ui';
import {
  IDevicePaginationTable,
  IDeviceRow,
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from './types';
import styles from './Devices.module.css';
import RestartDeviceModal from './RestartDeviceModal';
import DeleteDeviceModal from './DeleteDeviceModal';
import { IErrorResponse } from '../Sites';

const Devices = () => {
  // Hooks
  const { accessToken } = useAuth();

  const navigate = useNavigate();
  const { deviceIsDeletedObserver } = useNotification();

  useTemplate('menuAndFullNavbar');

  const { t } = useTranslation('translations', { keyPrefix: 'devices' });

  // States
  const [currentPlace, setCurrentPlace] = useState<IPlace>();

  const [devicesPagination, setDevicesPagination] =
    useState<IDevicePaginationTable>({} as IDevicePaginationTable);

  const [search, setSearch] = useState('');

  const [showRestartModal, setShowRestartModal] = useState(false);

  const [selectedDevices, setSelectedDevices] = useState<IDeviceRow[]>([]);

  const [rebootingDevices, setRebootingDevices] = useState<string[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deviceIsDeleting, setDeviceIsDeleting] = useState(false);

  const [isChangingPage, setIsChangingPage] = useState(false);

  const [paginationRequestData, setPaginationRequestData] =
    useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(false);

  // Variables
  const generateColumnsByPlaceType = {
    AP: [
      generateColumns('id', 'ID', 'left', 0, true),
      generateColumns('type_equipment', 'Tipo de equipamento', 'left', 0, true),
      generateColumns('mac', 'MAC', 'left', 0),
      generateColumns('alias', t('Nome'), 'left', 0),
      generateColumns('firmware_version', t('Versão de firmware'), 'left', 0),
      generateColumns('model', t('Modelo'), 'left', 0),
      generateColumns('devices', t('Clientes Conectados'), 'left', 0),
      generateColumns('status', 'Status', 'left', 0),
      generateColumns('actions', t('Ações'), 'left', 0)
    ],
    CFTV: [
      generateColumns('id', 'ID', 'left', 0, true),
      generateColumns('type_equipment', 'Tipo de equipamento', 'left', 0, true),
      generateColumns('mac', 'MAC', 'left', 0),
      generateColumns('alias', t('Nome'), 'left', 0),
      generateColumns('firmware_version', t('Versão de firmware'), 'left', 0),
      generateColumns('model', t('Modelo'), 'left', 0),
      generateColumns('status', 'Status', 'left', 0),
      generateColumns('actions', t('Ações'), 'left', 0)
    ]
  };

  const columnsOfTable = currentPlace
    ? generateColumnsByPlaceType[currentPlace?.devices_type]
    : generateColumnsByPlaceType.CFTV;

  const hasDevicePagination = devicesPagination?.pagination?.total_items > 0;

  const getDetailsOfCurrentPlace = () => {
    return api.sites.places.details.get(accessToken?.place_id as string);
  };

  const { isFetchedAfterMount: isFetchedAfterMountPlace } = useQuery(
    ['getInfoCurrentPlace', accessToken?.place_id],
    getDetailsOfCurrentPlace,
    {
      onSuccess: ({ data }: { data: IPlaceResponseUnique }) => {
        setCurrentPlace(data.place);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/error/not_found');
        }
      }
    }
  );

  const getPaginationDevices = () => {
    return api.devices.hefesto.get(paginationRequestDataDebounce);
  };

  const handleRebootDevice = () => {
    setRebootingDevices((prev) => [...prev, selectedDevices[0].id]);
    setSelectedDevices([]);
    setShowRestartModal(false);
  };

  const checkIfDeviceIsRebooting = (id: string) => {
    return rebootingDevices.includes(id);
  };

  const { refetch: refetchPagination, isFetching: isFetchingDevices } =
    useQuery(
      ['getDevices', paginationRequestDataDebounce, accessToken?.place_id],
      getPaginationDevices,
      {
        onSuccess: ({ data }: { data: IDevicePaginationTable }) => {
          if (!isInitialLoadingComplete) {
            setIsInitialLoadingComplete(true);
          }
          setDevicesPagination(data);
          setSelectedDevices([]);
          if (deviceIsDeleting) setDeviceIsDeleting(false);
        }
      }
    );

  useEffect(() => {
    refetchPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceIsDeletedObserver]);

  useEffect(() => {
    refetchPagination();
  }, [paginationRequestDataDebounce, refetchPagination]);

  useEffect(() => {
    setPaginationRequestData((prev) => ({
      ...prev,
      search_param: search,
      page_number: 1
    }));
  }, [search]);

  const isResultNotFound =
    devicesPagination?.pagination?.total_filtered_items === 0 &&
    !isFetchingDevices &&
    isInitialLoadingComplete;

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 align-center py-5"
          key={`${i}-skeleton-wireless-table`}>
          <Skeleton count={1} height={25} width={25} className="ml-2" />
          <Skeleton count={1} height={14} width={100} />
          <Skeleton count={1} height={14} width={90} />
          <Skeleton count={1} height={14} width={50} />
          <Skeleton count={1} height={14} width={50} />
          <Skeleton count={1} height={14} width={25} />
          <Skeleton count={1} height={30} width={78} borderRadius={100} />
          <Skeleton
            count={1}
            height={35}
            width={35}
            borderRadius={100}
            className="mr-3"
          />
        </div>
      );
    }

    return skeletons;
  };

  const statusColors = {
    online: 'green',
    offline: 'red'
  };

  const generateStausTag = (device: IDevice) => {
    return (
      <div className={styles.statusCelule}>
        <Tag color={statusColors[device.status]} wide>
          {device.status}
        </Tag>
        {!device.has_associated_wireless && device.type_equipment === 'AP' ? (
          <div className={styles.tooltipTagContainer}>
            <Tooltip
              content={t('Equipmamento sem wireless')}
              id="tooltip-tag-without-wireless"
              place="bottom"
              style={{ width: 190 }}>
              <Tag color="wine">{t('sem wireless')}</Tag>
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  };

  const generateActionsButtons = (device: IDevice) => {
    return (
      <ButtonIcon
        onClick={() => {
          if (device.type_equipment === 'CFTV') {
            navigate(`/devices/details/womcftv/${device.id}`);
          } else {
            navigate(`/devices/details/ap/${device.id}`);
          }
        }}>
        <MdSettings size={24} />
      </ButtonIcon>
    );
  };

  const addActionsColumnToDevices = (devices: IDevice[]) => {
    if (!devices) {
      return [];
    }

    const devicesObject = [] as IDeviceRow[];
    for (const device of devices) {
      devicesObject.push({
        id: device.id,
        type_equipment: device.type_equipment,
        mac: device.mac,
        alias: device.alias,
        frequency: device.frequency,
        firmware_version: device.firmware_version,
        model: device.model,
        statusRAW: device.status,
        devices: device.clients_connecteds,
        status: generateStausTag(device),
        actions: generateActionsButtons(device)
      });
    }
    return devicesObject;
  };

  const renderPage = () => {
    if (isInitialLoadingComplete && !hasDevicePagination) {
      navigate('/first-device');
    }

    return (
      <Grid fluid>
        <RestartDeviceModal
          selectedDevices={selectedDevices}
          show={showRestartModal}
          onClose={() => handleRebootDevice()}
          onCancel={() => {
            setSelectedDevices([]);
            setShowRestartModal(false);
          }}
        />
        <DeleteDeviceModal
          selectedDevices={selectedDevices}
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          refetchPagination={refetchPagination}
          setDeviceIsDeleting={setDeviceIsDeleting}
        />
        <Row>
          <Col xs={12} className="d-flex justify-between align-center mb-7">
            <h2
              className="title-xl-base text-uppercase"
              style={{ color: 'var(--color-neutral-dark-3)' }}>
              {t('Equipamentos')}
            </h2>
          </Col>
          <Card className="fit-width mb-8 pb-6">
            {selectedDevices.length > 0 ? (
              <Row>
                <Col xs={12} className="d-flex">
                  {selectedDevices.length === 1 ? (
                    <ButtonTextOutside
                      value={t('REINICIAR EQUIPAMENTO')}
                      className="text-uppercase mt-6 mb-6 ml-7">
                      <Button
                        onClick={() => setShowRestartModal(true)}
                        id="button-reboot-device"
                        rounded
                        disabled={
                          selectedDevices[0].statusRAW !== 'online' ||
                          deviceIsDeleting ||
                          checkIfDeviceIsRebooting(selectedDevices[0].id)
                        }>
                        <MdSync size={22} />
                      </Button>
                    </ButtonTextOutside>
                  ) : null}
                  <ButtonTextOutside
                    value={`${
                      selectedDevices.length > 1
                        ? t('Excluir equipamentos')
                        : t('Excluir equipamento')
                    } (${selectedDevices?.length})`}
                    className="text-uppercase mt-6 mb-6 ml-7">
                    <Button
                      onClick={() => setShowDeleteModal(true)}
                      id="button-delete-device"
                      rounded
                      isLoading={deviceIsDeleting}
                      color="var(--color-status-critical-base)">
                      <MdDelete size={22} />
                    </Button>
                  </ButtonTextOutside>
                </Col>
              </Row>
            ) : (
              <Row between="md">
                <Col xs={6}>
                  {!isInitialLoadingComplete ? (
                    <div>
                      <Row>
                        <Skeleton count={1} height={18} width={160} />
                      </Row>
                      <Skeleton count={1} height={52} borderRadius={10} />
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Label>{t('Pesquisar equipamento')}:</Label>
                      </Row>
                      <InputIconInside
                        iconLeft={
                          isFetchingDevices &&
                          !isChangingPage &&
                          loadingTypeOfRowsPerPage.type === 'success' ? (
                            <Loading value={40} indeterminate />
                          ) : (
                            <MdSearch />
                          )
                        }>
                        <Input
                          id="search-input"
                          value={search}
                          iconInsideLeft
                          placeholder={t('Digite no mínimo 3 caracteres')}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </InputIconInside>
                    </div>
                  )}
                </Col>
                <Col>
                  {!isInitialLoadingComplete ? (
                    <Skeleton
                      className="my-6 ml-7"
                      count={1}
                      height={50}
                      width={260}
                      borderRadius={10}
                    />
                  ) : (
                    <ButtonTextOutside
                      value={t('Adicionar equipamento')}
                      className="text-uppercase mt-6 mb-6 ml-7">
                      <Button
                        id="button-add-device-now"
                        rounded
                        onClick={() => navigate('/devices/add')}>
                        <MdAdd size={22} />
                      </Button>
                    </ButtonTextOutside>
                  )}
                </Col>
              </Row>
            )}
          </Card>
          <Card className="fit-width">
            {isResultNotFound ? (
              <ResultsNotFoundMessage
                id="devices"
                minHeight="190px"
                height="calc(100vh - 480px)"
              />
            ) : (
              <TableWithPagination<IDevice>
                isChangingPage={isChangingPage}
                loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
                columns={columnsOfTable}
                setPaginationObject={setDevicesPagination}
                rows={addActionsColumnToDevices(devicesPagination.devices)}
                isToShowSkeletons={
                  deviceIsDeleting || !isFetchedAfterMountPlace
                }
                selectedRows={selectedDevices}
                isFetchingData={isFetchingDevices}
                paginationData={devicesPagination.pagination}
                firstFetchWasMade={isInitialLoadingComplete}
                paginationRequestData={paginationRequestDataDebounce}
                renderSkeletonsOfTable={renderSkeletonsOfTable}
                setIsChangingPage={setIsChangingPage}
                setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
                setPaginationRequestData={setPaginationRequestData}
                onSelectRows={(selectedRows: IDeviceRow[]) =>
                  setSelectedDevices(selectedRows)
                }
                keyOfItemsOfPaginationTable="devices"
                minRowsToShowPagination={10}
              />
            )}
          </Card>
        </Row>
      </Grid>
    );
  };

  return <>{renderPage()}</>;
};

export { Devices };

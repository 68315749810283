import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import InputWithValidation from 'src/components/InputWithValidation';
import SelectWithValidation from 'src/components/SelectWithValidation';
import { useAuth } from 'src/hooks/useAuth';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IDataSite } from 'src/services/api/urls/sites/types';
import { timezones } from 'src/utils/timezones';
import Modal from 'ui-components/Modal';
import { IErrorResponse } from '..';
import {
  IResponsePostSiteData,
  ISharedSiteItemPage,
  ISiteData,
  ISiteItemPage
} from '../types';

interface IModalCreateOrEditSite {
  selectedSite: ISiteItemPage | ISharedSiteItemPage | null;
  createOrEditModalType: 'create' | 'edit';
  setCreateOrEditModalType: Dispatch<SetStateAction<'create' | 'edit'>>;
  showModalCreateSite: boolean;
  setShowModalCreateSite: Dispatch<SetStateAction<boolean>>;
  onCreatingSite: () => void;
  onEditingSite: () => void;
  setIsAddingSite: Dispatch<SetStateAction<boolean>>;
  setSiteIdBeingEditedOrDeleted: Dispatch<SetStateAction<string | null>>;
  setSites: Dispatch<SetStateAction<ISiteItemPage[]>>;
  setSharedSites: Dispatch<SetStateAction<ISharedSiteItemPage[]>>;
}

const ModalCreateOrEditSite = (props: IModalCreateOrEditSite) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showModalCreatePlace, setShowModalCreatePlace] = useState(false);
  const { addToast } = useToast();
  const { resetSession } = useAuth();
  const { t } = useTranslation('translations', { keyPrefix: 'site' });
  const navigate = useNavigate();
  const {
    createOrEditModalType,
    setCreateOrEditModalType,
    selectedSite,
    showModalCreateSite,
    setShowModalCreateSite,
    onCreatingSite,
    onEditingSite,
    setIsAddingSite,
    setSiteIdBeingEditedOrDeleted,
    setSites,
    setSharedSites
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors }
  } = useForm<IDataSite>({
    defaultValues: {
      site: {
        name: '',
        timezone: ''
      },
      place: {
        name: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  // mutations
  const addSiteMutation = useMutation(
    (data: IDataSite) => {
      if (createOrEditModalType === 'create') {
        return api.sites.post(data);
      }

      return api.sites.put(data, selectedSite?.id || '');
    },
    {
      onSuccess: ({ data }: { data: IResponsePostSiteData }) => {
        reset();
        if (createOrEditModalType === 'create') {
          addToast('success', t('Local criado com sucesso'));
          onCreatingSite();
        } else {
          if (selectedSite?.member?.role === 'manager') {
            setSharedSites((prevSites) => {
              const newSites = prevSites.map((site) => {
                if (site.id === selectedSite?.id) {
                  return {
                    ...site,
                    name: data.site.name,
                    timezone: data.site.timezone
                  };
                }

                return site;
              });

              return newSites;
            });
          } else {
            setSites((prevSites) => {
              const newSites = prevSites.map((site) => {
                if (site.id === selectedSite?.id) {
                  return {
                    ...site,
                    name: data.site.name,
                    timezone: data.site.timezone
                  };
                }

                return site;
              });

              return newSites;
            });
          }

          addToast('success', t('Local editado com sucesso'));
          onEditingSite();
        }
        setShowModalCreateSite(false);
        setShowModalCreatePlace(false);
        setDisableSubmitButton(false);
        setCreateOrEditModalType('create');
        if (createOrEditModalType === 'create') {
          navigate(`/sites/${data.site.id}/places`);
        } else {
          navigate('/sites');
        }
      },
      onError: (error: IErrorResponse) => {
        setDisableSubmitButton(false);
        setIsAddingSite(false);
        if (error.response.status === 401) {
          resetSession();
        } else if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status === 304 ||
          error.response.status === 404
        ) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          for (const item of error.response.data.message) {
            const field = item.attr.pop() as 'site.name' | 'site.timezone';
            setError(`site.${field as keyof ISiteData}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }

          if (showModalCreatePlace && errors.site) {
            setShowModalCreatePlace(false);
            setShowModalCreateSite(true);
          }
        }
      }
    }
  );

  const onSubmit = handleSubmit((data: IDataSite) => {
    if (createOrEditModalType === 'edit') {
      setSiteIdBeingEditedOrDeleted(selectedSite?.id || null);
      setDisableSubmitButton(true);
      const editSiteData = {
        site: data.site
      };
      addSiteMutation.mutate(editSiteData);
    } else if (createOrEditModalType === 'create' && !showModalCreatePlace) {
      setShowModalCreateSite(false);
      setShowModalCreatePlace(true);
    } else {
      setDisableSubmitButton(true);
      setIsAddingSite(true);
      addSiteMutation.mutate(data);
    }
  });

  const isFormEquals =
    useFormCompare({
      initialValues: {
        name: selectedSite?.name || '',
        timezone: selectedSite?.timezone || ''
      },
      currentValues: watch('site')
    }) && createOrEditModalType === 'edit';

  const setInitialValues = () => {
    if (createOrEditModalType === 'edit') {
      setValue('site.name', selectedSite?.name || '');
      setValue('site.timezone', selectedSite?.timezone || '');
    } else {
      reset();
    }
  };

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, createOrEditModalType]);

  const modalCreateOrEditTexts = {
    edit: {
      title: t('EDITAR LOCAL'),
      textButton: t('EDITAR LOCAL')
    },
    create: {
      title: t('CRIAR LOCAL'),
      textButton: t('CONTINUAR')
    }
  };

  const renderCreatePlaceModal = () => {
    return (
      <Modal
        show={showModalCreatePlace}
        title={t('CRIAR AMBIENTE')}
        height="353px"
        width="550px"
        actions={[
          {
            label: t('CANCELAR'),
            action: () => {
              clearErrors();
              setShowModalCreatePlace(false);
              setValue('site.name', '');
              setValue('site.timezone', '');
              setValue('place.name', '');
            },
            disabled: disableSubmitButton
          },
          {
            label: t('VOLTAR'),
            action: () => {
              setShowModalCreatePlace(false);
              setShowModalCreateSite(true);
            },
            disabled: disableSubmitButton
          },
          {
            label: t('CRIAR LOCAL'),
            action: onSubmit,
            disabled: disableSubmitButton,
            isLoading: disableSubmitButton
          }
        ]}>
        <form onSubmit={onSubmit}>
          <InputWithValidation
            control={control}
            controllerProps={{
              name: 'place.name',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                maxLength: {
                  value: 32,
                  message: t(
                    'O nome do ambiente precisa ter entre 1 e 32 caracteres'
                  )
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O nome do ambiente não pode começar nem terminar com um espaço'
                    ) as string)
                }
              }
            }}
            inputProps={{
              id: 'input-place-name'
            }}
            errors={errors?.place?.name}
            label={t('Nome do ambiente:')}
            placeholder={t('Ex.: Primeiro andar')}
          />
        </form>
      </Modal>
    );
  };

  return (
    <>
      {renderCreatePlaceModal()}
      <Modal
        show={showModalCreateSite}
        title={modalCreateOrEditTexts[createOrEditModalType].title}
        width="550px"
        actions={[
          {
            label: t('CANCELAR'),
            action: () => {
              clearErrors();
              setShowModalCreateSite(false);
              setCreateOrEditModalType('create');
              setValue('site.name', '');
              setValue('site.timezone', '');
              setValue('place.name', '');
            },
            disabled: disableSubmitButton
          },
          {
            label: t(modalCreateOrEditTexts[createOrEditModalType].textButton),
            action: onSubmit,
            disabled: isFormEquals || errors.site,
            isLoading: disableSubmitButton
          }
        ]}>
        <form onSubmit={onSubmit}>
          <InputWithValidation
            control={control}
            controllerProps={{
              name: 'site.name',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                maxLength: {
                  value: 32,
                  message: t(
                    'O nome do local precisa ter entre 1 e 32 caracteres'
                  )
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O nome do local não pode começar nem terminar com um espaço'
                    ) as string)
                }
              }
            }}
            inputProps={{
              id: 'input-site-name'
            }}
            errors={errors?.site?.name}
            label={t('Nome do local:')}
            placeholder={t('Ex.: Hotel São Luiz')}
          />
          <SelectWithValidation
            wrapperProps={{
              className: 'mt-4'
            }}
            control={control}
            controllerProps={{
              name: 'site.timezone',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                }
              }
            }}
            id="select-site-timezone"
            errors={errors?.site?.timezone}
            label={t('Fuso horário:')}
            placeholder={t('Selecione um fuso-horário')}
            options={timezones}
          />
        </form>
      </Modal>
    </>
  );
};

export { ModalCreateOrEditSite };

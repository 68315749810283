/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from 'src/components/Divider';
import InputWithValidation from 'src/components/InputWithValidation';
import { Alert } from 'ui-components/Alert';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import Label from 'ui-components/Label';
import { MdInfo } from 'react-icons/md';
import InfoModalWithIframe from 'src/components/InfoModalWithIframe';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';
import api from 'src/services/api';
import { IUpdateCaptivePortal } from 'src/services/api/urls/captivePortal/types';
import { useToast } from 'src/hooks/useToast';
import CroppedImageModal from 'src/components/CroppedImageModal';
import InputImageWithValidation from 'src/components/InputImageWithValidation';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { useAuth } from 'src/hooks/useAuth';
import {
  getSizeFromBase64,
  convertImageToBase64,
  getMimeTypeFromBase64,
  removePrefixFromBase64
} from 'src/utils/canvasUtils';
import { isUrl } from 'src/utils/regexUtils';
import styles from './PersonalizeCaptivePortal.module.css';
import { IPersonalizeForm, firstCaptivePortal } from './types';
import { RestoreFactoryDefaultModal } from './RestoreFactoryDefaultModal';
import { PreViewCaptivePortal } from './PreViewCaptivePortal';
import { ICaptivePortal } from '../types';
import { IMethodsTypes } from '../SetUpMethod/types';

interface IPersonalizeCaptivePortal {
  defaultCaptivePortal: ICaptivePortal;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
  methodsTypes: IMethodsTypes;
  refetchCaptivePortal: () => void;
  setIsLoadingFormCaptive: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const PersonalizeCaptivePortal = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  methodsTypes,
  isLoadingFormCaptive,
  refetchCaptivePortal,
  setIsLoadingFormCaptive,
  setActiveTab
}: IPersonalizeCaptivePortal) => {
  const { addToast } = useToast();

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.personalize'
  });

  const { accessToken } = useAuth();

  const [typeChange, setTypeChange] = useState<'save' | 'restore'>('restore');

  const [restoreFactoryDefaultModal, setRestoreFactoryDefaultModal] =
    useState(false);

  const [showPolitcsModal, setShowPolitcsModal] = useState(false);

  const [croppedImageModal, setCroppedImageModal] = useState(false);
  const [imageIsCropped, setImageIsCropped] = useState(false);
  const MAX_IMAGE_SIZE_IN_BYTES = 100000;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = useForm<IPersonalizeForm>({
    defaultValues: {
      captivePortal: {
        pageTitle: '',
        pageText: '',
        urlRedirectionAfterLogin: '',
        header_image_url: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const getActualDataCaptivePortal = () => {
    return {
      ...defaultCaptivePortal,
      name: watch('captivePortal.pageTitle'),
      preview_text: watch('captivePortal.pageText'),
      redirect_url: watch('captivePortal.urlRedirectionAfterLogin'),
      header_image_url: watch('captivePortal.header_image_url')
    } as ICaptivePortal;
  };

  const willChangeFormCaptive = useFormCompare({
    initialValues: {
      ...defaultCaptivePortal,
      header_image_url: defaultCaptivePortal.header_image_url || ''
    },
    currentValues: getActualDataCaptivePortal()
  });

  const updateCaptivePortal = (id: string, data: ICaptivePortal) => {
    const image = data.header_image_url || '';
    const imageHasChanged = !isUrl(image);
    const imageToBackendWithoutPrefix = imageHasChanged
      ? removePrefixFromBase64(image)
      : null;
    const formatedData = {
      ...data,
      header_image: imageToBackendWithoutPrefix
    };
    const isToRestoreImage = typeChange === 'restore';

    return api.captivePortal.captive.put(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      { captive_portal: formatedData } as IUpdateCaptivePortal,
      id,
      imageHasChanged,
      isToRestoreImage
    );
  };

  const updateCaptivePortalMutation = useMutation(
    'updateCaptivePortal',
    (data: Omit<ICaptivePortal, 'is_blocked_to_activate'>) => {
      return updateCaptivePortal(defaultCaptivePortal?.id || '', data);
    },
    {
      onSuccess: () => {
        setIsLoadingFormCaptive(false);
        if (typeChange === 'save') {
          addToast('success', `${t('Configurações salvas com sucesso')}`);
        } else {
          addToast('success', `${t('Configurações restauradas com sucesso')}`);
          clearErrors();
        }
        refetchCaptivePortal();
      },
      onError: () => {
        addToast(
          'error',
          'Desculpe não foi possível salvar as configurações, tente novamente mais tarde'
        );
        setIsLoadingFormCaptive(false);
      }
    }
  );

  const onSubmit = handleSubmit((data: IPersonalizeForm) => {
    setTypeChange('save');
    setIsLoadingFormCaptive(true);
    updateCaptivePortalMutation.mutate({
      name: data.captivePortal.pageTitle,
      preview_text: data.captivePortal.pageText,
      redirect_url: data.captivePortal.urlRedirectionAfterLogin,
      mode: defaultCaptivePortal ? defaultCaptivePortal.mode : undefined,
      header_image_url: data.captivePortal.header_image_url || null
    } as ICaptivePortal);
  });

  const onConfirmRestore = () => {
    setIsLoadingFormCaptive(true);
    setRestoreFactoryDefaultModal(false);
    updateCaptivePortalMutation.mutate({
      ...firstCaptivePortal,
      mode: defaultCaptivePortal ? defaultCaptivePortal.mode : undefined
    } as ICaptivePortal);
  };

  useEffect(() => {
    setValue(
      'captivePortal.header_image_url',
      defaultCaptivePortal.header_image_url || ''
    );
    setValue('captivePortal.pageTitle', defaultCaptivePortal?.name);
    setValue('captivePortal.pageText', defaultCaptivePortal?.preview_text);
    setValue(
      'captivePortal.urlRedirectionAfterLogin',
      defaultCaptivePortal?.redirect_url
    );
    setImageIsCropped(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCaptivePortal]);

  function isValidImageType(type: string, permittedExtensions: string[]) {
    return permittedExtensions.includes(type);
  }

  function isValidImageSize(sizeInBytes: number) {
    return sizeInBytes <= MAX_IMAGE_SIZE_IN_BYTES;
  }

  const validateImage = (value: unknown) => {
    if (!value) return true;
    const permittedExtensions = ['image/png', 'image/jpg', 'image/jpeg'];

    try {
      if (typeof value === 'string') {
        if (isUrl(value)) return true;
        const [type, size] = [
          getMimeTypeFromBase64(value),
          getSizeFromBase64(value)
        ];

        if (
          !isValidImageType(type, permittedExtensions) ||
          !isValidImageSize(size)
        ) {
          return false;
        }
      } else if (value instanceof File) {
        const { type, size } = value;
        if (
          !isValidImageType(type, permittedExtensions) ||
          !isValidImageSize(size)
        ) {
          return false;
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return true;
  };

  const handleChangeImage = async (image: File | null) => {
    if (!image) return;
    const isValid = validateImage(image);
    if (!isValid) {
      setValue('captivePortal.header_image_url', '');
      return;
    }

    if (image.size <= MAX_IMAGE_SIZE_IN_BYTES) {
      setCroppedImageModal(true);
      setImageIsCropped(false);
    }
    const imageInBase64 = await convertImageToBase64(image!);
    setValue('captivePortal.header_image_url', imageInBase64);
  };
  const defaultImageSize = {
    width: 510,
    height: 150
  };
  const getCropProportion = (width: number, height: number) => width / height;

  const renderSkeleton = () => {
    return (
      <Row className="mt-3">
        <Col xs={12}>
          <div className="fit-width">
            <div className={classNames('mb-3')}>
              <Skeleton width={145} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
              <div className="mt-1">
                <Skeleton width="100%" height={40} />
              </div>
            </div>
            <div className={classNames('mb-3')}>
              <Skeleton width={104} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
            </div>
            <div className={classNames('mb-3')}>
              <Skeleton width={37} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
            </div>
            <div className={classNames('mb-3')}>
              <Skeleton width={200} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
            </div>
            <Skeleton width={250} height={24} />
            <div
              className={classNames(
                'd-flex',
                'justify-end',
                'fit-width',
                'mt-8'
              )}>
              <div className={classNames('ml-3')}>
                <Skeleton width={130} height={45} />
              </div>
              <div className={classNames('ml-3')}>
                <Skeleton width={130} height={45} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Grid fluid>
      <CroppedImageModal
        show={croppedImageModal}
        onClose={() => setCroppedImageModal(false)}
        onChange={async (image) => {
          const imageInBase64 = await convertImageToBase64(image!);
          setValue('captivePortal.header_image_url', imageInBase64);
          setImageIsCropped(true);
        }}
        imageSrc={watch('captivePortal.header_image_url')}
        imageType={getMimeTypeFromBase64(
          watch('captivePortal.header_image_url')
        )}
        maxZoom={10}
        minZoom={1}
        aspect={getCropProportion(
          defaultImageSize.width,
          defaultImageSize.height
        )}
        onCancel={() => {
          setValue('captivePortal.header_image_url', '');
          setCroppedImageModal(false);
          setImageIsCropped(false);
        }}
        hasCancelModal
      />
      <RestoreFactoryDefaultModal
        showModalRestore={restoreFactoryDefaultModal}
        isLoadingRestore={isLoadingFormCaptive}
        onCancelRestore={() => setRestoreFactoryDefaultModal(false)}
        onConfirmRestore={onConfirmRestore}
      />
      <InfoModalWithIframe
        show={showPolitcsModal}
        onClose={() => setShowPolitcsModal(false)}
        title={t('Política de Privacidade Intelbras')}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        href={process.env.REACT_APP_TERMO_INTELBRAS_PRIVACY_POLICY!}
      />
      <InfoModalWithIframe
        show={showPolitcsModal}
        onClose={() => setShowPolitcsModal(false)}
        title={t('Política de Privacidade Intelbras')}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        href={process.env.REACT_APP_TERMO_INTELBRAS_PRIVACY_POLICY!}
      />
      <Row>
        <Col xs={12} className="mb-7 d-flex flex-column">
          <div>
            <h2
              className={classNames([
                'title-xl-base text-uppercase',
                styles.titlePage
              ])}
              style={{ color: 'var(--color-neutral-dark-3)' }}>
              {t('Personalizar')}
            </h2>
          </div>
          <div className="mt-3">
            <span className={classNames(['text-base-lg', styles.subtitlePage])}>
              {t(
                'Personalize a página de acesso que irá aparecer para os usuários, sempre que eles se conectarem à sua rede. Caso não pretenda personalizar, a página irá aparecer com nossa configuração padrão'
              )}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Card className={classNames('px-10', 'py-7', 'fit-width', 'd-flex')}>
          <Col xs={6}>
            {isFetchedCaptivePortal ? (
              <form onSubmit={onSubmit}>
                <Row className="mt-3">
                  <Col xs={12}>
                    <div className="fit-width">
                      <div className={classNames('mb-3')}>
                        <Label>{t('Imagem de cabeçalho')} </Label>
                        <div className="mt-1">
                          <InputImageWithValidation
                            control={control}
                            controllerProps={{
                              name: 'captivePortal.header_image_url',
                              rules: {
                                validate: {
                                  validateImage: (value) =>
                                    validateImage(value) ||
                                    t(
                                      'Extensão da imagem não permitida ou tamanho superior a 100kB'
                                    )
                                }
                              }
                            }}
                            errors={errors?.captivePortal?.header_image_url}
                            id="imageInputCaptivePortal"
                            onHandleChange={async (image) =>
                              handleChangeImage(image)
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <Alert type="info">
                            {t(
                              'Extensões permitidas: jpg, jpeg, png. Tamanho máximo permitido: 100kB'
                            )}
                          </Alert>
                        </div>
                      </div>
                      <div className={classNames('mb-3')}>
                        <InputWithValidation
                          label={t('Título da Página')}
                          hasRequiredIndicator
                          key="pageTitle"
                          id="pageTitle"
                          control={control}
                          controllerProps={{
                            name: 'captivePortal.pageTitle',
                            rules: {
                              required: {
                                value: true,
                                message: t('Campo obrigatório')
                              }
                            }
                          }}
                          errors={errors?.captivePortal?.pageTitle}
                        />
                      </div>
                      <div className={classNames('mb-3')}>
                        <InputWithValidation
                          label={t('Texto')}
                          control={control}
                          id="pageText"
                          controllerProps={{ name: 'captivePortal.pageText' }}
                          errors={errors?.captivePortal?.pageText}
                        />
                      </div>
                      <div className={classNames('mb-3')}>
                        <div className="d-flex align-start">
                          <Label hasRequiredIndicator>
                            <span
                              className={
                                errors.captivePortal
                                  ?.urlRedirectionAfterLogin &&
                                styles.labelWithError
                              }>
                              {t('Redirecionamento após login')}
                            </span>{' '}
                          </Label>
                          <span className={styles.labelWarning}>
                            ({t('inserir URL completa')})
                          </span>
                        </div>

                        <InputWithValidation
                          placeholder={t('ex: https://site.com.br')}
                          control={control}
                          id="urlRedirectionAfterLogin"
                          controllerProps={{
                            name: 'captivePortal.urlRedirectionAfterLogin',
                            rules: {
                              required: {
                                value: true,
                                message: t('Campo obrigatório')
                              },
                              pattern: {
                                value:
                                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/i,
                                message: t(
                                  'URL inválido. Verifique se o link possui http ou https'
                                )
                              }
                            }
                          }}
                          errors={
                            errors?.captivePortal?.urlRedirectionAfterLogin
                          }
                        />
                      </div>
                      <button
                        type="button"
                        id="btnPrivacyPolicts"
                        onClick={() => setShowPolitcsModal(true)}
                        className={classNames(
                          'd-flex align-center pt-2',
                          styles.politicsText
                        )}>
                        <MdInfo
                          size={14}
                          color="var(--color-brand-primary-darkest)"
                        />

                        <span className="text-sm-lg ml-2 text-bold">
                          {t('Política de Privacidade Intelbras')}
                        </span>
                      </button>
                      <div
                        className={classNames(
                          'd-flex',
                          'justify-end',
                          'fit-width',
                          'mt-8'
                        )}>
                        <Button
                          className={classNames([styles.buttons])}
                          id="btnRestoreFactoryDefault"
                          outline
                          type="button"
                          disabled={
                            isLoadingFormCaptive && typeChange !== 'restore'
                          }
                          isLoading={
                            isLoadingFormCaptive && typeChange === 'restore'
                          }
                          onClick={() => {
                            setTypeChange('restore');
                            setRestoreFactoryDefaultModal(true);
                          }}>
                          {t('Restaurar')}
                        </Button>
                        <Button
                          className={classNames([styles.buttons])}
                          id="btnSave"
                          type="submit"
                          disabled={
                            willChangeFormCaptive ||
                            (isLoadingFormCaptive && typeChange !== 'save') ||
                            !!errors.captivePortal
                          }
                          isLoading={
                            isLoadingFormCaptive && typeChange === 'save'
                          }
                          onClick={onSubmit}>
                          {t('Salvar')}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            ) : (
              renderSkeleton()
            )}
          </Col>
          <Divider orientation="vertical" style={{ height: '537px' }} />
          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <div className="fit-width">
                  <PreViewCaptivePortal
                    defaultCaptivePortal={
                      defaultCaptivePortal && getActualDataCaptivePortal()
                    }
                    isFetchedCaptivePortal={isFetchedCaptivePortal}
                    viewFormData={{
                      captivePortal: {
                        ...watch().captivePortal,
                        header_image_url:
                          !errors?.captivePortal?.header_image_url &&
                          imageIsCropped
                            ? watch('captivePortal.header_image_url')
                            : ''
                      }
                    }}
                    setActiveTab={setActiveTab}
                    methodsTypes={methodsTypes}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Card>
      </Row>
    </Grid>
  );
};

export { PersonalizeCaptivePortal };

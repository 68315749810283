import React, { useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/useAuth';
import Drawer from 'ui-components/Drawer';
import SetupProgressCard from 'src/components/SetupProgressCard';
import { useMutation, useQuery } from 'react-query';
import api from 'src/services/api';
import Modal from 'ui-components/Modal';
import Card from 'ui-components/Card';
import { Button } from 'ui-components/Button';
import { MdHelpOutline, MdWarning } from 'react-icons/md';
import classNames from 'classnames';
import { IRetryDataRequest } from 'src/services/api/urls/devices/types';
import { useNotification } from 'src/hooks/useNotification';
import Skeleton from 'react-loading-skeleton';
import { Divider } from 'src/components/Divider';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from 'src/hooks/useToast';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { eventGA } from 'src/utils/analytics';
import ClosableCard from 'src/components/CloseableCard';
import { Alert } from 'ui-components/Alert';
import styles from './AddDeviceDrawer.module.css';
import {
  IAddDrawerDevice,
  IConfiguration,
  IConfigurationMap,
  IDataConfigurationList,
  IDataId,
  IDeviceAdoptionData,
  IWebSocketConfigurationMessage,
  IRetryDeviceError
} from './types';
import ModalIgnoreNodeWithError from './ModalIgnoreNodeWithError';

const socketUrl = `${process.env.REACT_APP_WS_HOST}/device/configuration/`;

const AddDeviceDrawer = ({
  showDrawer = false,
  setShowDrawer
}: IAddDrawerDevice) => {
  const { triggerDeviceIsDeletedObserver } = useNotification();
  const [configurationsMap, setConfigurationsMap] = useState<IConfigurationMap>(
    {}
  );

  const [modalType, setModalType] = useState('add');
  const [devicesWithSomeError, setDevicesWithSomeError] = useState<
    IDeviceAdoptionData[]
  >([]);
  const [idOfCardToVerify, setIdOfCardToVerify] = useState('');
  const [showConfigurationDeviceModal, setShowConfigurationDeviceModal] =
    useState(false);

  const { refetchConfigurationsStatus } = useNotification();
  const { accessToken } = useAuth();

  const [currentDeviceWithError, setCurrentDeviceWithError] =
    useState<IDeviceAdoptionData | null>(null);

  const [showModalIgnoreNodeWithError, setShowModalIgnoreNodeWithError] =
    useState(false);

  const convertTypesOfProgressCard = {
    error: 'warning',
    running: 'default',
    success: 'success'
  };

  const sortConfigurations = (
    prevState: IConfigurationMap,
    added?: IConfigurationMap
  ) => {
    const configurationsMapUpdated = {
      ...added,
      ...prevState
    };

    const configurationsMapSorted = Object.values(
      configurationsMapUpdated
    ).sort((item, nextItem) => {
      if (item.action.includes('info') && !nextItem.action.includes('info')) {
        return -1;
      }

      if (!item.action.includes('info') && nextItem.action.includes('info')) {
        return 1;
      }

      if (item.group_status === 'error' && nextItem.group_status !== 'error') {
        return -1;
      }

      if (
        item.group_status === 'success' &&
        nextItem.group_status !== 'success'
      ) {
        return 1;
      }
      return 0;
    });

    const configurationsMapSortedObject = configurationsMapSorted.reduce(
      (map: IConfigurationMap, obj: IConfiguration) => {
        map[obj.id] = obj;
        return map;
      },
      {}
    );

    return configurationsMapSortedObject;
  };

  const onVerifyCard = (configuration: IConfiguration) => {
    setIdOfCardToVerify(configuration.id);
    setShowConfigurationDeviceModal(true);
    setModalType(configuration.action);
  };

  const updateConfiguration = (data: IDataConfigurationList) => {
    setConfigurationsMap((prevState) => {
      const configurationsMapAux = { ...prevState };
      for (const item of data.configurations) {
        if (item.total_devices === 0 && !item.action.includes('info')) {
          delete configurationsMapAux[item.id];
          // eslint-disable-next-line no-continue
          continue;
        }
        configurationsMapAux[item.id] = item;
      }
      return sortConfigurations(configurationsMapAux);
    });
  };

  const addConfiguration = ({ configurations }: IDataConfigurationList) => {
    const added: IConfigurationMap = configurations.reduce(
      (map: IConfigurationMap, obj: IConfiguration) => {
        map[obj.id] = obj;
        return map;
      },
      {}
    );
    setConfigurationsMap((prevState) => sortConfigurations(prevState, added));
  };

  const deleteConfiguration = ({ configurations }: IDataConfigurationList) => {
    for (const configuration of configurations) {
      setConfigurationsMap((prevState) => {
        const configurationsMapAux = { ...prevState };
        return _.omit(configurationsMapAux, [configuration.id]);
      });
    }
  };

  const webSocketTopics = useMemo(
    () => ({
      update: updateConfiguration,
      add: addConfiguration,
      delete: deleteConfiguration,
      error: (error: unknown) => window.console.error(error)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const listConfigurationCards = (data: IDataConfigurationList) => {
    setConfigurationsMap(() =>
      sortConfigurations(
        data.configurations
          .filter(
            (item) => item.total_devices !== 0 || item.action.includes('info')
          )
          .reduce(
            (previous: IConfigurationMap, currentDevice: IConfiguration) => {
              previous[currentDevice.id as string] = currentDevice;
              return previous;
            },
            {}
          )
      )
    );
  };

  const handleCloseDeviceConfigurationModal = () => {
    setShowConfigurationDeviceModal(false);
    setIdOfCardToVerify('');
    setCurrentDeviceWithError(null);
  };

  // Queries
  const { refetch: refetchConfigurationsCards } = useQuery(
    ['listConfigurationCards', `${accessToken?.place_id}`],
    api.devices.configurations.get,
    {
      onSuccess: ({ data }) => listConfigurationCards(data)
    }
  );

  const getDevicesWithSomeError = (id: string) => {
    return api.devices.configurations.getDevicesWithSomeError(id);
  };

  const {
    refetch: refetchDevicesWithSomeError,
    isFetching: isFetchingDevicesWithSomeError,
    isFetchedAfterMount: isFetchedAfterMountDevicesWithSomeError
  } = useQuery(
    ['getDeviceWithSomeError', idOfCardToVerify],
    () => getDevicesWithSomeError(idOfCardToVerify),
    {
      refetchOnWindowFocus: false,
      enabled: idOfCardToVerify !== '',
      onSuccess: ({ data }) => {
        setDevicesWithSomeError(data.configurations);
      },
      onError: (error: { response: { status: number } }) => {
        if (error.response.status === 404) {
          if (showConfigurationDeviceModal) {
            handleCloseDeviceConfigurationModal();
          }
        }
      }
    }
  );

  // mutations
  const deleteConfigurationCardMutation = useMutation(
    (id: string) => {
      return api.devices.configurations.deleteGroup(id);
    },
    {
      onSuccess: (data, variables) => {
        setConfigurationsMap((prevState) => {
          return _.omit(prevState, [variables]);
        });
      },
      onError: (error: { response: { status: number } }) => {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  );

  const deleteConfigurationCardWithoutDevices = (idOfConfiguration: string) => {
    setConfigurationsMap((prevState) => {
      const newState = _.omit(prevState, [idOfConfiguration]);
      return newState;
    });
  };

  // hooks
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.drawer'
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const {
    lastJsonMessage
  }: {
    lastJsonMessage: IWebSocketConfigurationMessage;
    sendJsonMessage: (message: { topic: 'delete'; data: IDataId }) => void;
  } = useWebSocket(
    socketUrl,
    {
      queryParams: {
        access_token: accessToken?.access_token as string
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      shouldReconnect: (closeEvent) => true
    },
    showDrawer
  );

  const deleteDeviceWithConfigurationError = useMutation(
    (device: IDeviceAdoptionData) => {
      return api.devices.deleteList([device.device_id]);
    },
    {
      onSuccess: ({ config }) => {
        eventGA(
          'Equipamentos',
          'Click',
          'Excluir equipamento com erro na aplicação de configuração'
        );
        triggerDeviceIsDeletedObserver();
        if (devicesWithSomeError.length === 1) {
          setDevicesWithSomeError([]);
        } else {
          refetchDevicesWithSomeError();
        }
        refetchConfigurationsStatus();
        refetchConfigurationsCards();

        addToast('success', t('Equipamento removido com sucesso'));

        if (config.url) {
          const deviceIds = config.url.match(/ids=([\w-]+)/);

          if (deviceIds) {
            const deviceId = deviceIds[1];

            const isDeviceDeleted = pathname.includes(deviceId);

            if (isDeviceDeleted) {
              navigate('/devices');
            }
          }
        }
      }
    }
  );

  const handleErrorDeleteDeviceOrNode = async (
    variables: IDeviceAdoptionData,
    error: { response: { status: number } }
  ) => {
    setConfigurationsMap((prevState) => {
      const newState = { ...prevState };
      newState[variables.id].is_deleting = true;
      return newState;
    });
    // eslint-disable-next-line no-console
    console.log(error);
  };

  const deleteDeviceWithAddError = useMutation(
    (device: IDeviceAdoptionData) => {
      return api.devices.configurations.delete(device.id);
    },
    {
      onSuccess: async () => {
        eventGA(
          'Equipamentos',
          'Click',
          'Excluir equipamento com erro na adição'
        );
        refetchConfigurationsStatus();

        const isLastDeviceWithSomeError =
          devicesWithSomeError.length === 1 &&
          configurationsMap[idOfCardToVerify].total_devices === 1;

        if (isLastDeviceWithSomeError) {
          setDevicesWithSomeError([]);
          deleteConfigurationCardWithoutDevices(idOfCardToVerify);
        } else {
          await refetchDevicesWithSomeError();
          await refetchConfigurationsCards();
        }
      },
      onError: (error: { response: { status: number } }, variables) => {
        handleErrorDeleteDeviceOrNode(variables, error);
      }
    }
  );

  const allMacsOfDevicesWithSomeErrorAreEquals = () => {
    return devicesWithSomeError.every(
      (obj, _callback, arr) => obj.mac === arr[0].mac
    );
  };

  const deleteDeviceNodeWithError = useMutation(
    (deviceConfiguration: IDeviceAdoptionData) => {
      return api.devices.configurations.delete(deviceConfiguration.id);
    },
    {
      onSuccess: async () => {
        if (allMacsOfDevicesWithSomeErrorAreEquals()) {
          setDevicesWithSomeError([]);
        } else {
          refetchDevicesWithSomeError();
          setShowConfigurationDeviceModal(true);
        }
        refetchConfigurationsStatus();
        refetchConfigurationsCards();
        setShowModalIgnoreNodeWithError(false);
      },
      onError: (error: { response: { status: number } }, variables) => {
        handleErrorDeleteDeviceOrNode(variables, error);
      }
    }
  );

  const retryDeviceMutation = useMutation(
    (data: IRetryDataRequest) => {
      return api.devices.configurations.retry(data);
    },
    {
      onSuccess: () => {
        eventGA(
          'Equipamentos',
          'Click',
          'Tentando novamente aplicar configuração'
        );
        refetchDevicesWithSomeError();
        refetchConfigurationsCards();
        refetchConfigurationsStatus();
        if (showModalIgnoreNodeWithError) {
          if (!allMacsOfDevicesWithSomeErrorAreEquals()) {
            setShowConfigurationDeviceModal(true);
          }
          setShowModalIgnoreNodeWithError(false);
        }
      },
      onError: (error: IRetryDeviceError, request: IRetryDataRequest) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const devicesWithSomeErrorUpdated = devicesWithSomeError.map(
            (deviceWithSomeError) => {
              if (deviceWithSomeError.device_id === request.device_id) {
                deviceWithSomeError.notShowRetry = true;
                deviceWithSomeError.message =
                  error.response.data.message[0].msg;
              }
              return deviceWithSomeError;
            }
          );
          if (showModalIgnoreNodeWithError) {
            setShowModalIgnoreNodeWithError(false);
          }
          setDevicesWithSomeError(devicesWithSomeErrorUpdated);
        }
      }
    }
  );

  const getGroupCardConfigurationMessageInPlural = (
    groupType: string,
    deviceCount: number
  ) => {
    const addPlural = () => {
      return deviceCount > 1
        ? t('equipamentos adicionados')
        : t('equipamento adicionado');
    };

    const configurationPlural = () => {
      return deviceCount > 1
        ? t('equipamentos configurados')
        : t('equipamento configurado');
    };

    const captivePortalPlural = () => {
      return deviceCount > 1
        ? t('equipamentos com erro')
        : t('equipamento com erro');
    };

    if (groupType === 'remove_macs_captive_portal') {
      return captivePortalPlural();
    }

    if (groupType === 'add') {
      return addPlural();
    }

    return configurationPlural();
  };

  const getErrorMessageByTypeOfCard = (
    groupType: string,
    deviceCount: number
  ) => {
    const addPlural = () => {
      return deviceCount > 1
        ? t('Ocorreu um erro na adição dos equipamentos')
        : t('Ocorreu um erro na adição do equipamento');
    };

    const configurationPlural = () => {
      return deviceCount > 1
        ? t('Ocorreu um erro na configuração dos equipamentos')
        : t('Ocorreu um erro na configuração do equipamento');
    };

    const captivePortalPlural = t(
      'Ocorreu uma falha de comunicação com o equipamento durante a desautorização'
    );

    if (groupType === 'remove_macs_captive_portal') {
      return captivePortalPlural;
    }

    if (groupType === 'add') {
      return addPlural();
    }

    return configurationPlural();
  };

  const getPercentage = (total: number, current: number) => {
    return Math.round((current / total) * 100);
  };

  const handleDeleteConfigurationCard = (configuration: IConfiguration) => {
    setConfigurationsMap((prevState) => {
      const newState = { ...prevState };
      newState[configuration.id].is_deleting = true;
      return newState;
    });
    deleteConfigurationCardMutation.mutateAsync(configuration.id);
  };

  const deleteDeviceWithSomeErrorInState = (
    deviceConfiguration: IDeviceAdoptionData
  ) => {
    setDevicesWithSomeError((prevState) => {
      return prevState.map((deviceConf) => {
        if (deviceConf.device_id === deviceConfiguration.device_id) {
          deviceConf.isDeleting = true;
        }
        return deviceConf;
      });
    });
  };

  const ignoreNodeWithError = (deviceConfiguration: IDeviceAdoptionData) => {
    deleteDeviceNodeWithError.mutateAsync(deviceConfiguration);
  };

  const handleRetryDeviceWithSomeError = (
    deviceConfiguration: IDeviceAdoptionData
  ) => {
    setDevicesWithSomeError((prevState) => {
      return prevState.map((deviceConf) => {
        if (deviceConf.device_id === deviceConfiguration.device_id) {
          deviceConf.isRetrying = true;
        }
        return deviceConf;
      });
    });
    retryDeviceMutation.mutate({
      device_id: deviceConfiguration.device_id
    });
  };

  const handleDeleteDeviceWithSomeError = (
    deviceConfiguration: IDeviceAdoptionData
  ) => {
    setDevicesWithSomeError((prevState) => {
      return prevState.map((deviceConf) => {
        if (deviceConf.device_id === deviceConfiguration.device_id) {
          deviceConf.isDeleting = true;
        }
        return deviceConf;
      });
    });

    if (modalType === 'add') {
      deleteDeviceWithAddError.mutate(deviceConfiguration);
    } else {
      deleteDeviceWithConfigurationError.mutate(deviceConfiguration);
    }
  };

  useEffect(() => {
    if (lastJsonMessage != null && lastJsonMessage.topic in webSocketTopics) {
      webSocketTopics[lastJsonMessage.topic](lastJsonMessage.data);
    }
  }, [lastJsonMessage, webSocketTopics]);

  useEffect(() => {
    if (showDrawer) {
      refetchConfigurationsCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrawer]);

  const colorByStatus = {
    error: 'var(--color-status-critical-dark)',
    warning: 'var(--color-status-alert-dark)'
  };

  const renderSkeletons = (numberOfSkeletons: number) => {
    const skeletons = [];
    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <Card
          className="mb-2 pb-5 pt-2"
          borderColor="black"
          key={`skeletom-error-config-${i}`}>
          <Skeleton width="100%" height={32} />
          <Divider />
          <Skeleton width="100%" height={32} />
          <div className="d-flex justify-end mt-2">
            {modalType === 'add' && (
              <Skeleton
                width="215px"
                height={45}
                style={{ marginRight: '8px' }}
              />
            )}
            <Skeleton width="185px" height={45} />
          </div>
        </Card>
      );
    }
    return skeletons;
  };

  const renderErrorMessage = (message: string | null) => {
    if (
      modalType === 'add' &&
      message &&
      message?.includes(
        'Não foi possível adicionar o equipamento. Para ajudar a resolver'
      )
    ) {
      return (
        <div>
          <span>
            {t(
              'Não foi possível adicionar o equipamento. Para ajudar a resolver, por favor, verifique com atenção o Guia de Adição de equipamento disponível em nossa'
            )}{' '}
          </span>
          <span className="text-bold">
            {t('Central de Ajuda')}{' '}
            <span className={classNames(styles.iconHelpDrawer)}>
              <MdHelpOutline size={18} />
            </span>
          </span>
        </div>
      );
    }

    return <span>{message}</span>;
  };

  const titleObject = {
    remove_macs_captive_portal: t('ERRO DE DESAUTORIZAÇÃO CAPTIVE PORTAL'),
    add: t('ADIÇÃO DE EQUIPAMENTOS'),
    configuration: t('CONFIGURAÇÃO DE EQUIPAMENTO')
  };

  const deviceConfigurationModal = () => {
    return (
      <Modal
        show={showConfigurationDeviceModal}
        width="720px"
        closable
        height="70vh"
        onClose={() => handleCloseDeviceConfigurationModal()}
        title={titleObject[modalType as keyof typeof titleObject]}>
        {isFetchedAfterMountDevicesWithSomeError &&
        !isFetchingDevicesWithSomeError ? (
          <div className={styles.contentModalDevicesWithErrors}>
            {devicesWithSomeError.length === 0 && (
              <EmptyMessage
                title={t('Nenhum equipamento')}
                subtitle={t(
                  'Não há equipamentos para serem exibidos no momento'
                )}
                id="empty-errors-devices"
                height="calc(70vh - 180px)"
              />
            )}
            {devicesWithSomeError.map((deviceConfiguration) => {
              const hasAuthenticationErrorInAdoption =
                modalType === 'add' &&
                deviceConfiguration.status === 'error' &&
                deviceConfiguration?.error_code ===
                  'DEVICE_AUTHENTICATION_ERROR';

              return (
                <Card
                  key={deviceConfiguration.mac + deviceConfiguration.created_at}
                  borderColor={
                    colorByStatus[
                      deviceConfiguration.status as 'error' | 'warning'
                    ]
                  }
                  className="mb-2 pb-5 pt-2">
                  <div className="d-flex justify-between text-center">
                    <div className="d-flex justify-center align-center">
                      <div className={styles.deviceData}>
                        <strong className="text-bold mr-6">
                          {deviceConfiguration.alias}
                        </strong>{' '}
                        <span>
                          <strong className="text-bold">MAC</strong>{' '}
                          {deviceConfiguration.mac}
                        </span>
                      </div>
                    </div>
                    <div>
                      {deviceConfiguration.status === 'error' ? (
                        <span
                          className={`d-flex justify-center align-center ${styles.errorIcon}`}>
                          <MdWarning size={32} />
                        </span>
                      ) : (
                        <span
                          className={`d-flex justify-center align-center ${styles.warningIcon}`}>
                          <MdWarning size={32} />
                        </span>
                      )}
                    </div>
                  </div>
                  <hr
                    className={classNames(['mb-3', 'mt-2', styles.dividerCard])}
                  />
                  <p
                    className={
                      deviceConfiguration.status === 'error'
                        ? styles.errorText
                        : styles.warningText
                    }>
                    {renderErrorMessage(deviceConfiguration.message)}
                  </p>
                  {deviceConfiguration.status === 'error' && (
                    <div className="d-flex justify-end mt-3">
                      {modalType === 'configuration' && (
                        <span className="mr-2">
                          <Button
                            ghost
                            isLoading={deviceConfiguration.isDeleting}
                            disabled={deviceConfiguration.isRetrying}
                            onClick={() => {
                              handleDeleteDeviceWithSomeError(
                                deviceConfiguration
                              );
                            }}>
                            {t('REMOVER EQUIPAMENTO')}
                          </Button>
                        </span>
                      )}
                      {modalType === 'remove_macs_captive_portal' && (
                        <Button
                          ghost
                          isLoading={deviceConfiguration.isDeleting}
                          disabled={deviceConfiguration.isRetrying}
                          onClick={() => {
                            setShowModalIgnoreNodeWithError(true);
                            setCurrentDeviceWithError(deviceConfiguration);
                            setShowConfigurationDeviceModal(false);
                          }}>
                          {t('IGNORAR')}
                        </Button>
                      )}
                      {modalType === 'add' && (
                        <span className="mr-2">
                          <Button
                            ghost
                            isLoading={deviceConfiguration.isDeleting}
                            disabled={deviceConfiguration.isRetrying}
                            onClick={() => {
                              handleDeleteDeviceWithSomeError(
                                deviceConfiguration
                              );
                            }}>
                            {t('IGNORAR')}
                          </Button>
                        </span>
                      )}
                      {!deviceConfiguration.notShowRetry &&
                        !hasAuthenticationErrorInAdoption && (
                          <Button
                            outline
                            isLoading={
                              retryDeviceMutation.isLoading &&
                              retryDeviceMutation.variables?.device_id ===
                                deviceConfiguration.device_id
                            }
                            disabled={deviceConfiguration.isDeleting}
                            onClick={() => {
                              handleRetryDeviceWithSomeError(
                                deviceConfiguration
                              );
                            }}>
                            {t('TENTAR NOVAMENTE')}
                          </Button>
                        )}
                    </div>
                  )}
                </Card>
              );
            })}
          </div>
        ) : (
          <div className={styles.loadingModal}>
            {renderSkeletons(
              isFetchedAfterMountDevicesWithSomeError
                ? devicesWithSomeError.length
                : 3
            )}
          </div>
        )}
      </Modal>
    );
  };

  const getCardTitle = (action: string) => {
    if (action === 'add') {
      return t('ADIÇÃO DE EQUIPAMENTOS');
    }
    if (action === 'update_firmware') {
      return t('ATUALIZAÇÃO DE FIRMWARE');
    }
    if (action === 'remove_macs_captive_portal') {
      return t('ERRO DE DESAUTORIZAÇÃO CAPTIVE PORTAL');
    }
    if (action === 'info' || action === 'info_persistent') {
      return t('MENSAGEM DO SISTEMA');
    }

    return t('APLICAÇÃO DE CONFIGURAÇÃO');
  };

  const renderCardInfo = (configuration: IConfiguration) => {
    return (
      <ClosableCard
        key={configuration.id}
        id={configuration.id}
        title={getCardTitle(configuration.action)}
        type={convertTypesOfProgressCard[configuration.group_status]}
        closable={configuration.action === 'info'}
        closeTooltipText={t('Remover card')}
        isLoadingClose={configuration.is_deleting}
        onClose={() => handleDeleteConfigurationCard(configuration)}>
        <Alert type="error">{configuration.subtitle}</Alert>
      </ClosableCard>
    );
  };

  const renderCardsByTypes = (configuration: IConfiguration) => {
    if (
      configuration.action === 'info' ||
      configuration.action === 'info_persistent'
    ) {
      return renderCardInfo(configuration);
    }
    if (
      configuration.action === 'remove_macs_captive_portal' &&
      configuration.group_status !== 'error'
    ) {
      return null;
    }

    return (
      <SetupProgressCard
        key={configuration.id}
        id={configuration.id}
        title={getCardTitle(configuration.action)}
        value={getPercentage(
          configuration.total_devices,
          configuration.finished_devices_count
        )}
        type={convertTypesOfProgressCard[configuration.group_status]}
        warningMessage={
          configuration.group_status === 'error'
            ? getErrorMessageByTypeOfCard(
                configuration.action,
                configuration.total_devices
              )
            : ''
        }
        closable={configuration.group_status === 'success'}
        closeTooltipText={t('Remover card')}
        isLoadingClose={configuration.is_deleting}
        onClose={() => handleDeleteConfigurationCard(configuration)}
        message={`${configuration.finished_devices_count} de ${
          configuration.total_devices
        } ${getGroupCardConfigurationMessageInPlural(
          configuration.action,
          configuration.total_devices
        )}`}
        onVerify={() => onVerifyCard(configuration)}
        subtitle={configuration.subtitle}
      />
    );
  };

  const arrayOfConfigurations = Object.values(configurationsMap);
  const filteredOfDesautorizationNodesRunning = arrayOfConfigurations.filter(
    (configuration) =>
      configuration.action !== 'remove_macs_captive_portal' ||
      configuration.group_status !== 'running'
  );

  return (
    <Drawer
      title={t('Notificações')}
      id="drawer-add-device"
      show={showDrawer}
      onClose={() => setShowDrawer(false)}
      responsiveWidth={{ lg: '55vw' }}>
      <ModalIgnoreNodeWithError
        currentDeviceWithError={currentDeviceWithError}
        handleRetryDeviceWithSomeError={handleRetryDeviceWithSomeError}
        ignoreNodeWithError={ignoreNodeWithError}
        show={showModalIgnoreNodeWithError}
      />
      {deviceConfigurationModal()}
      {filteredOfDesautorizationNodesRunning.length > 0 ? (
        filteredOfDesautorizationNodesRunning.map((configuration) =>
          renderCardsByTypes(configuration)
        )
      ) : (
        <EmptyMessage
          title={t('Nenhuma notificação')}
          subtitle={t('Não há notificações para serem exibidas')}
          id="empty-notifications"
          height="calc(100vh - 125px)"
        />
      )}
    </Drawer>
  );
};

export default AddDeviceDrawer;

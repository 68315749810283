import classNames from 'classnames';
import React, { useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { MdSearch, MdUpload } from 'react-icons/md';
import { Button, ButtonIcon } from 'ui-components/Button';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import SearchWithLine from 'src/assets/search-with-line.svg';
import Card from 'ui-components/Card';
import Tag from 'ui-components/Tags';
import moment from 'moment';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import Loading from 'ui-components/Loading';
import { Tooltip } from 'inmaster-ui';
import styles from './SearchFirmware.module.css';
import { IFirmwareVersionToRender } from './types';
import { IDeviceDataToUpdateFireware } from '../types';

interface IProps {
  handleSelectFirmwareVersion: (firmwareVersion: string) => void;
  device: IDeviceDataToUpdateFireware;
}
export interface IErrorResponse {
  response: {
    status: number;
  };
}

const SearchFirmware = ({ handleSelectFirmwareVersion, device }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const [firmwareVersions, setFirmwareVersions] = useState<
    IFirmwareVersionToRender[]
  >([]);
  const [search, setSearch] = useState('');

  function formatChangeLog(changeLog: string) {
    const regex = /(\\r|\\t|\\n|»)/gm;

    const formattedChangeLog = changeLog.replace(regex, ' ');
    return formattedChangeLog;
  }

  const handleCollapse = (version: string) => {
    const newFirmwareVersions = firmwareVersions.map((firmwareVersion) => {
      if (firmwareVersion.version === version) {
        return {
          ...firmwareVersion,
          collapsed: !firmwareVersion.collapsed
        };
      }

      return firmwareVersion;
    });

    setFirmwareVersions(newFirmwareVersions);
  };

  const canUpdateFirmware = (version: string, isSupported: boolean) => {
    if (!isSupported) return false;
    if (isSupported && version !== device.firmware_version) return true;

    return false;
  };

  const rederFirmwareVersions = () => {
    return (
      <div className={classNames(['fit-width', styles.cardsShell])}>
        {firmwareVersions.map((firmwareVersion) => (
          <Card
            borderColor="var(--color-brand-secondary-light)"
            className={classNames([
              'pa-1 px-4 mb-2 fit-width',
              !canUpdateFirmware(
                firmwareVersion.version,
                firmwareVersion.is_supported
              ) && 'py-3'
            ])}
            key={firmwareVersion.version}>
            <div className="d-flex justify-between align-center">
              <div className={styles.cardInfos}>
                <div className={styles.cardItem}>
                  <span className="text-bold">{t('Versão')}:</span>
                  <span
                    className="ml-2"
                    id={`number-of-version-${firmwareVersion.version}`}>
                    {firmwareVersion.version}
                  </span>
                </div>
                <div className={styles.cardItem}>
                  <span className="text-bold">{t('Última atualização')}: </span>
                  <span
                    className="ml-2"
                    id={`last-update-date-${firmwareVersion.updated_at}`}>
                    {moment(firmwareVersion.updated_at).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div
                  id={`${firmwareVersion.version}-is-supported-${String(
                    firmwareVersion.is_supported
                  )}`}>
                  {firmwareVersion.is_supported ? (
                    <Tag color="green">{t('suportada')}</Tag>
                  ) : (
                    <Tag color="lightblue">{t('não suportada')}</Tag>
                  )}
                </div>
                <div
                  className={classNames([
                    styles.cardItem,
                    'd-flex aling-center justify-end'
                  ])}>
                  <button
                    className={styles.textButton}
                    type="button"
                    onClick={() => handleCollapse(firmwareVersion.version)}>
                    {firmwareVersion.collapsed
                      ? t('Ver menos')
                      : t('Changelog')}
                  </button>
                  {canUpdateFirmware(
                    firmwareVersion.version,
                    firmwareVersion.is_supported
                  ) && (
                    <Tooltip
                      content={t('Atualizar firmware')}
                      place="bottom"
                      id="tooltip-upload-firmware">
                      <ButtonIcon
                        className="ml-4"
                        onClick={() =>
                          handleSelectFirmwareVersion(firmwareVersion.version)
                        }>
                        <MdUpload size={24} />
                      </ButtonIcon>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames([
                firmwareVersion.collapsed
                  ? styles.changeLogShell
                  : styles.changeLogShellClosed,
                !canUpdateFirmware(
                  firmwareVersion.version,
                  firmwareVersion.is_supported
                ) &&
                  firmwareVersion.collapsed &&
                  'mt-2'
              ])}>
              <span className="mb-1">
                {formatChangeLog(firmwareVersion.changelog)}
              </span>
            </div>
          </Card>
        ))}
      </div>
    );
  };

  const getSearchFirmware = () => {
    return api.devices.firmwares.search(device.model, search);
  };

  const { refetch, isFetching } = useQuery(
    ['searchFirmwareQuery', device.model],
    getSearchFirmware,
    {
      onSuccess: ({ data }) => {
        const formatedFirmwares = data.firmwares.map((firmware) => ({
          ...firmware,
          collapsed: false
        }));

        setFirmwareVersions(formatedFirmwares);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status >= 400 && error.response.status <= 600) {
          setFirmwareVersions([]);
        }
      },
      enabled: false
    }
  );

  const handleSearch = () => {
    refetch();
  };

  return (
    <div className="fit-width">
      <div className="fit-width">
        <Row>
          <Label>{t('Pesquisar firmware')}:</Label>
        </Row>
        <InputIconInside iconLeft={<MdSearch />}>
          <Input
            id="search-input"
            iconInsideLeft
            placeholder={t('Pesquise a versão firmware desejada')}
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
          />
        </InputIconInside>
      </div>

      <div className="fit-width d-flex justify-end my-3">
        <Button
          secondary
          id="btn-search-firmware-device"
          disabled={isFetching}
          className={classNames([
            styles.searchButton,
            'd-flex align-center justify-center'
          ])}
          onClick={handleSearch}>
          {isFetching ? (
            <Loading value={40} indeterminate show />
          ) : (
            t('PESQUISAR FIRMWARE')
          )}
        </Button>
      </div>

      <div
        className={classNames([
          'd-flex align-center justify-center flex-column pt-3',
          styles.cardsContainer
        ])}>
        {firmwareVersions.length === 0 ? (
          <>
            <img
              src={SearchWithLine}
              alt="Ícone de procura"
              width="100"
              height="100"
            />

            <span className={classNames([styles.textFeedback, 'mt-3 mb-7'])}>
              {t('Os resultados dos firmwares pesquisados aparecerão aqui')}.
            </span>
          </>
        ) : (
          rederFirmwareVersions()
        )}
      </div>
    </div>
  );
};

export default SearchFirmware;

import { isNull } from 'lodash';
import React, { useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdInfo } from 'react-icons/md';
import Label from 'ui-components/Label';
import Modal from 'ui-components/Modal';
import Select from 'ui-components/Select';
import Toggle from 'ui-components/Toggle';
import stylesAdvancedWireless from '../AdvancedWireless.module.css';
import { IAdvancedWirelessForm } from '../types';

interface IBandSteeringSectionProps {
  controlForm: Control<IAdvancedWirelessForm, unknown>;
  valueBandSteering: boolean;
  restoreToCachedOnActive: () => void;
}

interface ISelectOptions {
  value: string | number;
  label: string;
  id?: string;
}

const BandSteeringSection = (props: IBandSteeringSectionProps) => {
  const {
    controlForm: control,
    valueBandSteering,
    restoreToCachedOnActive
  } = props;
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless.band-steering'
  });

  const [showBandSteeringModal, setShowBandSteeringModal] = useState(false);

  const selectOptionsBandSteering: ISelectOptions[] = [
    {
      label: t('Preferencialmente 5GHz'),
      value: '5g_prioritization',
      id: '5g_prioritization'
    },
    {
      label: t('Balanceamento de clientes entre frequências'),
      value: 'loadbalance',
      id: 'loadbalance'
    }
  ];

  const bandSteeringInfoModal = () => {
    return (
      <Modal
        title={t('Band Steering')}
        show={showBandSteeringModal}
        width="720px"
        closable
        onClose={() => setShowBandSteeringModal(false)}>
        <p
          className={`text-sm-base mb-6 px-1 ${stylesAdvancedWireless.modalText}`}>
          <span className={stylesAdvancedWireless.modalBold}>
            {t('Balanceamento de clientes entre frequências:')}
          </span>{' '}
          {t(
            'O AP irá otimizar a utilização da rede realizando o balanceamento de carga entre as frequências 2.4GHz e 5GHz.'
          )}
        </p>
        <p
          className={`text-sm-base pb-8 px-1 ${stylesAdvancedWireless.modalText}`}>
          <span className={stylesAdvancedWireless.modalBold}>
            {t('Preferencialmente 5GHz:')}
          </span>{' '}
          {t(
            'Os clientes com suporte 802.11ac terão preferência para se manterem conectados à interface 5GHz. Isto não garante, porém, que eles não sejam migrados para a interface 2.4GHz devido à outros fatores (sinal e utilização da rede).'
          )}
        </p>
      </Modal>
    );
  };

  return (
    <Row className="fit-width">
      {bandSteeringInfoModal()}
      <Col xs={12}>
        <Label className="d-flex text-bold text-xl-lg mb-2">
          {t('Band Steering')}
        </Label>
      </Col>
      <Col xs={12} className={`d-flex ${stylesAdvancedWireless.columnsGap}`}>
        <Col xs={6}>
          <div>
            <p className="text-sm-base">
              {t(
                'O AP escolherá a melhor frequência para o cliente, baseado em métricas de rede e qualidade de sinal, e ocorrerá apenas entre equipamentos que possuem a mesma wireless.'
              )}
            </p>
          </div>
        </Col>
        <Col xs={5}>
          <div className="d-flex mb-4">
            <Controller
              control={control}
              name="band_steering"
              render={({ field: { onChange, value } }) => (
                <Toggle
                  color="var(--color-status-ok-base)"
                  checked={value}
                  id="band-steering"
                  onChange={() => {
                    onChange(!value);
                    if (value) {
                      restoreToCachedOnActive();
                    }
                  }}
                />
              )}
            />
            <span className="d-flex justify-center align-center">
              {t('Band Steering')}{' '}
              <span
                className={`${stylesAdvancedWireless.iconContainer} d-flex justify-center align-center`}>
                <MdInfo
                  size={20}
                  className={`${stylesAdvancedWireless.icon} ml-1`}
                  onClick={() => {
                    setShowBandSteeringModal(true);
                  }}
                />
              </span>
            </span>
          </div>
          {valueBandSteering && (
            <>
              <Label className="d-flex">{t('Método')}:</Label>
              <Controller
                control={control}
                name="band_steering_method"
                render={({ field: { onChange, value } }) => (
                  <Select
                    getLastOptionAsDefault
                    id="band-steering-method"
                    value={isNull(value) ? undefined : value}
                    onChange={onChange}
                    options={selectOptionsBandSteering}
                  />
                )}
              />
            </>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export { BandSteeringSection };

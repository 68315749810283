import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Grid } from 'react-flexbox-grid';
import { useNavigate } from 'react-router-dom';
import { useTemplate } from 'src/hooks/useTemplate';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { useForm, Controller, MultipleFieldErrors } from 'react-hook-form';
import { Input } from 'ui-components/Input';
import Label from 'ui-components/Label';
import { MdCancel } from 'react-icons/md';
import InputHelper from 'ui-components/InputHelper';
import Loading from 'ui-components/Loading';
import { InputWrapper } from 'ui-components/InputWrapper';
import api from 'src/services/api';
import { IDataSite, ISiteResponse } from 'src/services/api/urls/sites/types';
import { useAuth } from 'src/hooks/useAuth';
import { IAccessToken } from 'src/services/api/urls/auth/types';
import SelectWithValidation from 'src/components/SelectWithValidation';
import { timezones } from 'src/utils/timezones';
import Steps from 'ui-components/Steps';
import { eventGA } from 'src/utils/analytics';
import styles from './FirstAccess.module.css';
import StepImage from './StepImage';
import Step0Image from '../../assets/step_0_image.svg';
import Step1Image from '../../assets/step_1_image.svg';
import Step2Image from '../../assets/step_2_image.svg';
import Step3Image from '../../assets/step_3_image.svg';
import Step4Image from '../../assets/step_4_image.svg';

const FirstAccess = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'firstAccess' });
  const {
    handleSubmit,
    trigger,
    control,
    getValues,
    watch,
    formState: { errors }
  } = useForm<IDataSite>({
    defaultValues: {
      site: { name: '', timezone: 'America/Sao_Paulo' },
      place: {
        name: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });
  // hooks
  useTemplate('avatarNavbar');
  const navigate = useNavigate();

  // states
  const [step, setStep] = useState(0);

  const { setAccessToken, accessToken, resetSession } = useAuth();

  // queries
  const { isFetching } = useQuery(
    'wirelessQuery',
    () => {
      return api.sites.get();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: ISiteResponse }) => {
        if (data.sites.length > 0) {
          navigate('/devices');
        }
      }
    }
  );

  // mutations
  const mutation = useMutation(
    (data: IDataSite) => {
      return api.sites.post(data);
    },
    {
      onSuccess: () => {
        const accessTokenAux = { ...accessToken } as IAccessToken;
        setAccessToken({ ...accessTokenAux });
        navigate('/sites');
      },
      onError: (error: { response: { status: number } }) => {
        if (error.response.status === 401) {
          resetSession();
        }
      }
    }
  );

  // functions
  const onSubmit = handleSubmit((data: IDataSite) => {
    eventGA('Tutorial', 'Click', 'Finalizar Tutorial');
    mutation.mutate(data);
  });

  const validateInputValues = (index: number) => {
    const inputKeys = Object.keys(getValues());
    const subInputKeys = getValues();
    const keyToAcess = inputKeys[index];
    const subInput = subInputKeys[keyToAcess as keyof IDataSite] as {
      [key: string]: string;
    };
    for (const inputValue of Object.values(subInput)) {
      if (inputValue === '') {
        return false;
      }
    }
    return true;
  };

  const inputsAndress = [['site.name'], ['place.name']];

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code !== 'Enter') {
      return false;
    }
    if (e.code === 'Enter' && step <= 1 && validateInputValues(step)) {
      e.preventDefault();
      setStep((prev) => prev + 1);
    } else if (e.code === 'Enter') {
      e.preventDefault();
      for (const input of inputsAndress[step]) {
        trigger(input as keyof IDataSite);
      }
    }
    return true;
  };

  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  const nextStep = async (inputName?: string) => {
    if (inputName && inputName.length > 0) {
      const isInputValid = await trigger(inputName as keyof IDataSite);
      if (!isInputValid) return;
    }
    setStep((prev) => prev + 1);
  };

  const backStep = () => {
    eventGA('Tutorial', 'Click', `Voltar Tutorial ${step - 1}`);
    setStep((prev) => prev - 1);
  };

  const skipIntro = () => {
    eventGA('Tutorial', 'Click', 'Pular Tutorial');
    setStep(5);
  };

  const introducingSteps = [
    () => (
      <StepImage
        image={Step0Image}
        title={t('Bem-vindo ao InMaster!')}
        text={t('Seu software de gerenciamento centralizado de equipamentos')}
        onNextStep={nextStep}
        onSkip={skipIntro}
        noSkip
        noBackStep
        step={step}
      />
    ),
    () => (
      <StepImage
        image={Step1Image}
        title={t('Gerencie múltiplas redes')}
        text={t(
          'Crie, edite e defina as configurações das suas Redes Wireless. Com o InMaster, você está no controle.'
        )}
        onNextStep={nextStep}
        onBackStep={backStep}
        onSkip={skipIntro}
        step={step}
      />
    ),
    () => (
      <StepImage
        image={Step2Image}
        title={t('Monitore seus equipamentos')}
        text={t(
          'Cadastre os equipamentos que transmitem Redes Wireless e monitore o funcionamento de cada um deles.'
        )}
        onNextStep={nextStep}
        onBackStep={backStep}
        onSkip={skipIntro}
        step={step}
      />
    ),
    () => (
      <StepImage
        image={Step3Image}
        title={t('Administre seus locais')}
        text={t(
          'Gerencie diferentes estabelecimentos de qualquer lugar, organize-os de forma individual.'
        )}
        onNextStep={nextStep}
        onBackStep={backStep}
        onSkip={skipIntro}
        step={step}
      />
    ),
    () => (
      <StepImage
        image={Step4Image}
        title={t('Organize seus ambientes')}
        text={t(
          'Administre os diferentes ambientes do seu local e tenha mais controle de toda a sua infraestrutura de rede.'
        )}
        onNextStep={nextStep}
        onBackStep={backStep}
        onSkip={skipIntro}
        step={step}
      />
    ),
    () => (
      <StepImage
        image={Step0Image}
        title={t('Vamos começar!')}
        text=""
        onNextStep={nextStep}
        noBackStep
        onSkip={() => {
          setStep(1);
        }}
        textSkip={t('Voltar à explicação')}
        step={step}
      />
    )
  ];

  const siteStep = () => {
    return (
      <div className="fit-width d-flex align-start flex-column" key="step-0">
        <h1 id="step-title" className="title-xl-base mt-8 mb-3">
          {t('Crie seu primeiro local')}
        </h1>
        <div className="fit-width mt-3 d-flex align-start flex-column">
          <InputWrapper invalid={Boolean(errors?.site?.name)}>
            <Label>{t('Nome do local')}:</Label>
            <Controller
              control={control}
              name="site.name"
              rules={{
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                maxLength: {
                  value: 32,
                  message: t(
                    'O nome do local precisa ter entre 1 e 32 caracteres'
                  )
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O nome do local não pode começar nem terminar com um espaço'
                    ) as string)
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  id="site-name-input"
                  placeholder={t('Ex.: Hotel São Luis')}
                />
              )}
            />
            {getInputErrorMessage(errors.site?.name?.types)}
          </InputWrapper>
          <SelectWithValidation
            wrapperProps={{
              className: 'mt-4'
            }}
            control={control}
            controllerProps={{
              name: 'site.timezone',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                }
              }
            }}
            id="select-site-timezone"
            errors={errors?.site?.timezone}
            label={t('Fuso horário:')}
            options={timezones}
          />
          <div className="fit-width mt-8 d-flex justify-between">
            <Button
              ghost
              className="mr-3"
              onClick={() => setStep(3)}
              id="back-to-explanation">
              {t('Voltar à explicação')}
            </Button>
            <Button id="create-site" onClick={() => nextStep('site.name')}>
              {t('Criar local')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const placeStep = () => {
    return (
      <div className="fit-width d-flex align-start flex-column" key="step-1">
        <h1 id="step-title" className="title-xl-base mt-8 mb-3">
          {t('Crie um ambiente para o local')} {watch('site.name')}
        </h1>
        <div className="fit-width mt-3 d-flex align-start flex-column">
          <InputWrapper invalid={Boolean(errors?.place?.name)}>
            <Label>{t('Nome do ambiente')}:</Label>
            <Controller
              control={control}
              name="place.name"
              rules={{
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                maxLength: {
                  value: 32,
                  message: t(
                    'O nome do ambiente precisa ter entre 1 e 32 caracteres'
                  )
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O nome do ambiente não pode começar nem terminar com um espaço'
                    ) as string)
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  id="place-name-input"
                  placeholder={t('Ex.: Administração')}
                />
              )}
            />
            {getInputErrorMessage(errors.place?.name?.types)}
          </InputWrapper>
          <div className="fit-width mt-8 d-flex justify-between">
            <div>
              <Button
                ghost
                className="mr-3"
                onClick={() => setStep(4)}
                id="back-to-explanation">
                {t('Voltar à explicação')}
              </Button>
            </div>
            <div className="d-flex justify-end">
              <Button
                outline
                className="mr-3"
                id="btn-back"
                onClick={() => setStep((prev) => prev - 1)}>
                {t('Voltar')}
              </Button>
              <Button id="button-submit-firstaccess" type="submit">
                {t('Criar ambiente')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    eventGA('Usuário', 'Access', 'Acessou pela primeira vez');
  }, []);

  // variables
  const stepsPages = [...introducingSteps, siteStep, placeStep];
  return (
    <div>
      {mutation.isLoading ? (
        <Loading
          fullscreen
          value={40}
          indeterminate
          message={t('Configurando o local')}
          show={mutation.isLoading}
        />
      ) : (
        <Grid fluid>
          {!isFetching ? (
            <div
              className={classNames([
                'd-flex justify-center',
                styles.container
              ])}>
              <Card
                className={classNames([
                  styles.cardFirstAccess,
                  'd-flex align-start flex-column'
                ])}>
                {step > 0 && step < 5 ? (
                  <div className="fit-width mb-3">
                    <Steps
                      current={step - 1}
                      items={[
                        t('Wireless'),
                        t('Equipamentos'),
                        t('Locais'),
                        t('Ambientes')
                      ]}
                    />
                  </div>
                ) : null}
                <form
                  className="fit-width mt-11"
                  id="form-wireless"
                  onSubmit={onSubmit}
                  aria-hidden="true"
                  onKeyDown={(e) => checkKeyDown(e)}>
                  {stepsPages[step]()}
                </form>
              </Card>
            </div>
          ) : (
            <Loading value={40} indeterminate fullscreen show />
          )}
        </Grid>
      )}
    </div>
  );
};

export { FirstAccess };

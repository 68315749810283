import React, { useRef, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Controller, MultipleFieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCancel, MdLock } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { useNotification } from 'src/hooks/useNotification';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IAddDevice } from 'src/services/api/urls/devices/types';
import { Alert } from 'ui-components/Alert';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputIconInside, InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import InputMask from 'react-input-mask';
import { eventGA } from 'src/utils/analytics';
import {
  IPlace,
  IPlaceResponseUnique
} from 'src/services/api/urls/sites/types';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import styles from './FormAddDevices.module.css';
import { PasswordVisibilityIcon } from '../PasswordVisibilityIcon';
import InfoCaptiveAndFirmwareModal from '../InfoCaptiveAndFirmwareModal';

export interface IErrorResponseMessage {
  attr: string[];
  msg: string;
}
interface IErrorResponse {
  response: {
    status: number;
    data: { message: IErrorResponseMessage[] };
  };
}

const mask = [
  /[0-9a-fA-F]/,
  /[0-9a-fA-F]/,
  ':',
  /[0-9a-fA-F]/,
  /[0-9a-fA-F]/,
  ':',
  /[0-9a-fA-F]/,
  /[0-9a-fA-F]/,
  ':',
  /[0-9a-fA-F]/,
  /[0-9a-fA-F]/,
  ':',
  /[0-9a-fA-F]/,
  /[0-9a-fA-F]/,
  ':',
  /[0-9a-fA-F]/,
  /[0-9a-fA-F]/
];

interface IFormAddDevicesProps {
  redirectOnCancel: string;
}

const FormAddDevices = (props: IFormAddDevicesProps) => {
  const { redirectOnCancel } = props;
  // Hooks
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors }
  } = useForm<IAddDevice>({
    defaultValues: {
      mac: '',
      password: '',
      alias: ''
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const navigate = useNavigate();

  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.add'
  });
  const { resetSession, accessToken } = useAuth();

  const { addToast } = useToast();

  const inputRef = useRef(null);

  const { triggerNotificationAnimationWithText } = useNotification();

  // states
  const [showPassword, setShowPassword] = useState(false);

  const [isFirstEquipment, setIsFirstEquipment] = useState(false);

  const [place, setPlace] = useState<IPlace>({} as IPlace);

  const [showWarningCaptiveActiveModal, setShowWarningCaptiveActiveModal] =
    useState(false);

  // queries
  const getPlaceUnique = () => {
    return api.sites.hefesto.places.details.get(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const { isFetching: isFetchingPlace } = useQuery(
    'placeUnique',
    getPlaceUnique,
    {
      onSuccess: ({ data }: { data: IPlaceResponseUnique }) => {
        setPlace(data.place);
      }
    }
  );

  // mutations
  const AddDeviceMutation = useMutation(
    (data: IAddDevice) => {
      return api.devices.post(data);
    },
    {
      onSuccess: () => {
        if (!isFirstEquipment) {
          eventGA('Equipamentos', 'Click', 'Adicionar primeiro equipamento');
          setIsFirstEquipment(true);
        } else {
          eventGA('Equipamentos', 'Click', 'Adicionar mais equipamentos');
        }

        reset();
        triggerNotificationAnimationWithText(t('ADICIONANDO EQUIPAMENTO'));
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 401) {
          resetSession();
        } else if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          error.response.data.message.forEach((item) => {
            const field = item.attr.pop() as 'mac' | 'alias' | 'password';
            setError(field, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          });
        }
      }
    }
  );

  // functions
  const onSubmit = handleSubmit((data: IAddDevice) => {
    data.mac = data.mac.toUpperCase();
    AddDeviceMutation.mutate(data);
  });

  const formatErrorInput = (error: string) => {
    const splitErrorString = error.split('"');
    const elements = splitErrorString.map((item, index) => {
      if (index % 2 !== 0) {
        return (
          <span key={item} className="text-bold" style={{ margin: 0 }}>
            {item}
          </span>
        );
      }

      return item;
    });

    return <div>{elements}</div>;
  };

  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      if (errorTypes.request.toString().includes('"')) {
        message = formatErrorInput(errorTypes.request.toString());
      } else {
        message = errorTypes.request;
      }
    }

    return (
      <InputHelper id="error-message" icon={<MdCancel />} value={message} />
    );
  };

  return (
    <Grid fluid>
      <InfoCaptiveAndFirmwareModal
        showModal={showWarningCaptiveActiveModal}
        setShowModal={setShowWarningCaptiveActiveModal}
      />
      <Row>
        <Col xs={12} className="mb-7">
          <h2
            className="title-xl-base text-uppercase"
            style={{ color: 'var(--color-neutral-dark-3)' }}>
            {t('Adicionar Equipamentos')}
          </h2>
        </Col>
        <Col xs={12}>
          <Card className="fit-width">
            {!isFetchingPlace ? (
              place.captive_portal_mode && (
                <div className="mb-6">
                  <Alert type="info">
                    <div>
                      <span>
                        {t(
                          'O Captive Portal encontra-se ativo neste ambiente. Antes de adicionar um equipamento, verifique se a versão de firmware está atualizada'
                        )}
                      </span>
                      {'. '}
                      <span
                        className={classNames([
                          styles.customLink,
                          'text-xs-xs'
                        ])}
                        aria-hidden
                        onClick={() => {
                          setShowWarningCaptiveActiveModal(true);
                        }}>
                        {t('Saiba Mais')}
                      </span>
                    </div>
                  </Alert>
                </div>
              )
            ) : (
              <div className="mb-6">
                <Skeleton height={30} width={250} />
              </div>
            )}
            <form
              className="fit-width"
              id="form-add-device"
              autoComplete="off"
              onSubmit={onSubmit}>
              <Row className="d-flex align-center">
                <Col xs={12}>
                  <Row>
                    <Col xs={12} md={6} className="pr-2">
                      <InputWrapper invalid={Boolean(errors?.mac)}>
                        <Label>{t('MAC do equipamento')}:</Label>
                        <Controller
                          control={control}
                          name="mac"
                          rules={{
                            required: {
                              value: true,
                              message: t('Campo obrigatório')
                            },
                            pattern: {
                              value:
                                /^((([0-9A-F]{2}:){5})|(([0-9A-F]{2}-){5})|([0-9A-F]{10}))([0-9A-F]{2})$/i,
                              message: t('MAC inválido')
                            }
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <InputMask
                              mask={mask}
                              maskPlaceholder={null}
                              onChange={onChange}
                              onBlur={onBlur}
                              ref={inputRef}
                              value={value.toUpperCase()}>
                              <Input
                                id="mac-input"
                                placeholder="00:1A:2B:03:45:C6"
                                autoComplete="off"
                              />
                            </InputMask>
                          )}
                        />
                        {getInputErrorMessage(errors.mac?.types)}
                      </InputWrapper>
                    </Col>
                    <Col xs={12} md={6} className="pl-2">
                      <InputWrapper invalid={Boolean(errors?.password)}>
                        <Label>{t('Senha')}:</Label>
                        <Controller
                          control={control}
                          name="password"
                          rules={{
                            required: {
                              value: true,
                              message: t('Campo obrigatório')
                            },
                            minLength: {
                              value: 5,
                              message: t(
                                'A senha do equipamento deve ter no mínimo 5 e no máximo de 63 caracteres'
                              )
                            },
                            maxLength: {
                              value: 63,
                              message: t(
                                'A senha do equipamento deve ter no mínimo 5 e no máximo de 63 caracteres'
                              )
                            },
                            validate: {
                              notWhitespaceOnly: (value) =>
                                value.trim().length > 0 ||
                                (t('Campo obrigatório') as string),
                              notStartsOrEndsWithWhitespace: (value) =>
                                (!value.startsWith(' ') &&
                                  !value.endsWith(' ')) ||
                                (t(
                                  'A senha não pode começar nem terminar com um espaço'
                                ) as string)
                            }
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <InputIconInside
                              iconLeft={<MdLock />}
                              iconRight={
                                <PasswordVisibilityIcon
                                  setShowPassword={setShowPassword}
                                  showPassword={showPassword}
                                />
                              }>
                              <Input
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                type={showPassword ? 'text' : 'password'}
                                id="password-input"
                                placeholder="*******"
                                iconInsideLeft
                                iconInsideRight
                                autoComplete="off"
                              />
                            </InputIconInside>
                          )}
                        />
                        {getInputErrorMessage(errors.password?.types)}
                      </InputWrapper>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mt-5">
                  <InputWrapper invalid={Boolean(errors?.alias)}>
                    <Label>{t('Nome')}:</Label>
                    <Controller
                      control={control}
                      name="alias"
                      rules={{
                        required: {
                          value: true,
                          message: t('Campo obrigatório')
                        },
                        maxLength: {
                          value: 32,
                          message: t(
                            'O nome do equipamento precisa ter entre 1 e 32 caracteres'
                          )
                        },
                        pattern: {
                          value: /^([a-zA-Z0-9 -]+)$/,
                          message: t(
                            'O nome do equipamento não pode conter caracteres especiais'
                          )
                        },
                        validate: {
                          notWhitespaceOnly: (value) =>
                            value.trim().length > 0 ||
                            (t('Campo obrigatório') as string),
                          notStartsOrEndsWithWhitespace: (value) =>
                            (!value.startsWith(' ') && !value.endsWith(' ')) ||
                            (t(
                              'O nome do equipamento não pode começar nem terminar com um espaço'
                            ) as string)
                        }
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          id="name-input"
                          placeholder={t('Ex.: AP 01 - Primeiro Andar')}
                        />
                      )}
                    />
                    {getInputErrorMessage(errors.alias?.types)}
                  </InputWrapper>
                  <span
                    className={classNames([
                      styles.subtitleInput,
                      'text-sm-xs'
                    ])}>
                    {t(
                      'O processo de adição do equipamento só poderá ser concluído quando ele estiver online'
                    )}
                  </span>
                </Col>
                <Col xs={12} className="d-flex justify-end mt-11">
                  <Button
                    ghost
                    type="button"
                    className="mr-4"
                    id="button-cancel"
                    disabled={AddDeviceMutation.isLoading}
                    onClick={() => navigate(redirectOnCancel)}>
                    {t('Cancelar')}
                  </Button>
                  <Button
                    type="submit"
                    id="button-add-device"
                    isLoading={AddDeviceMutation.isLoading}>
                    {t('Adicionar equipamento')}
                  </Button>
                </Col>
              </Row>
            </form>
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export { FormAddDevices };

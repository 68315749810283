import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import { IMemberWithTranslatedRoleData } from 'src/services/api/urls/sites/types';
import Modal from 'ui-components/Modal';
import Label from 'ui-components/Label';
import RadioButton from 'ui-components/RadioButton';
import { Divider } from 'src/components/Divider';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { useMutation } from 'react-query';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { IDataEditedMember } from './types';
import { IErrorResponse } from '../..';
import { getTagText } from '..';

interface PropsModalEditMember {
  showModalEditMember: boolean;
  setShowModalEditMember: Dispatch<SetStateAction<boolean>>;
  member: IMemberWithTranslatedRoleData;
  onMemberEdited: (newMemberInfos: IMemberWithTranslatedRoleData) => void;
}

const ModalEditMember = (props: PropsModalEditMember) => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', { keyPrefix: 'members' });
  const {
    showModalEditMember,
    setShowModalEditMember,
    member,
    onMemberEdited
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    getValues,
    watch
  } = useForm<IDataEditedMember>({
    defaultValues: {
      member: {
        role: RoleMember.GUEST
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const [hasNextPageEditionMember, setHasNextPageEditionMember] =
    useState(true);

  const isFormEquals = useFormCompare({
    initialValues: { role: member.role },
    currentValues: watch('member')
  });

  const editMembersMutation = useMutation(
    (data: IDataEditedMember) => {
      return api.sites.members.put(data, member.site, member.id);
    },
    {
      onSuccess: () => {
        setShowModalEditMember(false);
        setHasNextPageEditionMember(true);
        onMemberEdited({
          ...member,
          role: getValues('member.role'),
          translatedRole: getTagText(watch('member.role'))
        });
        clearErrors();
        reset();
        addToast('success', t('Permissões do membro editadas com sucesso'));
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status >= 400 && error.response.status <= 600) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (error.response.status === 304) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        }

        setHasNextPageEditionMember(true);
        setShowModalEditMember(false);
      }
    }
  );

  useEffect(() => {
    setValue('member.role', member.role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('member.role', event.target.defaultValue as RoleMember);
  };

  const onSubmit = handleSubmit((data: IDataEditedMember) => {
    editMembersMutation.mutate(data);
  });

  const skipNextPageForm = () => {
    setHasNextPageEditionMember(false);
  };

  const renderFormEditMember = () => {
    return (
      <>
        <div className="mb-3">
          <p className="title-sm-base">{t('Nome')}: </p>
          <p className="text-xl-lg">{member && member.user?.name}</p>
        </div>
        <div>
          <p className="title-sm-base">{t('E-mail:')} </p>
          <p className="text-xl-lg">{member && member.user?.email}</p>
        </div>
        <Divider />
        <form onSubmit={onSubmit}>
          <div className="mt-2">
            <Label className="text-sm-xs">
              {t('Selecione o nível de permissão:')}
            </Label>
            <Controller
              control={control}
              name="member.role"
              render={({ field: { value } }) => (
                <div className="d-flex flex-column mt-1">
                  <RadioButton
                    checked={value === RoleMember.MANAGER}
                    value={RoleMember.MANAGER}
                    id="btn-manager"
                    onChange={handleRadioChange}
                  />
                  <Label element="radiobutton" className="text-sm-sm">
                    {t('Gerente')}
                  </Label>

                  <Label element="radiobutton" className="text-sm-xs">
                    {t(
                      'Tem acesso a gerencia completa do local, adição e remoção de membros'
                    )}
                  </Label>

                  <RadioButton
                    checked={value === RoleMember.GUEST}
                    value={RoleMember.GUEST}
                    onChange={handleRadioChange}
                    id="btn-convidado"
                  />
                  <Label element="radiobutton" className="text-sm-sm">
                    {t('Convidado')}
                  </Label>

                  <Label element="radiobutton" className="text-sm-xs ">
                    {t('Tem acesso a gerencia completa dos ambientes')}
                  </Label>
                </div>
              )}
            />
          </div>
        </form>
      </>
    );
  };
  const renderConfirmEditMember = () => {
    return (
      <div>
        <Label>
          <span className="text-sm-lg">
            {t('Ao realizar o próximo')} <i>{t('login')}</i>{' '}
          </span>
          <span className="title-xl-sm">
            {member.user?.name !== '-' ? member.user?.name : ''}
          </span>{' '}
          <span className="text-sm-lg">
            {t(
              'terá as informações alteradas no local compartilhado. Deseja realmente editar suas permissões?'
            )}
          </span>
        </Label>
      </div>
    );
  };

  const editActions = [
    {
      label: t('CANCELAR'),
      disabled: editMembersMutation.isLoading,
      action: () => {
        clearErrors();
        reset();
        setValue('member.role', member.role);
        setShowModalEditMember(false);
        setHasNextPageEditionMember(true);
      }
    },
    hasNextPageEditionMember
      ? {
          disabled: isFormEquals,
          isLoading: editMembersMutation.isLoading,
          label: t('CONTINUAR'),
          action: skipNextPageForm
        }
      : {
          isLoading: editMembersMutation.isLoading,
          label: t('EDITAR'),
          action: onSubmit
        }
  ];

  const renderModalEditMembers = () => {
    return (
      <Modal
        show={showModalEditMember}
        title={hasNextPageEditionMember ? t('EDITAR MEMBRO') : ''}
        width="550px"
        actions={editActions}>
        {hasNextPageEditionMember
          ? renderFormEditMember()
          : renderConfirmEditMember()}
      </Modal>
    );
  };

  return <>{renderModalEditMembers()}</>;
};

export { ModalEditMember };

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useMutation, useQuery } from 'react-query';
import { IUpdateCaptivePortal } from 'src/services/api/urls/captivePortal/types';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import { useNotification } from 'src/hooks/useNotification';
import { useToast } from 'src/hooks/useToast';
import { Button } from 'ui-components/Button';
import { useNavigate } from 'react-router-dom';
import { IDevicePaginationTable } from 'src/pages/Devices/types';
import { Tooltip } from 'inmaster-ui';
import { ICaptivePortal } from '../types';
import { ConfirmChangeMethodCaptiveModal } from '../ConfirmChangeMethodCaptiveModal';
import { ConfirmDisableMethodCaptiveModal } from '../ConfirmDisableMethodCaptiveModal';
import { IMethodTypeName } from '../SetUpMethod/types';
import BlockActiveCaptiveModal from '../BlockActiveCaptiveModal';

interface IActiveCaptivePortalButton {
  defaultCaptivePortal: ICaptivePortal;
  choosedMethod: IMethodTypeName;
  hasLog: boolean;
  isFetchedCaptivePortal: boolean;
  setDefaultCaptivePortal: Dispatch<SetStateAction<ICaptivePortal>>;
}

const ActiveCaptivePortalButton = ({
  defaultCaptivePortal,
  hasLog,
  choosedMethod,
  isFetchedCaptivePortal,
  setDefaultCaptivePortal
}: IActiveCaptivePortalButton) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.activeMethodCaptiveButton'
  });

  const { accessToken } = useAuth();

  const { addToast } = useToast();

  const navigate = useNavigate();

  const { triggerNotificationAnimationWithText } = useNotification();

  const [isLoadingMethodCaptive, setIsLoadingMethodCaptive] = useState(false);

  const [
    countDevicesWithAssociatedWireless,
    setCountDevicesWithAssociatedWireless
  ] = useState(0);

  const [showConfirmActiveMethodModal, setShowConfirmActiveMethodModal] =
    useState(false);

  const [showConfirmDisableMethodModal, setShowConfirmDisableMethodModal] =
    useState(false);

  const [showBlockActiveCaptiveModal, setShowBlockActiveCaptiveModal] =
    useState(false);

  const nameChoosedMethod = () => {
    switch (choosedMethod) {
      case 'password':
        return t('Senha Simples');
      case 'cpf':
        return t('Cadastro Pessoa Física');
      case 'individual':
        return t('Cadastro Individual');
      case 'voucher':
        return t('Voucher');
      default:
        return '';
    }
  };

  useQuery(
    ['getDevicesWithWireless', accessToken?.place_id],
    api.devices.hefesto.get,
    {
      onSuccess: ({ data }: { data: IDevicePaginationTable }) => {
        const devicesWithAssociatedWirelessList = data.devices.filter(
          (device) => device.has_associated_wireless
        );

        setCountDevicesWithAssociatedWireless(
          devicesWithAssociatedWirelessList.length
        );
      }
    }
  );

  const renderTooltipMessageActiveMethod = () => {
    if (isLoadingMethodCaptive) {
      return t('A ativação está sendo feita em segundo plano');
    }

    if (!hasLog) {
      switch (choosedMethod) {
        case 'password':
          return `${t('Adicione uma senha para ativar o método')}`;
        default:
          return `${t('Adicione um cadastro para ativar o método')}`;
      }
    }

    return t('Clique para ativar o método como padrão deste ambiente');
  };

  const renderTooltipMessageDesactiveMethod = () => {
    if (isLoadingMethodCaptive) {
      return t('A desativação está sendo feita em segundo plano');
    }

    return t('Clique para desativar o método como padrão deste ambiente');
  };

  const updateCaptivePortal = (
    id: string,
    auxCaptivePortal: ICaptivePortal
  ) => {
    return api.captivePortal.captive.put(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      { captive_portal: auxCaptivePortal } as IUpdateCaptivePortal,
      id
    );
  };

  const updateCaptivePortalMutation = useMutation(
    'updateCaptivePortal',
    (auxCaptivePortal: Omit<ICaptivePortal, 'is_blocked_to_activate'>) => {
      return updateCaptivePortal(
        defaultCaptivePortal.id || '',
        auxCaptivePortal
      );
    },
    {
      onSuccess: ({ data }: { data: IUpdateCaptivePortal }) => {
        setDefaultCaptivePortal({ ...data.captive_portal } as ICaptivePortal);

        if (data.captive_portal.mode) {
          addToast(
            'success',
            `${t('A ativação do Captive')} - ${nameChoosedMethod()} ${t(
              'foi iniciada'
            )}`
          );
          if (countDevicesWithAssociatedWireless > 0) {
            triggerNotificationAnimationWithText(t('ATIVANDO CAPTIVE PORTAL'));
          }
        } else {
          addToast(
            'success',
            `${t('A desativação do Captive')} - ${nameChoosedMethod()} ${t(
              'foi iniciada'
            )}`
          );
          if (countDevicesWithAssociatedWireless > 0) {
            triggerNotificationAnimationWithText(
              t('DESATIVANDO CAPTIVE PORTAL')
            );
          }
        }

        setIsLoadingMethodCaptive(false);
      },
      onError: () => {
        addToast(
          'error',
          'Desculpe não foi possível definir o método como padrão'
        );
        setIsLoadingMethodCaptive(false);
      }
    }
  );

  const checkIfHasNotCaptivePortalOrIsNotPasswordMode =
    !defaultCaptivePortal?.id || defaultCaptivePortal?.mode !== choosedMethod;

  const checkIfIsCaptivePortalBlockedToActivate = () => {
    if (defaultCaptivePortal.is_blocked_to_activate)
      setShowBlockActiveCaptiveModal(true);
    else setShowConfirmActiveMethodModal(true);
  };

  const checkIfIsCaptivePortalBlockedToDisable = () => {
    if (defaultCaptivePortal.is_blocked_to_activate)
      setShowBlockActiveCaptiveModal(true);
    else setShowConfirmDisableMethodModal(true);
  };

  const renderActiveAndDesactiveMethod = () => {
    if (checkIfHasNotCaptivePortalOrIsNotPasswordMode) {
      return (
        <Tooltip
          place="bottom-end"
          id="tooltip-active-method"
          content={renderTooltipMessageActiveMethod()}>
          <Button
            disabled={!hasLog}
            isLoading={isLoadingMethodCaptive}
            onClick={() => {
              checkIfIsCaptivePortalBlockedToActivate();
            }}>
            {t('Ativar Método')}
          </Button>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        place="bottom-end"
        id="tooltip-disable-method"
        content={renderTooltipMessageDesactiveMethod()}>
        <Button
          secondary
          isLoading={isLoadingMethodCaptive}
          onClick={() => {
            checkIfIsCaptivePortalBlockedToDisable();
          }}>
          {t('Desativar Método')}
        </Button>
      </Tooltip>
    );
  };

  const onConfirmChangeMethod = () => {
    const auxCaptivePortal = {
      ...defaultCaptivePortal,
      mode: choosedMethod
    } as ICaptivePortal;

    setIsLoadingMethodCaptive(true);
    setShowConfirmActiveMethodModal(false);
    updateCaptivePortalMutation.mutate(auxCaptivePortal);
  };

  const onCancelChangeMethod = () => {
    setShowConfirmActiveMethodModal(false);
  };

  const onConfirmDisableMethod = () => {
    const auxCaptivePortal = {
      ...defaultCaptivePortal,
      mode: undefined
    } as ICaptivePortal;

    setIsLoadingMethodCaptive(true);
    setShowConfirmDisableMethodModal(false);
    updateCaptivePortalMutation.mutate(auxCaptivePortal);
  };

  const onCancelDisableMethod = () => {
    setShowConfirmDisableMethodModal(false);
  };

  return (
    <>
      <ConfirmChangeMethodCaptiveModal
        showModalConfirmChange={showConfirmActiveMethodModal}
        onConfirmChange={onConfirmChangeMethod}
        onCancelChange={onCancelChangeMethod}
        isLoadingConfirmChange={isLoadingMethodCaptive}
      />

      <ConfirmDisableMethodCaptiveModal
        showModalDisableMethod={showConfirmDisableMethodModal}
        onConfirmDisableMethod={onConfirmDisableMethod}
        onCancelDisableMethod={onCancelDisableMethod}
        isLoadingDisableMethod={isLoadingMethodCaptive}
      />

      <BlockActiveCaptiveModal
        showModal={showBlockActiveCaptiveModal}
        setShowModal={setShowBlockActiveCaptiveModal}
      />

      <div className="d-flex align-center">
        {isFetchedCaptivePortal ? (
          <>
            <Button
              outline
              className="mr-3"
              onClick={() => navigate('/captive-portal')}>
              {t('Voltar')}
            </Button>
            {renderActiveAndDesactiveMethod()}
          </>
        ) : (
          <>
            <Skeleton width={120} height={50} className="mr-3" />
            <Skeleton width={120} height={50} />
          </>
        )}
      </div>
    </>
  );
};

export { ActiveCaptivePortalButton };

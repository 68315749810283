import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'ui-components/Input';

const IndividualRegisterForm = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.formForPreviewCaptive'
  });

  return (
    <>
      <Input placeholder={t('CPF')} id="input-cpf" />
      <Input
        placeholder={t('Nome')}
        className="mt-3"
        id="input-cpf"
        type="text"
      />
      <Input
        placeholder={t('Email')}
        className="mt-3"
        id="input-email"
        type="email"
      />
      <Input
        placeholder={t('Telefone')}
        className="mt-3"
        id="input-phone"
        type="tel"
      />
    </>
  );
};

export { IndividualRegisterForm };

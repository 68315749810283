import React, { useState, useCallback } from 'react';
import Cropper, { Point, Area } from 'react-easy-crop';
import Modal from 'ui-components/Modal';
import Range from 'src/components/Range';
import { Button } from 'ui-components/Button';
import { getCroppedImg } from 'src/utils/canvasUtils';
import { useTranslation } from 'react-i18next';
import styles from './CroppedImageModal.module.css';
import { Divider } from '../Divider';
import { ConfirmModal } from '../ConfirmModal';

interface IProps {
  show: boolean;
  onClose: () => void;
  onChange: (url: File | null) => void;
  imageSrc: string;
  imageName?: string;
  imageType?: string;
  aspect?: number;
  minZoom?: number;
  maxZoom?: number;
  zoomSpeed?: number;
  onCancel?: () => void;
  hasCancelModal?: boolean;
}

const CroppedImageModal = ({
  imageSrc,
  show,
  onClose,
  onChange,
  imageName = '',
  imageType = '',
  maxZoom,
  minZoom,
  aspect,
  zoomSpeed,
  onCancel,
  hasCancelModal
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'croppedImageModal'
  });

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [currentCroppedArea, setCurrentCroppedArea] = useState<Area>(
    {} as Area
  );
  const [showCancelModal, setShowCancelModal] = useState(false);

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCurrentCroppedArea(croppedAreaPixels);
  }, []);

  const handleClickCancel = () => {
    if (hasCancelModal) {
      setShowCancelModal(true);
    } else if (onCancel) {
      onCancel();
    } else {
      onClose();
    }
  };

  const handleCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        currentCroppedArea,
        imageName,
        imageType
      );
      onChange(croppedImage);
      onClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [imageSrc, currentCroppedArea, imageName, imageType, onChange, onClose]);

  return (
    <>
      {hasCancelModal && (
        <ConfirmModal
          isLoadingAction={false}
          showModal={showCancelModal}
          onConfirm={() => {
            setShowCancelModal(false);
            if (onCancel) onCancel();
          }}
          onCancel={() => setShowCancelModal(false)}>
          <span className="text-sm-lg">
            {t('Tem certeza que deseja sair?')}
          </span>
        </ConfirmModal>
      )}
      <Modal
        show={show}
        closable
        width="774px"
        title={t('Cortar imagem')}
        onClose={handleClickCancel}>
        <Divider />

        <div className={styles.container}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              cropAreaStyle: {
                border: '1px solid var(--color-neutral-light-5)'
              }
            }}
            zoomSpeed={zoomSpeed}
            minZoom={minZoom}
            maxZoom={maxZoom}
          />
        </div>

        <div className="d-flex align-center justify-center mt-6">
          <div className={styles.rangeContainer}>
            <Range
              hasTooltip
              onChange={(zoomValue) => setZoom(zoomValue)}
              value={zoom}
              min={1}
              max={10}
              step={0.1}
              isPercentage
            />
          </div>
        </div>

        <div className="d-flex align-center justify-center mt-6 mb-7">
          <Button outline className="mr-3" onClick={handleClickCancel}>
            {t('CANCELAR')}
          </Button>
          <Button onClick={handleCroppedImage}>
            {t('SALVAR E VISUALIZAR')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

CroppedImageModal.defaultProps = {
  aspect: 1,
  minZoom: 1,
  maxZoom: 10,
  zoomSpeed: 0.5,
  onCancel: null,
  hasCancelModal: false,
  imageName: '',
  imageType: ''
};

export default CroppedImageModal;

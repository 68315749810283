import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTemplate } from 'src/hooks/useTemplate';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import Card from 'ui-components/Card';
import Table from 'ui-components/Table';
import Tag from 'ui-components/Tags';
import api from 'src/services/api';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { useQuery } from 'react-query';
import {
  ISiteDataWireless,
  IWirelessSite
} from 'src/services/api/urls/sites/types';
import classNames from 'classnames';

import Skeleton from 'react-loading-skeleton';
import { useAuth } from 'src/hooks/useAuth';
import { generateColumns } from 'src/utils/tableUtils';

import { Tooltip } from 'inmaster-ui';
import { ISecurityTranslations, IWirelessRow } from './types';
import styles from './Wireless.module.css';
import DeleteWirelessModal from './DeleteWirelessModal';

const Wireless = () => {
  useTemplate('menuAndFullNavbar');
  const navigate = useNavigate();
  const { t } = useTranslation('translations', { keyPrefix: 'site.wireless' });
  const { accessToken } = useAuth();

  // States
  const [listWireless, setListWireless] = useState<IWirelessSite[]>(
    [] as IWirelessSite[]
  );
  const [selectedWireless, setSelectedWireless] = useState<IWirelessRow[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showModalDelete, setShowModalDelete] = useState(false);

  const addActionsColumnToWireless = (wirelessList: IWirelessSite[]) => {
    if (!wirelessList) {
      return [];
    }
    const wirelessObject = [] as IWirelessRow[];
    for (const wireless of wirelessList) {
      wirelessObject.push({
        devices_ids: wireless.devices_ids,
        id: wireless.id,
        name: wireless.name,
        security: wireless.is_captive_portal_enabled
          ? t('Captive Portal')
          : wireless.security,
        frequency:
          wireless.frequency !== 'dualband'
            ? `${wireless.frequency}GHz`
            : '2.4GHz | 5GHz',
        enabled: !wireless.enabled,
        hidden: wireless.hidden,
        count_devices: wireless.count_devices,
        isVisible: (
          <div>
            {wireless.enabled ? (
              <div>
                {wireless.hidden ? (
                  <Tag color="neutral-dark-4" outlined wide>
                    {t('Oculta')}
                  </Tag>
                ) : (
                  <Tag color="blue" outlined wide>
                    {t('Visível')}
                  </Tag>
                )}
              </div>
            ) : (
              <Tag color="lightblue" outlined wide>
                {t('Desabilitada')}
              </Tag>
            )}
          </div>
        ),
        actions: (
          <ButtonIcon onClick={() => navigate(`/wireless/edit/${wireless.id}`)}>
            <MdEdit size={24} />
          </ButtonIcon>
        )
      });
    }
    return wirelessObject;
  };

  const colunas = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('security', t('Segurança'), 'left', 0),
    generateColumns('frequency', t('Frequência'), 'left', 0),
    generateColumns('isVisible', t('Visibilidade'), 'left', 0),
    generateColumns('actions', t('Ações'), 'left', 0)
  ];

  const wirelessSecurityTranslations: ISecurityTranslations = {
    Open: t('Aberta'),
    'WPA-PSK': 'WPA-PSK',
    'WPA2-PSK': 'WPA2-PSK'
  };

  // Queries
  const getWireless = async () => {
    return api.wireless.get();
  };

  const {
    refetch: refetchWireless,
    isLoading,
    isFetchedAfterMount
  } = useQuery(['getSites', accessToken?.place_id], getWireless, {
    onSuccess: (data: ISiteDataWireless) => {
      data.data.wireless = data.data.wireless.map((wireless) => {
        wireless.security =
          wirelessSecurityTranslations[
            wireless.security as keyof ISecurityTranslations
          ];

        return wireless;
      });
      setListWireless(data.data.wireless);
      setIsDeleting(false);
    }
  });

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-around my-4 align-center"
          key={`${i}-skeleton-wireless-table`}>
          <Skeleton count={1} height={25} width={25} className="ml-2" />
          <Skeleton count={1} height={14} width={100} />
          <Skeleton count={1} height={14} width={100} />
          <Skeleton count={1} height={14} width={100} />
          <Skeleton count={1} height={30} width={128} borderRadius={100} />
          <Skeleton count={1} height={45} width={45} className="mr-3" />
        </div>
      );
    }

    return skeletons;
  };

  const onSuccessDeleteWireless = () => {
    refetchWireless();
    setSelectedWireless([]);
  };

  return (
    <Grid fluid>
      <>
        <DeleteWirelessModal
          onSuccessDeleteWireless={onSuccessDeleteWireless}
          selectedWireless={selectedWireless}
          setIsDeleting={setIsDeleting}
          showModalDelete={showModalDelete}
          setShowModalDelete={setShowModalDelete}
        />
        <Row>
          <Col xs={12} className="mb-7 d-flex flex-column">
            <div>
              <h2
                className="title-xl-base text-uppercase"
                style={{ color: 'var(--color-neutral-dark-3)' }}>
                {t('Wireless')}
              </h2>
            </div>
            <div className="mt-1">
              <span
                className={classNames([
                  'text-sm-base',
                  styles.wirelessMaxCountInfo
                ])}>
                {t('É possível configurar até 8 Wireless por ambiente')}
              </span>
            </div>
          </Col>
          {listWireless.length === 0 && !isLoading && isFetchedAfterMount ? (
            <div className="fit-width">
              <EmptyMessage
                height="calc(100vh - 280px)"
                title={t('Nenhuma wireless')}
                subtitle={t(
                  'Ainda não há wireless disponível neste ambiente. Experimente adicionar uma nova'
                )}
                action={() => navigate('/wireless/add')}
                id="add-first-wireless"
                buttonText={t('ADICIONAR WIRELESS')}
              />
            </div>
          ) : (
            <div className="fit-width">
              <Card className="fit-width d-flex align-center mb-7">
                {selectedWireless?.length === 0 ? (
                  <div className="fit-width d-flex justify-end">
                    {!isFetchedAfterMount ? (
                      <Skeleton
                        className="my-3 mx-4"
                        count={1}
                        height={50}
                        width={230}
                        borderRadius={10}
                      />
                    ) : (
                      <Tooltip
                        content={t('Limite de criação de 8 Wireless atingido')}
                        place="bottom"
                        id="tooltip-limit-wireless"
                        disabled={!(listWireless.length >= 8 || isLoading)}>
                        <ButtonTextOutside
                          value={t('Adicionar wireless')}
                          className="text-uppercase mt-6 mb-6 ml-7">
                          <Button
                            id="button-add-wireless-now"
                            rounded
                            disabled={
                              listWireless.length >= 8 ||
                              isLoading ||
                              isDeleting
                            }
                            onClick={() => navigate('/wireless/add')}>
                            <MdAdd size={22} />
                          </Button>
                        </ButtonTextOutside>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <ButtonTextOutside
                    id="button-delete-wireless-text"
                    value={`${t('Excluir Wireless')} (${
                      selectedWireless?.length
                    })`}
                    className="text-uppercase mt-6 mb-6 ml-7">
                    <Button
                      id="button-remove-wireless-now"
                      rounded
                      onClick={() => setShowModalDelete(true)}
                      color="var(--color-status-critical-base)"
                      isLoading={isDeleting}>
                      <MdDelete size={22} />
                    </Button>
                  </ButtonTextOutside>
                )}
              </Card>
              <Card className="fit-width mb-8">
                {isDeleting || !isFetchedAfterMount ? (
                  <div className="d-flex flex-column">
                    <Skeleton
                      count={1}
                      height={50}
                      borderRadius={10}
                      className="mb-3"
                    />
                    {renderSkeletonsOfTable(5)}
                  </div>
                ) : (
                  <Table
                    columns={colunas}
                    rows={addActionsColumnToWireless(listWireless)}
                    onSelectRows={(selectedRows: IWirelessRow[]) =>
                      setSelectedWireless(selectedRows)
                    }
                    selectedRows={selectedWireless}
                    keyToCompare="id"
                    elementToInactiveRow="enabled"
                    id="wirelesses-table"
                    disableSelection={isDeleting}
                  />
                )}
              </Card>
            </div>
          )}
        </Row>
      </>
    </Grid>
  );
};

export { Wireless };

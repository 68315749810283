import React, { ReactNode } from 'react';
import { Navbar } from 'src/components/Navbar';
import { INavbarActions } from 'src/components/Navbar/types';

import styles from './NavbarWithAvatarTemplate.module.css';

interface ITemplateProps {
  children: ReactNode;
  navbarActions?: INavbarActions;
}
const NavbarWithAvatarTemplate = ({
  children,
  navbarActions
}: ITemplateProps) => {
  return (
    <div className={`${styles.container}`} id="view">
      <Navbar logo actions={navbarActions} />
      {children}
    </div>
  );
};

NavbarWithAvatarTemplate.defaultProps = {
  navbarActions: {
    config: false,
    notification: false,
    avatar: true,
    helper: true
  }
};

export { NavbarWithAvatarTemplate };

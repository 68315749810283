import { useMutation } from 'react-query';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import api from 'src/services/api';
import Modal from 'ui-components/Modal';
import { useAuth } from 'src/hooks/useAuth';
import Checkbox from 'ui-components/Checkbox';
import Label from 'ui-components/Label';
import { IErrorResponse } from '../../Sites';
import { IDeletePlaceData, IPlaceItemPage } from '../types';
import { ISelectedSiteData } from '../../Sites/types';

interface IModalRemovePlace {
  currentDeletingPlace: IPlaceItemPage;
  setCurrentDeletingPlace: Dispatch<SetStateAction<IPlaceItemPage>>;
  selectedSite: ISelectedSiteData;
  showModalDeletePlace: boolean;
  setShowModalDeletePlace: Dispatch<SetStateAction<boolean>>;
  onDeletingPlace: () => void;
  setPlaceIdBeingEditedOrDeleted: Dispatch<SetStateAction<string | null>>;
  setPlaces: React.Dispatch<React.SetStateAction<IPlaceItemPage[]>>;
}

const ModalRemovePlace = (props: IModalRemovePlace) => {
  const [disableSubmitButtonDeleteForm, setDisableSubmitButtonDeleteForm] =
    useState(false);

  const { t } = useTranslation('translations', { keyPrefix: 'site.place' });

  const { addToast } = useToast();

  const { accessTokenInfo } = useAuth();

  const {
    currentDeletingPlace,
    setCurrentDeletingPlace,
    selectedSite,
    showModalDeletePlace,
    setShowModalDeletePlace,
    onDeletingPlace,
    setPlaceIdBeingEditedOrDeleted,
    setPlaces
  } = props;

  const {
    handleSubmit: handleSubmitDeleteForm,
    control: controlDeleteForm,
    setValue: setValueDeleteForm,
    watch
  } = useForm<IDeletePlaceData>({
    defaultValues: {
      checkContinueDelete: false
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const deletePlaceMutation = useMutation(
    () => {
      const data = {
        email: accessTokenInfo ? accessTokenInfo.email : ''
      };
      const { id } = currentDeletingPlace;
      return api.sites.places.delete(id, selectedSite.id, data);
    },
    {
      onSuccess: () => {
        setPlaces((prev) =>
          prev.filter((place) => place.id !== currentDeletingPlace.id)
        );
        setShowModalDeletePlace(false);
        addToast('success', t('Ambiente excluído com sucesso'));
        setValueDeleteForm('checkContinueDelete', false);
        setDisableSubmitButtonDeleteForm(false);
        onDeletingPlace();
      },
      onError: (error: IErrorResponse) => {
        setDisableSubmitButtonDeleteForm(false);
        if (error.response.status === 404) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        }

        setShowModalDeletePlace(false);
      },
      onMutate: () => {
        setPlaceIdBeingEditedOrDeleted(currentDeletingPlace.id);
      }
    }
  );

  const onSubmitDeleteForm = handleSubmitDeleteForm(() => {
    deletePlaceMutation.mutate();
    setDisableSubmitButtonDeleteForm(true);
  });

  const renderModalDelete = () => {
    if (
      currentDeletingPlace.device_count &&
      currentDeletingPlace?.device_count > 0
    ) {
      return (
        <>
          <span>
            {t('Todas as configurações do ambiente')}{' '}
            <span className="text-bold">
              {currentDeletingPlace && currentDeletingPlace.name}
            </span>{' '}
            {t(
              'serão excluídas permanentemente, os equipamentos receberão padrão de fábrica. Deseja continuar'
            )}
            ?
          </span>
          <Controller
            control={controlDeleteForm}
            name="checkContinueDelete"
            render={({ field: { value } }) => (
              <div className="d-flex mt-3">
                <Checkbox
                  checked={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValueDeleteForm(
                      'checkContinueDelete',
                      event.target.checked
                    );
                  }}
                />
                <Label className="text-base-base ml-3">
                  {t('Estou ciente e desejo continuar')}
                </Label>
              </div>
            )}
          />
        </>
      );
    }
    return (
      <span>
        {t('Deseja realmente excluir o ambiente')}{' '}
        <span className="text-bold">
          {currentDeletingPlace && currentDeletingPlace.name}
        </span>
        ?
      </span>
    );
  };

  return (
    <Modal
      width="550px"
      show={showModalDeletePlace}
      actions={[
        {
          label: t('NÃO'),
          action: () => {
            setShowModalDeletePlace(false);
            setCurrentDeletingPlace({} as IPlaceItemPage);
            setValueDeleteForm('checkContinueDelete', false);
          },
          disabled:
            disableSubmitButtonDeleteForm || deletePlaceMutation.isLoading
        },
        {
          label: t('SIM'),
          action: () => {
            onSubmitDeleteForm();
          },
          disabled:
            disableSubmitButtonDeleteForm ||
            (currentDeletingPlace.device_count &&
              currentDeletingPlace.device_count > 0 &&
              !watch('checkContinueDelete')),
          isLoading: deletePlaceMutation.isLoading
        }
      ]}>
      <div>{renderModalDelete()}</div>
    </Modal>
  );
};

export { ModalRemovePlace };

import React from 'react';
import {
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import { IErrorResponse } from 'src/pages/Sites';
import api from 'src/services/api';
import Drawer from 'ui-components/Drawer';
import { Button } from 'ui-components/Button';
import { ICreateSimplePassword } from 'src/services/api/urls/captivePortal/types';
import { useAuth } from 'src/hooks/useAuth';
import moment from 'moment';
import FormSimplePassword from 'src/components/FormSimplePassword';
import { convertMomentToTimezone } from 'src/utils/DatesAndTimesConvert';
import { IFormSimplePassword, ISimplePassword } from './types';
import styles from './styles.module.css';
import ConfirmChangePasswordModal from './ConfirmChangePasswordModal';

interface IProps {
  captiveId: string;
  hasActivePassword: boolean;
  show: boolean;
  onClose: () => void;
}

const DrawerFormSimplePassword = ({
  captiveId,
  show,
  hasActivePassword,
  onClose
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.simplePassword'
  });

  const { accessToken, siteTimezone } = useAuth();

  const [showConfirmChangePassword, setShowConfirmChangePassword] =
    React.useState(false);

  const [createdPassword, setCreatedPassword] =
    React.useState<ICreateSimplePassword>({} as ICreateSimplePassword);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger,
    resetField
  } = useForm<IFormSimplePassword>({
    defaultValues: {
      simplePassword: {
        confirmPassword: '',
        password: '',
        periodType: '',
        periodInDays: [null, null],
        periodInTime: undefined
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { addToast } = useToast();

  const addSimplePasswordMutation = useMutation(
    (data: ICreateSimplePassword) => {
      return api.captivePortal.simplePassword.post(
        data,
        accessToken?.site_id || '',
        accessToken?.place_id || '',
        captiveId || ''
      );
    },
    {
      onSuccess: () => {
        addToast('success', t('Senha simples adicionada com sucesso'));
        reset();
        onClose();
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          for (const item of error.response.data.message) {
            const field = item.attr.pop() as
              | 'simplePassword.password'
              | 'simplePassword.confirmPassword'
              | 'simplePassword.period';
            setError(`simplePassword.${field as keyof ISimplePassword}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }
        }
      }
    }
  );

  const checkIfPasswordsAreEqual = (
    password: string,
    confirmPassword: string
  ) => {
    if (password !== confirmPassword) {
      setError('simplePassword.confirmPassword', {
        types: {
          request: t('As senhas não coincidem. Tente novamente') as string
        }
      });
      setError('simplePassword.password', {
        types: {
          request: t('As senhas não coincidem. Tente novamente') as string
        }
      });
      return false;
    }
    return true;
  };

  const onSubmit = handleSubmit((data: IFormSimplePassword) => {
    if (
      !checkIfPasswordsAreEqual(
        data.simplePassword.password,
        data.simplePassword.confirmPassword
      )
    ) {
      return;
    }

    let getExpiredAt = '';
    const checkIfPeriodTypeIsDateAndHasValue =
      data.simplePassword.periodType === 'date' &&
      data.simplePassword.periodInDays[1];

    const checkIfPeriodTypeIsTimeAndHasValue =
      data.simplePassword.periodType === 'time' &&
      data.simplePassword.periodInTime;

    if (checkIfPeriodTypeIsDateAndHasValue) {
      getExpiredAt = moment(
        data.simplePassword.periodInDays[1] &&
          data.simplePassword.periodInDays[1].toString()
      )
        .add(moment().format('HH:mm'), 'hours')
        .format();
    } else if (checkIfPeriodTypeIsTimeAndHasValue) {
      const getHours =
        data.simplePassword.periodInTime &&
        new Date(data.simplePassword.periodInTime.value)
          .toTimeString()
          .split(' ')[0];
      getExpiredAt = moment().add(getHours).format();
    }

    const addSimplePassword = {
      simple_password: {
        password: data.simplePassword.password,
        expires_at: convertMomentToTimezone(
          getExpiredAt,
          siteTimezone
        ).format(),

        created_at: moment()
          .tz(siteTimezone || 'America/Sao_Paulo')
          .format()
      }
    } as ICreateSimplePassword;

    setCreatedPassword(addSimplePassword);

    if (hasActivePassword) {
      setShowConfirmChangePassword(true);
    } else {
      addSimplePasswordMutation.mutate(addSimplePassword);
    }
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onConfirmChangePassword = () => {
    addSimplePasswordMutation.mutate(createdPassword);
    setShowConfirmChangePassword(false);
  };

  const onCancelChangePassword = () => {
    setShowConfirmChangePassword(false);
    reset();
    onClose();
  };

  return (
    <>
      <ConfirmChangePasswordModal
        showModal={showConfirmChangePassword}
        onCancel={onCancelChangePassword}
        onConfirm={onConfirmChangePassword}
        isLoadingAction={addSimplePasswordMutation.isLoading}
      />
      <Drawer
        show={show}
        onClose={handleClose}
        closable
        title={t('ADICIONAR SENHA SIMPLES')}
        responsiveWidth={{ lg: '40vw' }}>
        <form onSubmit={onSubmit} className={styles.formSimplePasword}>
          <FormSimplePassword
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
            resetField={resetField}
          />
          <div className="fit-width d-flex justify-end">
            <Button
              type="submit"
              isLoading={addSimplePasswordMutation.isLoading}>
              {t('SALVAR')}
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default DrawerFormSimplePassword;

import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { Alert } from 'ui-components/Alert';
import Modal from 'ui-components/Modal';
import { useNotification } from 'src/hooks/useNotification';
import _ from 'lodash';
import { IWirelessRow } from '../types';

interface IDeleteWirelessModalProps {
  showModalDelete: boolean;
  selectedWireless: IWirelessRow[];
  onSuccessDeleteWireless: () => void;
  setShowModalDelete: Dispatch<SetStateAction<boolean>>;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
}

const DeleteWirelessModal = ({
  showModalDelete,
  selectedWireless,
  onSuccessDeleteWireless,
  setIsDeleting,
  setShowModalDelete
}: IDeleteWirelessModalProps) => {
  const { addToast } = useToast();

  const { t } = useTranslation('translations', { keyPrefix: 'site.wireless' });

  // mutations
  const deleteWirelessList = (data: IWirelessRow[]) => {
    return api.wireless.deleteList(data.map((item: IWirelessRow) => item.id));
  };

  const { triggerNotificationAnimationWithText } = useNotification();

  const checkIfWirelessHasDevices = () => {
    return selectedWireless.some((wireless) => wireless.devices_ids.length > 0);
  };

  const getListOfIdsAssociatedWithWireless = () => {
    const devicesIdsAssociatedToWireless = [] as string[];

    selectedWireless.map((wireless) => {
      devicesIdsAssociatedToWireless.push(...wireless.devices_ids);
      return wireless;
    });
    
    return _.uniq(devicesIdsAssociatedToWireless);
  };

  const generatePluralOrSingular = () => {
    const hasMoreThanOneDevice =
      getListOfIdsAssociatedWithWireless().length > 1;

    if (hasMoreThanOneDevice) {
      return t('CONFIGURANDO EQUIPAMENTOS');
    }

    return t('CONFIGURANDO EQUIPAMENTO');
  };

  const { mutate: deleteWirelessListMutate } = useMutation(deleteWirelessList, {
    onSuccess: () => {
      addToast(
        'success',
        selectedWireless.length === 1
          ? t('Wireless excluída com sucesso')
          : `${selectedWireless.length} ${t('Wireless excluídas com sucesso')}`
      );

      if (checkIfWirelessHasDevices()) {
        triggerNotificationAnimationWithText(generatePluralOrSingular());
      }

      setIsDeleting(false);

      onSuccessDeleteWireless();
    }
  });

  const onConfirmDeleteWirelessAction = () => {
    setIsDeleting(true);
    setShowModalDelete(false);
    deleteWirelessListMutate(selectedWireless);
  };

  const onCancelDeleteWirelessAction = () => {
    setShowModalDelete(false);
    setIsDeleting(false);
  };

  return (
    <Modal
      width="500px"
      show={showModalDelete}
      actions={[
        { label: t('Cancelar'), action: onCancelDeleteWirelessAction },
        { label: t('Excluir'), action: onConfirmDeleteWirelessAction }
      ]}>
      {selectedWireless.length === 1 ? (
        <>
          <div className="mb-2">
            {t('Deseja realmente excluir a Wireless')}{' '}
            <span className="text-bold">{selectedWireless[0].name}</span>?
          </div>
          {checkIfWirelessHasDevices() && (
            <Alert type="warning" className="mt-4">
              {t(
                'Todos os equipamentos associados a esta wireless deixarão de transmiti-la'
              )}
              .
            </Alert>
          )}
        </>
      ) : (
        <>
          <div className="mb-2">
            {t('Deseja realmente excluir')} {selectedWireless.length}{' '}
            {t('Wireless')}?
          </div>
          {checkIfWirelessHasDevices() && (
            <Alert type="warning">
              {t(
                'Todos os equipamentos associados a estas wireless deixarão de transmiti-las'
              )}
              .
            </Alert>
          )}
        </>
      )}
    </Modal>
  );
};

export default DeleteWirelessModal;

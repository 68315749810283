import React from 'react';
import classNames from 'classnames';
import { Col } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import {
  MdSignalWifiStatusbar1Bar,
  MdSignalWifiStatusbar2Bar,
  MdSignalWifiStatusbar3Bar,
  MdSignalWifiStatusbar4Bar
} from 'react-icons/md';
import { EmptyMessage } from 'src/components/EmptyMessage';
import Card from 'ui-components/Card';

import { Tooltip } from 'inmaster-ui';
import styles from './ConnectedReceivers.module.css';
import { IConnectedReceiversProps } from './types';

const ConnectedReceivers = ({ receivers }: IConnectedReceiversProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const generateSignalIcon = (signal: number) => {
    if (signal >= -67) {
      return (
        <Tooltip
          content={`${signal}dBm`}
          place="bottom-end"
          id="tooltip-signal-wifi4">
          <MdSignalWifiStatusbar4Bar
            size={24}
            style={{ color: 'var(--color-brand-secondary-light)' }}
          />
        </Tooltip>
      );
    }
    if (signal >= -73) {
      return (
        <Tooltip
          content={`${signal}dBm`}
          place="bottom-end"
          id="tooltip-signal-wifi3">
          <MdSignalWifiStatusbar3Bar
            size={24}
            style={{ color: 'var(--color-brand-secondary-light)' }}
          />
        </Tooltip>
      );
    }
    if (signal >= -80) {
      return (
        <Tooltip
          content={`${signal}dBm`}
          place="bottom-end"
          id="tooltip-signal-wifi2">
          <MdSignalWifiStatusbar2Bar
            size={24}
            style={{ color: 'var(--color-brand-secondary-light)' }}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        content={`${signal}dBm`}
        place="bottom-end"
        id="tooltip-signal-wifi1">
        <MdSignalWifiStatusbar1Bar
          size={24}
          style={{ color: 'var(--color-brand-secondary-light)' }}
        />
      </Tooltip>
    );
  };

  return (
    <div className="mt-10">
      <Col xs={12} className="d-flex justify-between align-center">
        <h3
          className="title-xl-base text-uppercase"
          style={{ color: 'var(--color-neutral-dark-3)' }}>
          {t('Receptores conectados (RX)')}
        </h3>
      </Col>

      {receivers.length === 0 ? (
        <EmptyMessage
          id="no-rx"
          title={t('Nenhum RX disponível')}
          subtitle={t('Ainda não há RX conectados neste esquipamento')}
          height=" calc(100vh - 655px)"
          minHeight="190px"
        />
      ) : (
        <div className="mt-10 mt-7">
          {receivers.map((rx) => (
            <Card
              borderColor="var(--color-brand-secondary-light)"
              className={classNames([
                'px-8 d-flex aling-center justify-between align-center',
                styles.cardShell
              ])}>
              <span className={styles.textSmallXs}>
                <span className="text-bold">MAC</span> {rx.mac}
              </span>

              {generateSignalIcon(rx.signal)}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default ConnectedReceivers;

import moment from 'moment-timezone';

export const dateToTimestamp = (
  date: string | undefined,
  timezoneString: string
) => {
  const dateObject = moment(date).parseZone();
  const dateInMilliseconds = dateObject.tz(timezoneString).valueOf();
  return dateInMilliseconds;
};

export const convertSecondsToMilis = (seconds: number) => {
  return seconds * 1000;
};

export const convertDateWithTimezone = (date: string | undefined) => {
  return moment(date).parseZone().format('DD/MM/YYYY HH:mm:ss');
};

export const convertMomentToTimezone = (
  date: string | undefined,
  timezone: string | undefined
) => {
  if (timezone) {
    return moment(date).tz(timezone);
  }
  return moment(date);
};

import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui-components/Alert';
import Label from 'ui-components/Label';
import Toggle from 'ui-components/Toggle';
import stylesAdvancedWireless from '../AdvancedWireless.module.css';
import styles from './BeamformingSection.module.css';
import { IAdvancedWirelessForm } from '../types';
import { MultiUserMIMOSection } from '../MultiUserMIMOSection';

interface IBeamformingSectionProps {
  controlForm: Control<IAdvancedWirelessForm, unknown>;
  watch: UseFormWatch<IAdvancedWirelessForm>;
}

const BeamformingSection = (props: IBeamformingSectionProps) => {
  const { controlForm: control, watch } = props;
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless.beamforming'
  });

  return (
    <Row className="fit-width">
      <Col xs={12}>
        <Label className="d-flex text-bold text-xl-lg mb-2">
          {t('Beamforming')}
        </Label>
      </Col>
      <Col xs={12} className={`d-flex ${stylesAdvancedWireless.columnsGap}`}>
        <Col xs={6}>
          <div className="mb-2">
            <Alert type="warning">
              {t(
                'O Beamforming requer que o dispositivo do cliente tenha suporte a esta tecnologia para atuar ativamente'
              )}
            </Alert>
          </div>
          <div>
            <p className="text-sm-base">
              {t(
                'O Beamforming garante que o sinal do Wi-Fi seja direcionado diretamente aos dispositivos dos clientes, evitando perdas durante o processo de transmissão e melhorando o nível de sinal na área de 360° ao redor do Access Point, proporcionando ainda uma melhor performance com o MU-MIMO.'
              )}
            </p>
          </div>
        </Col>
        <Col xs={5}>
          <div className={classNames(['d-flex mb-4'])}>
            <Controller
              control={control}
              name="beamforming"
              render={({ field: { onChange, value } }) => (
                <Toggle
                  color="var(--color-status-ok-base)"
                  disabled={watch('mu_mimo')}
                  checked={watch('mu_mimo') || value}
                  id="beamforming"
                  onChange={onChange}
                />
              )}
            />
            <span>
              {t('Beamforming')}
              <span
                className={classNames([
                  stylesAdvancedWireless.labelDetails,
                  watch('mu_mimo') && styles.disabledLabel
                ])}>
                {t('Disponível para os modelos Wave 2')}
              </span>
            </span>
          </div>
          {watch('beamforming') && (
            <MultiUserMIMOSection controlForm={control} />
          )}
        </Col>
      </Col>
    </Row>
  );
};

export { BeamformingSection };

import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import Modal from 'ui-components/Modal';
import { IErrorResponse } from '..';
import { ISelectedSiteData, ISharedSiteItemPage } from '../types';
import styles from './ModalExitSharedSite.module.css';

interface IModalExitSharedSite {
  showModalConfirmSharedSiteExit: boolean;
  sharedSite?: ISelectedSiteData | null;
  memberId?: string | null;
  onDeleteSharedSite: () => void;
  setSharedSites: React.Dispatch<React.SetStateAction<ISharedSiteItemPage[]>>;
}

const ModalExitSharedSite = (props: IModalExitSharedSite) => {
  const { t } = useTranslation('translations', { keyPrefix: 'site' });
  const { addToast } = useToast();
  const {
    showModalConfirmSharedSiteExit,
    sharedSite,
    onDeleteSharedSite,
    memberId,
    setSharedSites
  } = props;
  const [showModalFeedbackSharedSiteExit, setShowModalFeedbackSharedSiteExit] =
    useState(false);

  const exitMemberMutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => api.sites.members.delete(sharedSite!.id, memberId!),
    {
      onSuccess: () => {
        setSharedSites((prev) =>
          prev.filter((site) => site.id !== sharedSite?.id)
        );
        onDeleteSharedSite();
        setShowModalFeedbackSharedSiteExit(true);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        }

        onDeleteSharedSite();
      }
    }
  );
  const renderModalConfirmSharedSiteExit = () => {
    return (
      <Modal
        width="550px"
        show={showModalConfirmSharedSiteExit}
        actions={[
          {
            label: t('NÃO'),
            action: () => {
              onDeleteSharedSite();
            },
            disabled: exitMemberMutation.isLoading
          },
          {
            label: t('SIM'),
            action: () => {
              exitMemberMutation.mutate();
            },
            disabled: exitMemberMutation.isLoading,
            isLoading: exitMemberMutation.isLoading
          }
        ]}>
        <span>
          {t('Ao sair do local')}{' '}
          <span className="text-uppercase">
            {sharedSite && sharedSite.name}
          </span>{' '}
          {t(
            'você perderá todos os seus acessos e permissões. Deseja continuar?'
          )}
        </span>
      </Modal>
    );
  };

  const renderModalFeedbackSharedSiteExit = () => {
    return (
      <Modal
        width="650px"
        show={showModalFeedbackSharedSiteExit}
        actions={[
          {
            label: t('ENTENDI'),
            action: () => {
              exitMemberMutation.reset();
              setShowModalFeedbackSharedSiteExit(false);
            },
            primary: true
          }
        ]}>
        <div className="d-flex flex-column align-center justify-center">
          <MdCheckCircle size={80} color="var(--color-brand-secondary-light)" />

          <span
            className={classNames([
              'text-bold mt-4 mb-4',
              styles.localRemovedText
            ])}>
            {t('Local removido com sucesso')}
          </span>

          <span>
            {t(
              'Para ter acesso novamente, entre em contato com o proprietário do local'
            )}
          </span>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {renderModalConfirmSharedSiteExit()}
      {renderModalFeedbackSharedSiteExit()}
    </>
  );
};

ModalExitSharedSite.defaultProps = {
  sharedSite: null,
  memberId: null
};

export { ModalExitSharedSite };

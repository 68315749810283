import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

const RequireFirstAccess = ({ children }: { children: JSX.Element }) => {
  const { accessToken } = useAuth();
  const location = useLocation();
  if (
    accessToken &&
    accessToken.site === null &&
    !location.pathname.includes('/sites') &&
    !location.pathname.includes('/informations') &&
    !location.pathname.includes('/error') &&
    location.pathname !== '/first-access'
  ) {
    return <Navigate to="/sites" replace />;
  }

  return children;
};

export { RequireFirstAccess };

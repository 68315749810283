import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import { Controller, useForm } from 'react-hook-form';
import RadioButton from 'ui-components/RadioButton';
import Label from 'ui-components/Label';
import { ISharedSiteItemPage, ISiteItemPage } from 'src/pages/Sites/types';
import { useMutation } from 'react-query';
import api from 'src/services/api';
import { MdCheckCircle } from 'react-icons/md';
import classNames from 'classnames';
import { IErrorResponse } from 'src/pages/Sites';
import { useToast } from 'src/hooks/useToast';
import { Alert } from 'ui-components/Alert';
import styles from './ModalCreateMembers.module.css';
import { IDataMember, RoleMember } from './types';
import InputWithValidation from '../InputWithValidation';

interface PropsModalCreateMember {
  showModalCreateMember: boolean;
  setShowModalCreateMember: Dispatch<SetStateAction<boolean>>;
  site: ISiteItemPage | ISharedSiteItemPage | null;
  onMemberCreated: (newMembersCount: number) => void;
}

const ModalCreateMembers = (props: PropsModalCreateMember) => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', { keyPrefix: 'members' });
  const {
    showModalCreateMember,
    setShowModalCreateMember,
    site,
    onMemberCreated
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    setError,
    watch,
    formState: { errors }
  } = useForm<IDataMember>({
    defaultValues: {
      member: {
        email: '',
        role: RoleMember.GUEST
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });
  const [userIsRegistered, setUserIsRegistered] = useState(true);

  const siteId = site ? site.id : '';

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('member.role', event.target.defaultValue as RoleMember);
  };

  const addMembersMutation = useMutation(
    (data: IDataMember) => {
      return api.sites.members.post(data, siteId);
    },
    {
      onSuccess: ({ data }) => {
        if (data.user.account_status === 'not-registered') {
          setUserIsRegistered(false);
        } else {
          setUserIsRegistered(true);
        }
        clearErrors();
        onMemberCreated(data.site_counters.user_count);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          for (const item of error.response.data.message) {
            const field = item.attr.pop() as 'site.name' | 'site.timezone';
            setError(`member.${field as 'email'}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }
        }
      }
    }
  );

  const onSubmit = handleSubmit((data: IDataMember) => {
    addMembersMutation.mutate(data);
  });

  const renderAddedSuccessMember = () => {
    return (
      <div className="d-flex justify-center align-center fit-width flex-column">
        <div className={classNames([styles.checkIcon, 'mb-5'])}>
          <MdCheckCircle size={96} />
        </div>
        <div>
          <div
            className={classNames([
              'd-flex justify-center align-center fit-width mb-2',
              styles.addedSuccessfullyMemberTexts
            ])}>
            <span className="title-sm-base text-center">
              {t('O')} {watch('member.email')} {t('foi adicionado com sucesso')}
            </span>
          </div>
          <div
            className={classNames([
              'd-flex justify-center align-center fit-width text-center',
              styles.addedSuccessfullyMemberTexts
            ])}>
            {userIsRegistered ? (
              <span>
                {t('Ao realizar o próximo')}{' '}
                <span style={{ fontStyle: 'italic' }}>login</span>{' '}
                {t('com o e-mail informado ele terá acesso ao local')}{' '}
                {site?.name}
              </span>
            ) : (
              <Alert type="warning">
                <span style={{ textAlign: 'left' }}>
                  {t('O membro')}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {t('não possui conta no InMaster')}
                    {', '}
                  </span>
                  {t(
                    'solicite que ele realize o cadastro com o e-mail informado para poder ter acesso ao local compartilhado'
                  )}
                </span>
              </Alert>
            )}
          </div>
        </div>
      </div>
    );
  };

  const addActions = [
    {
      label: t('CANCELAR'),
      disabled: addMembersMutation.isLoading,
      action: () => {
        clearErrors();
        setShowModalCreateMember(false);
        reset();
      }
    },
    {
      label: t('ADICIONAR'),
      action: onSubmit,
      isLoading: addMembersMutation.isLoading,
      disabled: errors.member?.email
    }
  ];

  const successfullyAddedMemberActions = [
    {
      primary: true,
      label: t('ENTENDI'),
      action: () => {
        setShowModalCreateMember(false);
        addMembersMutation.reset();
        reset();
      }
    }
  ];

  const renderCreateOrEditModalMembers = () => {
    return (
      <Modal
        show={showModalCreateMember}
        title={addMembersMutation.isSuccess ? '' : t('ADICIONAR MEMBRO')}
        width="550px"
        actions={
          addMembersMutation.isSuccess
            ? successfullyAddedMemberActions
            : addActions
        }>
        {!addMembersMutation.isSuccess ? (
          <>
            <div className="mb-2">
              <span>{t('Adicione um novo membro ao local')} </span>
              <b className="title-sm-base">{site && site.name}</b>
            </div>
            <form onSubmit={onSubmit}>
              <label className="text-sm-xs" htmlFor="email">
                {t('E-mail:')}
              </label>
              <InputWithValidation
                inputProps={{
                  itemType: 'email',
                  id: 'email'
                }}
                control={control}
                controllerProps={{
                  name: 'member.email',
                  rules: {
                    required: {
                      value: true,
                      message: t('Campo obrigatório')
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t('Endereço de email inválido')
                    },
                    validate: {
                      notWhitespaceOnly: (value) =>
                        value.trim().length > 0 ||
                        (t('Campo obrigatório') as string),
                      notWhitespace: (value) =>
                        value.indexOf(' ') === -1 ||
                        (t(
                          'O e-mail não pode conter espaços nem caracteres especiais'
                        ) as string),
                      notSpecialCharacters: (value) => {
                        const hasSpecialCharacters = /^[a-zA-Z0-9@._]+$/.test(
                          value
                        );
                        return (
                          hasSpecialCharacters ||
                          (t(
                            'O e-mail não pode conter espaços nem caracteres especiais'
                          ) as string)
                        );
                      }
                    }
                  }
                }}
                errors={errors?.member?.email}
                placeholder={t('intelbras@email.com')}
              />
              <div className="mt-2">
                <Label className="text-sm-xs">
                  {t('Selecione o nível de permissão:')}
                </Label>
                <Controller
                  control={control}
                  name="member.role"
                  render={({ field: { value } }) => (
                    <div className="d-flex flex-column mt-1">
                      <RadioButton
                        checked={value === RoleMember.MANAGER}
                        value={RoleMember.MANAGER}
                        id="btn-manager"
                        onChange={handleRadioChange}
                      />
                      <Label element="radiobutton" className="text-sm-sm">
                        {t('Gerente')}
                      </Label>

                      <Label
                        element="radiobutton"
                        className={`text-sm-xs ${styles['color-text-subtitle']}`}>
                        {t(
                          'Tem acesso a gerencia completa do local, adição e remoção de membros'
                        )}
                      </Label>

                      <RadioButton
                        checked={value === RoleMember.GUEST}
                        value={RoleMember.GUEST}
                        onChange={handleRadioChange}
                        id="btn-convidado"
                      />
                      <Label element="radiobutton" className="text-sm-sm">
                        {t('Convidado')}
                      </Label>

                      <Label
                        element="radiobutton"
                        className={`text-sm-xs ${styles['color-text-subtitle']}`}>
                        {t('Tem acesso a gerencia completa dos ambientes')}
                      </Label>
                    </div>
                  )}
                />
              </div>
            </form>
          </>
        ) : (
          renderAddedSuccessMember()
        )}
      </Modal>
    );
  };

  return <>{renderCreateOrEditModalMembers()}</>;
};

export { ModalCreateMembers };

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MdSettings } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'src/components/Divider';
import { Alert } from 'ui-components/Alert';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import styles from './CardOfCaptivePortalType.module.css';

interface ICardOfCaptivePortalType {
  id?: string;
  nameType?: string;
  descriptionType?: string;
  statusMethod?: boolean;
  redirectTo?: string;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
  showWarningFirmware?: boolean;
}

const CardOfCaptivePortalType = ({
  nameType,
  id,
  statusMethod,
  descriptionType,
  redirectTo,
  isFetchedCaptivePortal,
  isLoadingFormCaptive,
  showWarningFirmware
}: ICardOfCaptivePortalType) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.setUpMethod.cardOfCaptivePortalType'
  });

  const navigate = useNavigate();

  return (
    <Card
      borderColor={
        statusMethod
          ? 'var(--color-brand-primary-dark)'
          : 'var(--color-brand-secondary-light)'
      }
      className="d-flex flex-column align-start fit-width fit-height pa-5"
      key={id}>
      <div className="fit-width d-flex justify-between align-center mb-2">
        <span
          className={classNames([
            'title-sm-base',
            statusMethod && styles.title
          ])}>
          {nameType}
        </span>
        {statusMethod && (
          <span
            className={classNames(styles.activeLabel, 'd-flex align-center')}>
            <div className={classNames(styles.ball, 'mr-2')} /> {t('Ativo')}
          </span>
        )}
      </div>
      <Divider />
      <div className="d-flex flex-column align-start justify-between mt-2 mb-4">
        <span className={showWarningFirmware ? 'mb-4' : ''}>
          {descriptionType}
        </span>
        {showWarningFirmware && (
          <Alert type="warning" width="420px">
            {t('Há equipamento(s) neste ambiente com firmware desatualizado')}
          </Alert>
        )}
      </div>
      {isLoadingFormCaptive || !isFetchedCaptivePortal ? (
        <Skeleton height={50} width={100} />
      ) : (
        <Button
          ghost
          disabled={isLoadingFormCaptive}
          isLoading={!isFetchedCaptivePortal}
          onClick={() => navigate(redirectTo || '/captive-portal')}>
          <span
            className={classNames([
              'd-flex align-center',
              statusMethod && styles.activeLabel,
              statusMethod && styles.buttonHoverColor
            ])}>
            <MdSettings size={24} />
            {statusMethod ? (
              <span className="ml-2">{t('Gerenciar')}</span>
            ) : (
              <span className="ml-2">{t('Definir')}</span>
            )}
          </span>
        </Button>
      )}
    </Card>
  );
};

CardOfCaptivePortalType.defaultProps = {
  nameType: '',
  descriptionType: '',
  statusMethod: false,
  redirectTo: '',
  id: '',
  showWarningFirmware: false
};

export { CardOfCaptivePortalType };

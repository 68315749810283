import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.css';
import Loading from 'ui-components/Loading';

const Button = ({
  children,
  onClick = () => {},
  secondary = false,
  outline = false,
  ghost = false,
  rounded = false,
  iconRight = <></>,
  hasIconRight = false,
  disabled = false,
  className = '',
  color = '',
  id = '',
  isLoading = false,
  ...props
}) => {
  return (
    <button
      className={classNames([
        styles.button,
        secondary ? styles.secondary : styles.primary,
        outline ? styles.outline : styles.primary,
        ghost ? styles.ghost : styles.primary,
        rounded && styles.rounded,
        hasIconRight && styles.reverse,
        isLoading ? styles.isLoading : null,
        className
      ])}
      {...props}
      onClick={onClick}
      disabled={disabled}
      style={{ background: color, borderColor: color }}
      id={id}>
      {hasIconRight && (
        <span
          className={classNames([
            styles.icon,
            hasIconRight && styles.icon_right
          ])}>
          {iconRight}
        </span>
      )}
      <span>
        {isLoading ? (
          <Loading
            show
            value={30}
            width={19}
            height={19}
            radius={7}
            indeterminate
            colors={{
              inside: '#FFF',
              outside: '#000'
            }}
          />
        ) : (
          children
        )}
      </span>
    </button>
  );
};

const ButtonTextOutside = ({ children, value, className = '', id = '' }) => {
  return (
    <span className={classNames([styles.text_outside, className])}>
      {children}
      <span id={id}>{value}</span>
    </span>
  );
};

const ButtonGroup = ({ children }) => {
  return <span className={styles.button_group}>{children}</span>;
};

const ButtonIcon = ({
  children,
  disabled = false,
  className = '',
  id = '',
  isLoading = false,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled}
      id={id}
      className={classNames([
        styles.button,
        styles.ghost,
        styles.ghostIcon,
        isLoading ? styles.isLoadingBtnIcon : null,
        className
      ])}>
      <span className={classNames([styles.icon, styles.icon_only])}>
        {isLoading ? (
          <Loading
            show
            value={30}
            width={19}
            height={19}
            radius={7}
            colors={{
              inside: '#FFF',
              outside: '#000'
            }}
            indeterminate
          />
        ) : (
          children
        )}
      </span>
    </button>
  );
};

export { Button, ButtonTextOutside, ButtonGroup, ButtonIcon };

import classNames from 'classnames';
import React from 'react';
import styles from './DueToneIcons.module.css';

interface IDuoToneIconsProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: JSX.Element;
  colorIcon: string;
  backgroundColor?: string;
  value?: number | string | React.ReactNode;
  title: string;
  className?: string;
  onClick?: () => void;
}
const DuoToneIcons = ({
  backgroundColor,
  colorIcon,
  icon,
  value,
  title,
  className,
  onClick,
  ...props
}: IDuoToneIconsProps) => {
  return (
    <div
      onClick={() => (onClick ? onClick() : null)}
      className={classNames(['d-flex align-center justify-center', className])}
      {...props}
      aria-hidden="true">
      <div
        className={styles.containerIcon}
        style={{ backgroundColor: backgroundColor || '' }}>
        <div
          style={{ color: colorIcon }}
          className="d-flex align-center justify-center">
          {icon}
        </div>
      </div>

      <div className="d-flex flex-column">
        <span
          className={classNames(['title-sm-sm', styles.value])}
          id={`duotone-${title}@${value}`}>
          {value}
        </span>
        <span className="title-xl-xs">{title}</span>
      </div>
    </div>
  );
};

DuoToneIcons.defaultProps = {
  className: '',
  backgroundColor: '',
  onClick: null,
  value: ''
};

export default DuoToneIcons;

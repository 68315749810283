import React, { useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdInfo } from 'react-icons/md';
import Label from 'ui-components/Label';
import Modal from 'ui-components/Modal';
import Toggle from 'ui-components/Toggle';
import { IAdvancedWirelessForm } from '../types';
import stylesAdvancedWireless from '../AdvancedWireless.module.css';

interface FastRoamingSectionProps {
  controlForm: Control<IAdvancedWirelessForm, unknown>;
}

const FastRoamingSection = (props: FastRoamingSectionProps) => {
  const { controlForm: control } = props;
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless.fast-roaming'
  });
  const [showFastRoamingModal, setShowFastRoamingModal] = useState(false);

  const fastRoamingInfoModal = () => {
    return (
      <Modal
        title={t('Fast Roaming (802.11r)')}
        show={showFastRoamingModal}
        width="720px"
        closable
        onClose={() => setShowFastRoamingModal(false)}>
        <p
          className={`text-sm-base pb-8 px-1 ${stylesAdvancedWireless.modalText}`}>
          {t(
            'Ao habilitar o suporte ao protocolo 802.11r, alguns dispositivos legados (antigos) podem apresentar problemas de conexão por não conseguirem interpretar corretamente as suítes AKM (Authetication and Key Management) Responsáveis pela transição segura entra APs.'
          )}
        </p>
      </Modal>
    );
  };

  return (
    <Row className="fit-width">
      <Col xs={12}>
        <Label className="d-flex text-bold text-xl-lg mb-2">
          {t('Roaming')}
        </Label>
      </Col>
      <Col xs={12} className={`d-flex ${stylesAdvancedWireless.columnsGap}`}>
        <Col xs={6}>
          <div>
            <p className="text-sm-base">
              {t(
                'Esta função está disponível para toda linha de Access Points. O AP irá sugerir a transição para os dispositivos quando for conveniente.'
              )}
            </p>
          </div>
        </Col>
        <Col xs={5}>
          <div className="d-flex">
            <Controller
              control={control}
              name="fast_roaming"
              render={({ field: { onChange, value } }) => (
                <Toggle
                  color="var(--color-status-ok-base)"
                  checked={value}
                  id="fast-roaming"
                  onChange={onChange}
                />
              )}
            />
            <span className="d-flex justify-center align-center">
              {t('Fast Roaming (802.11r)')}{' '}
              <span
                className={`${stylesAdvancedWireless.iconContainer} d-flex justify-center align-center`}>
                <MdInfo
                  size={20}
                  className={`${stylesAdvancedWireless.icon} ml-1`}
                  onClick={() => setShowFastRoamingModal(true)}
                />
              </span>
            </span>
          </div>
        </Col>
      </Col>
      {fastRoamingInfoModal()}
    </Row>
  );
};

export { FastRoamingSection };

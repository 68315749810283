import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
  useLocation
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs, Crumb } from 'ui-components/Breadcrumbs';
import classNames from 'classnames';
import { MdKeyboardBackspace } from 'react-icons/md';
import { MobileAuthRedirect } from './pages/MobileAuthRedirect';
import { FirstAccess } from './pages/FirstAccess';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Wireless } from './pages/Wireless';
import { RequireAuth } from './pages/RequireAuth';
import { RequireFirstAccess } from './pages/RequireFirstAccess';
import { AddDevices } from './pages/Devices/AddDevices';
import { BlockFirstAccessToRegisteredUsers } from './pages/BlockFirstAccessToRegisteredUsers';
import { Devices } from './pages/Devices';
import { WirelessSettings } from './pages/Wireless/WirelessSettings';
import Sites from './pages/Sites';
import DeviceDetails from './pages/Devices/DeviceDetails/index';
import { AdvancedWireless } from './pages/AdvancedWireless';
import { GenericError } from './pages/GenericError';
import Customers from './pages/Customers';
import styles from './routes.module.css';
import { Places } from './pages/Places';
import { ConnectionLog } from './pages/ConnectionLog';
import { FirstDevice } from './pages/FirstDevice';
import { AddFirstDevice } from './pages/FirstDevice/AddFirstDevice';
import { WomCFTVDetails } from './pages/Devices/WomCFTVDetails';
import { Informations } from './pages/Informations';
import AccessLists from './pages/AccessLists';
import AddAccessLists from './pages/AccessLists/AddAccessLists';
import { LogoutRedirect } from './pages/LogoutRedirect';
import { CaptivePortal } from './pages/CaptivePortal';
import { SimplePassword } from './pages/CaptivePortal/SimplePassword';
import { IndividualRecord } from './pages/CaptivePortal/IndividualRecord';
import { PageView } from './utils/analytics';
import { WebAuthRedirect } from './pages/WebAuthRedirect';
import { Voucher } from './pages/CaptivePortal/Voucher';
import { CPFRecord } from './pages/CaptivePortal/CPFRecord';

interface IRoute {
  path: string;
  name: string;
  element: JSX.Element;
  breadcrumb: string;
}

const RoutesPaths = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'routes' });

  const environment = process.env.REACT_APP_TYPE_ENV;

  const defaultRoutes = [
    { path: '/', name: 'Início', element: <Home /> },
    {
      path: '/informations',
      name: 'Informações',
      breadcrumb: t('Informações'),
      element: <Informations />
    },
    {
      path: '/wireless',
      name: 'Wireless',
      breadcrumb: 'Wireless',
      element: <Wireless />
    },
    {
      path: '/wireless/add',
      name: 'Adicionar Wireless',
      breadcrumb: t('Adicionar Wireless'),
      element: <WirelessSettings />
    },
    {
      path: '/advanced-options',
      name: 'Opções avançadas',
      breadcrumb: t('Opções avançadas'),
      element: <AdvancedWireless />
    },
    {
      path: '/wireless/edit/:id',
      name: 'Editar Wireless',
      breadcrumb: t('Editar Wireless'),
      element: <WirelessSettings />
    },
    {
      path: '/first-device',
      name: 'Equipamentos',
      breadcrumb: t('Equipamentos'),
      element: <FirstDevice />
    },
    {
      path: '/first-device/add',
      name: 'Equipamentos',
      breadcrumb: t('Equipamentos'),
      element: <AddFirstDevice />
    },
    {
      path: '/devices/add',
      name: 'Adicionar Equipamentos',
      breadcrumb: 'Adicionar Equipamentos',
      element: <AddDevices />
    },
    {
      path: '/devices',
      name: 'Equipamentos',
      breadcrumb: t('Equipamentos'),
      element: <Devices />
    },
    {
      path: '/devices/details/ap/:id',
      name: 'Visualizar Equipamento',
      breadcrumb: 'Visualizar Equipamento',
      element: <DeviceDetails />
    },
    {
      path: '/devices/details/womcftv/:id',
      name: 'Visualizar Equipamento',
      breadcrumb: 'Visualizar Equipamento',
      element: <WomCFTVDetails />
    },
    {
      path: '/sites',
      name: 'Sites',
      breadcrumb: t('Locais'),
      element: <Sites />
    },
    {
      path: '/sites/:id/places',
      name: 'Ambientes',
      breadcrumb: t('Ambientes'),
      element: <Places />
    },
    {
      path: '/sites/:id/connection-log',
      name: 'Registro de conexão',
      breadcrumb: t('Registro de conexão'),
      element: <ConnectionLog />
    },
    {
      path: '/customers',
      name: 'Clientes',
      breadcrumb: t('Clientes'),
      element: <Customers />
    },
    {
      path: '/captive-portal',
      name: 'Captive Portal - INTELBRAS',
      breadcrumb: t('Captive Portal'),
      element: <CaptivePortal />
    },
    {
      path: '/captive-portal/:cp_id/simple-password',
      name: 'Senha simples',
      breadcrumb: t('Senha simples'),
      element: <SimplePassword />
    },
    {
      path: '/error/:type',
      name: 'Error',
      breadcrumb: 'Erro',
      element: <GenericError />
    },
    {
      path: '*',
      name: 'Erro 404',
      breadcrumb: 'Erro 404',
      element: <Navigate to="/error/not_found" />
    }
  ] as IRoute[];

  const routes =
    environment === 'development'
      ? ([
          {
            path: '/sites/:id/access-lists',
            name: 'Listas de acesso',
            breadcrumb: t('Listas de acesso (ACL)'),
            element: <AccessLists />
          },
          {
            path: '/sites/:id/access-lists/add',
            name: 'Adicionar Lista de acesso',
            breadcrumb: t('Adicionar Lista de acesso'),
            element: <AddAccessLists />
          },
          // {
          //   path: '/captive-portal/:cp_id/individual-record',
          //   name: 'Cadastro Individual',
          //   breadcrumb: t('Cadastro individual'),
          //   element: <IndividualRecord />
          // },
          // {
          //   path: '/captive-portal/:cp_id/voucher',
          //   name: 'Voucher',
          //   breadcrumb: t('Voucher'),
          //   element: <Voucher />
          // },
          // {
          //   path: '/captive-portal/:cp_id/cpf-record',
          //   name: 'Cadastro Pessoa Física',
          //   breadcrumb: t('Cadastro Pessoa Física'),
          //   element: <CPFRecord />
          // },
          ...defaultRoutes
        ] as IRoute[])
      : [...defaultRoutes];

  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: [
      '/sites',
      '/login',
      '/places',
      '/informations',
      'sites/:id/connection-log',
      'sites/:id/access-lists/add'
    ]
  }).filter(
    (b) => b.match.pattern.path !== '/*' // remove the 404 route from breadcrumbs
  );

  const renderBreadCrumb = useMemo(() => {
    return breadcrumbs.map(({ key, breadcrumb }, index) => {
      if (index !== breadcrumbs.length - 1) {
        return (
          <Crumb key={key}>
            <Link to={key}>{breadcrumb}</Link>
          </Crumb>
        );
      }
      return <Crumb key={key}>{breadcrumb}</Crumb>;
    });
  }, [breadcrumbs]);

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (breadcrumbs.length > 1) {
      navigate(breadcrumbs[breadcrumbs.length - 2].match.pathname);
    }
  }, [breadcrumbs, navigate]);

  const location = useLocation();

  // Pega o path da rota sem os ids dinâmicos (:id)
  const pathWithouDynamicIds = useMemo(() => {
    const containsNumbers = /\d/;
    const splitPath = location.pathname.split('/');
    let originalPath = '';
    for (const path of splitPath) {
      // eslint-disable-next-line no-continue
      if (path.length === 0) continue;
      if (containsNumbers.test(path)) {
        originalPath += '/:id';
        // eslint-disable-next-line no-continue
        continue;
      }

      originalPath += `/${path}`;
    }
    return originalPath;
  }, [location]);

  // Usa o path da rota sem os ids dinâmicos (:id) para pegar o nome da rota
  const pageTitle = useMemo(() => {
    if (pathWithouDynamicIds === '/error/not_found')
      return 'Erro 404 - InMaster';
    const nameOfRoute = routes.find(
      (route) => route.path === pathWithouDynamicIds
    )?.name;
    if (nameOfRoute) {
      return `${nameOfRoute} - InMaster`;
    }
    return 'InMaster';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    PageView(pageTitle);
    document.title = pageTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/auth" element={<MobileAuthRedirect />} />
      <Route path="/auth-web" element={<WebAuthRedirect />} />
      <Route path="/logout/:typeOfLogout" element={<LogoutRedirect />} />
      <Route
        path="/first-access"
        element={
          <RequireAuth>
            <BlockFirstAccessToRegisteredUsers>
              <FirstAccess />
            </BlockFirstAccessToRegisteredUsers>
          </RequireAuth>
        }
      />
      {routes.map((route) => (
        <Route
          path={route.path}
          element={
            <RequireAuth>
              <RequireFirstAccess>
                <>
                  {breadcrumbs.length > 1 && (
                    <div className="d-flex align-center mb-6">
                      <button
                        type="button"
                        aria-label='Voltar para a página de "Locais"'
                        id="btn-back-to-sites"
                        className={classNames([
                          styles.backToSitesButton,
                          'mr-3'
                        ])}
                        onClick={() => handleBack()}>
                        <MdKeyboardBackspace size={16} />
                      </button>
                      <Breadcrumbs>{renderBreadCrumb}</Breadcrumbs>
                    </div>
                  )}
                  {route.element}
                </>
              </RequireFirstAccess>
            </RequireAuth>
          }
          key={route.name}
        />
      ))}
    </Routes>
  );
};

export { RoutesPaths };

import React, { useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdInfo } from 'react-icons/md';
import { ICreateOrUpdateWireless } from 'src/services/api/urls/wireless/types';
import Label from 'ui-components/Label';
import Modal from 'ui-components/Modal';
import Toggle from 'ui-components/Toggle';
import stylesAdvancedOptions from '../AdvancedOption.module.css';
import styles from './IsolationsOptions.module.css';

interface IIsolationsOptions {
  isDisabledInputBecauseIsDisabledWireless: boolean;
  control: Control<ICreateOrUpdateWireless, unknown>;
}
const IsolationsOptions = (props: IIsolationsOptions) => {
  const [showIsolateCustomerModal, setShowIsolateCustomerModal] =
    useState(false);
  const [showIsolateNetworkModal, setShowIsolateNetworkModal] = useState(false);
  const { isDisabledInputBecauseIsDisabledWireless, control } = props;
  const { t } = useTranslation('translations', { keyPrefix: 'site.wireless' });

  const isolateCustomersInfoModal = () => {
    return (
      <Modal
        title={t('Isolar Clientes')}
        show={showIsolateCustomerModal}
        width="550px"
        closable
        onClose={() => setShowIsolateCustomerModal(false)}>
        <p className={`mb-6 px-1 ${styles.modalText}`}>
          {t(
            'Impede que clientes conectados dentro da mesma Wireless possam se comunicar entre eles.'
          )}
        </p>
      </Modal>
    );
  };

  const isolateNetworkInfoModal = () => {
    return (
      <Modal
        title={t('Somente internet')}
        show={showIsolateNetworkModal}
        width="550px"
        closable
        onClose={() => setShowIsolateNetworkModal(false)}>
        <p className={`mb-6 px-1 ${styles.modalText}`}>
          {t(
            'Os clientes conectados dentro desta Wireless somente irão acessar a internet. Não será possível acessar domínios dentro da rede local.'
          )}
        </p>
      </Modal>
    );
  };

  return (
    <Row>
      {isolateCustomersInfoModal()}
      {isolateNetworkInfoModal()}
      <Col xs={12}>
        <Label className="d-flex text-bold text-xl-lg mb-2">
          {t('Opções de isolamento')}
        </Label>
      </Col>
      <Col xs={12} className={`d-flex ${stylesAdvancedOptions.columnsGap}`}>
        <Col xs={6}>
          <div>
            <p className="text-sm-base">
              {t(
                'Realize o isoalmento da Wireless aumentando sua privacidade e segurança.'
              )}
            </p>
          </div>
        </Col>
        <Col xs={5}>
          <div className="d-flex mb-4">
            <Controller
              control={control}
              name="wireless.client_isolate"
              render={({ field: { onChange, value } }) => (
                <Toggle
                  color="var(--color-status-ok-base)"
                  checked={value}
                  id="isolate-customers"
                  onChange={onChange}
                  disabled={isDisabledInputBecauseIsDisabledWireless}
                />
              )}
            />
            <span>{t('Isolar clientes')}</span>
            <span
              className={`${styles.iconContainer} d-flex justify-center align-center`}>
              <MdInfo
                size={20}
                className={`${styles.icon} ml-1`}
                onClick={() => {
                  setShowIsolateCustomerModal(true);
                }}
              />
            </span>
          </div>
          {/* <div className="d-flex">
            <Controller
              control={control}
              name="wireless.network_isolate"
              render={({ field: { onChange, value } }) => (
                <Toggle
                  color="var(--color-status-ok-base)"
                  checked={value}
                  id="isolate-network"
                  onChange={onChange}
                  disabled={isDisabledInputBecauseIsDisabledWireless}
                />
              )}
            />
            <span>{t('Somente internet')}</span>
            <span
              className={`${styles.iconContainer} d-flex justify-center align-center`}>
              <MdInfo
                size={20}
                className={`${styles.icon} ml-1`}
                onClick={() => {
                  setShowIsolateNetworkModal(true);
                }}
              />
            </span>
          </div> */}
        </Col>
      </Col>
    </Row>
  );
};

export { IsolationsOptions };

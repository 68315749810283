import { isNull } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { eventGA } from 'src/utils/analytics';
import { Alert } from 'ui-components/Alert';
import Modal from 'ui-components/Modal';
import { IDeviceRow } from '../types';

interface IProps {
  selectedDevices: IDeviceRow[];
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
  isRebooting?: boolean;
  setIsRebooting?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

interface IErrorResponse {
  response: {
    status: number;
  };
}

const RestartDeviceModal = ({
  selectedDevices,
  show,
  onCancel,
  onClose,
  isRebooting,
  setIsRebooting
}: IProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'devices' });
  const { addToast } = useToast();

  const rebootDeviceMutation = useMutation(
    () => {
      return api.devices.reboot({ id: selectedDevices[0].id });
    },
    {
      onSuccess: () => {
        addToast(
          'success',
          t(
            'Equipamento está sendo reiniciado, a ação acontecerá em segundo plano.'
          )
        );
        eventGA('Equipamentos', 'Click', 'Reiniciar equipamento');
        onClose();
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        }
        onClose();
      }
    }
  );

  const rebootDevice = () => {
    rebootDeviceMutation.mutate();
  };

  const modalRestartActions = [
    {
      label: t('CANCELAR'),
      action: () => onCancel(),
      disabled: isRebooting
    },
    {
      label: t('REINICIAR'),
      action: () => rebootDevice(),
      isLoading: isRebooting
    }
  ];

  useEffect(() => {
    if (setIsRebooting && !isNull(setIsRebooting)) {
      setIsRebooting(rebootDeviceMutation.isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rebootDeviceMutation.isLoading]);

  return (
    <Modal actions={modalRestartActions} show={show} width="550px">
      <div className="d-flex flex-column">
        <span className="text-sm-lg mb-2">
          {t('Reiniciar este equipamento?')}
        </span>
        <Alert type="warning">
          {t(
            'Todos os clientes ligados a este equipamento serão desconectados'
          )}
        </Alert>
      </div>
    </Modal>
  );
};

RestartDeviceModal.defaultProps = {
  setIsRebooting: null,
  isRebooting: false
};

export default RestartDeviceModal;

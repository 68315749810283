import React from 'react';
import { t as translate } from 'i18next';
import classNames from 'classnames';
import home from 'src/assets/help-images/tela-inicial.png';
import homeWithContaUnica from 'src/assets/help-images/tela-inicial-com-conta-unica.png';
import contaUnicaWindow from 'src/assets/help-images/tela-conta-unica.png';
import site from 'src/assets/help-images/tela-locais.png';
import siteWithMenu from 'src/assets/help-images/tela-local-menu-destacado.png';
import siteWithMenuOpen from 'src/assets/help-images/tela-local-menu-aberto-com-excluir-destacado.png';
import placeWithMenu from 'src/assets/help-images/tela-ambiente-com-menu-destacado.png';
import placeWithMenuOpen from 'src/assets/help-images/tela-ambiente-com-menu-aberto-excluir-destacado.png';
import place from 'src/assets/help-images/tela-ambientes.png';
import modalAddMember from 'src/assets/help-images/modal-add-membro.png';
import siteWithMenuOpenOnAddMember from 'src/assets/help-images/tela-locais-menu-aberto-add-membro-destacado.png';
import modalSuccessAddMemberWithoutRegister from 'src/assets/help-images/modal-sucesso-add-membro-sem-cadastro.png';
import modalSuccessAddMemberWithRegister from 'src/assets/help-images/modal-sucesso-add-membro-com-cadastro.png';
import sharedSiteWithNewSharedSite from 'src/assets/help-images/tela-locais-compatilhados-com-tag-novo-local.png';
import siteWithMenuOpenOnViewMember from 'src/assets/help-images/tela-local-menu-aberto-visualizar-membro-destacado.png';
import drawerMembers from 'src/assets/help-images/drawer-membros.png';
import modalEditMember from 'src/assets/help-images/modal-edicao-membro.png';
import modalConfirmEditMember from 'src/assets/help-images/modal-confirmacao-edicao-membro.png';
import drawerMembersWithEditOption from 'src/assets/help-images/drawer-membros-opcao-edicao-destacado.png';
import modalConfirmDeleteMember from 'src/assets/help-images/modal-confirmacao-exclusao-membro.png';
import drawerMembersWithDeleteOption from 'src/assets/help-images/drawer-membros-opcao-deletar-destacada.png';
import addNewDevice from 'src/assets/help-images/form-add-equipamento.png';
import viewAllDevices from 'src/assets/help-images/visualizacao-equipamentos.png';
import viewDetailsDeviceWithEditName from 'src/assets/help-images/tela-detalhes-equipamento-com-editar-nome-destacado.png';
import viewAllDevicesWithConfigurationOption from 'src/assets/help-images/tela-visualizacao-equipamentos-com-configuracao-destacada.png';
import viewDetailsDeviceWithReboot from 'src/assets/help-images/tela-detalhes-equipamento-com-opcao-reiniciar-destacada.png';
import viewDetailsDeviceWithRadioConfiguration from 'src/assets/help-images/tela-detalhes-equipamento-opcao-edicoes-radios.png';
import viewDetailsDeviceWithRadioConfigurationEnable from 'src/assets/help-images/tela-detalhes-equipamento-edicoes-radios-habilitado.png';
import viewDetailsDeviceWithUpdateFirmware from 'src/assets/help-images/tela-detalhes-equipamento-atualizar-firmware.png';
import modalUpdateFirmware from 'src/assets/help-images/modal-atualizar-firmware.png';
import modalUpdateFirmwareWithLatestVersion from 'src/assets/help-images/modal-atualizar-firmware-versao-atualizada.png';
import modalUpdateFirmwareWithChooseVersion from 'src/assets/help-images/modal-atualizar-firmware-versao-desatualizada.png';
import viewDetailsDeviceWithAssociatedWireless from 'src/assets/help-images/tela-detalhes-equipamento-wireless-associado.png';
import modalToChooseWireless from 'src/assets/help-images/modal-escolha-wireless-associado.png';
import viewAllDevicesWithOneChecked from 'src/assets/help-images/tela-visualizacao-equipamentos-um-equipamento-selecionado.png';
import viewDetailsDeviceWithDelete from 'src/assets/help-images/tela-detalhes-equipamento-com-excluir-destacado.png';
import viewAllDevicesWithTwoChecked from 'src/assets/help-images/tela-visualizacao-equipamentos-dois-equipamentos-selecionados.png';
import viewWirelessListEmpty from 'src/assets/help-images/tela-visualizacao-wireless-sem-wireless.png';
import createNewWireless from 'src/assets/help-images/tela-criacao-wireless.png';
import selectorFrequency from 'src/assets/help-images/seletor-frequencia.png';
import viewNewWirelessWithDevices from 'src/assets/help-images/tela-criacao-wireless-equipamentos-associados.png';
import viewWirelessListWithEditButton from 'src/assets/help-images/tela-listagem-wireless-editar-destacado.png';
import viewWirelessWithDevices from 'src/assets/help-images/tela-edicao-wireless-equipamentos-associados.png';
import viewWirelessWithSensitivity from 'src/assets/help-images/tela-edicao-wireless-sensibilidade-destacada.png';
import viewWirelessWithHide from 'src/assets/help-images/tela-edicao-wireless-ocultar-destacada.png';
import viewWirelessWithIsolateOption from 'src/assets/help-images/tela-edicao-wireless-isolamento-destacada.png';
import viewWirelessWithVLAN from 'src/assets/help-images/tela-edicao-wireless-vlan-destacada.png';
import viewWirelessWithQoS from 'src/assets/help-images/tela-edicao-wireless-limite-banda-destacado.png';
import fieldQoSEnable from 'src/assets/help-images/campo-limite-banda-ativo.png';
import viewWirelessListWithOneChecked from 'src/assets/help-images/tela-visualizacao-wireless-uma-selecionada.png';
import modalDeleteOneWireless from 'src/assets/help-images/modal-exclusao-uma-wireless.png';
import viewWirelessListWithTwoChecked from 'src/assets/help-images/tela-visualizacao-wireless-duas-selecionadas.png';
import modalDeleteTwoWireless from 'src/assets/help-images/modal-exclusao-duas-wireless.png';
import viewAdvancedWirelessWithRoaming from 'src/assets/help-images/tela-wireless-avancado-roaming.png';
import viewAdvancedWirelessWithButtonSave from 'src/assets/help-images/tela-wireless-avancado-botao-salvar.png';
import viewAdvancedWirelessWithBandSteering from 'src/assets/help-images/tela-wireless-avancado-band-steering.png';
import viewAdvancedWirelessWithBeamforming from 'src/assets/help-images/tela-wireless-avancado-beamforming.png';
import viewAdvancedWirelessWithMUMIMO from 'src/assets/help-images/tela-wireless-avancado-mu-mimo.png';
import viewConnectedClients from 'src/assets/help-images/tela-clientes-conectados.png';
import viewConnectedClientsEmpty from 'src/assets/help-images/tela-clientes-conectados-vazio.png';
import siteWithMenuOpenOnConnectionsLog from 'src/assets/help-images/tela-local-menu-aberto-registro-conexao.png';
import viewConnectionsLogEmpty from 'src/assets/help-images/tela-registro-conexao-vazio.png';
import viewConnectionsLog from 'src/assets/help-images/tela-registro-conexao.png';
import viewConnectionsLogWithDownloadOption from 'src/assets/help-images/tela-registro-conexao-botao-download-destacado.png';
import viewConnectionsLogWithLoadingMensage from 'src/assets/help-images/loadin-download-registro.png';
import { IHelpText } from './types';

const t = (text: string) => {
  return translate(`helperDrawer.${text}`);
};

export const helpTexts: IHelpText[] = [
  {
    section: t('Acessando o InMaster'),
    items: [
      {
        title: t('Guia para adição de equipamentos no InMaster'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para adicionar corretamente o seu equipamento no InMaster, certifique-se sobre os seguintes pontos:'
              )}
            </p>
            <ol className={classNames(['mt-4 px-4'])}>
              <li>
                {t(
                  'O firmware do equipamento deve estar atualizado com a versão compatível com o InMaster*;'
                )}
              </li>
              <li>
                {t(
                  'Aplique o reset padrão de fábrica no equipamento antes de iniciar a adição no InMaster. Caso o seu equipamento já possua alguma configuração aplicada, o reset de fábrica é necessário para que a adição ao InMaster seja realizada com sucesso;'
                )}
              </li>
              <li>
                {t(
                  'O MAC do equipamento tenha sido corretamente digitado no InMaster;'
                )}
              </li>
              <li>
                {t(
                  'A senha do equipamento esteja corretamente digitada no InMaster. Lembrando que, com a aplicação do reset padrão de fábrica no equipamento, a sua senha será a senha padrão "admin";'
                )}
              </li>
              <li>
                {t(
                  'O equipamento esteja devidamente energizado no momento da adição;'
                )}
              </li>
              <li>
                {t(
                  'O equipamento só poderá estar adicionado em um único ambiente de uma conta específica, portanto é importante verificar se o mesmo já não tenha sido adicionado em algum outro local/ambiente do InMaster'
                )}
                .
              </li>
            </ol>
            <p className={classNames(['mt-4'])}>
              *{' '}
              {t(
                'Para ser compatível com o InMaster, a versão de firmware do AP deve ser igual ou superior à 2.10.0.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              *{' '}
              {t(
                'Para o AP da linha AX a versão de firmware deve ser igual ou superior à 4.1.11'
              )}
              .
            </p>
          </div>
        )
      },
      {
        title: t('Como fazer uso da solução?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O InMaster é uma solução cloud, multisite voltada para gerenciar os equipamentos Access Point (APs) da Intelbras e monitorar os diversos locais e ambientes configurados.'
              )}
            </p>
            <p className={classNames('mt-4')}>
              {t('Para fazer uso da solução você deve acessar o endereço')}{' '}
              <a
                className={classNames(['text-bold'])}
                style={{ color: 'var(--color-brand-primary-dark)' }}
                href="https://inmaster.intelbras.com.br"
                target="_blank"
                rel="noreferrer">
                https://inmaster.intelbras.com.br
              </a>{' '}
              {t(
                'e seguir os passos de cadastro e login recomendados neste manual.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para utilizar o aplicativo do InMaster basta acessar a loja de seu interesse e baixar o app. O aplicativo do InMaster está disponível na Google Play e App Store da Apple.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>
                {t('Importante:')}
              </span>{' '}
              {t(
                'o InMaster é compatível com todos os APs da Intelbras*; exceto: AP300, HS300 e linha BSPRO.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 1:')}</span>{' '}
              {t(
                'Para ser compatível com o InMaster, a versão de firmware do AP deve ser igual ou superior à 2.10.0.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 2:')}</span>{' '}
              {t(
                'Para o AP da linha AX a versão de firmware deve ser igual ou superior à 4.1.11'
              )}
            </p>
          </div>
        )
      },
      {
        title: t(
          'As configurações feitas nos meus equipamentos serão perdidas quando eu os adicionar no InMaster?'
        ),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Todas as configurações inerentes ao equipamento serão automaticamente carregadas para o InMaster assim que o equipamento em questão tenha o seu processo de adoção concluído.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Importante, as redes wireless configuradas nesses equipamentos não serão consideradas, pois a estrutura do InMaster – hierarquizado por meio de locais e seus ambientes – possuem detalhes e restrições que necessitam que as redes wireless sejam novamente estruturadas na ferramenta.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como fazer login no InMaster (Conta Única Intelbras)?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O login no InMaster é feito via Conta Única Intelbras. Para tanto, basta acessar o botão Conta Intelbras e, caso ainda não tenha cadastro, criar uma nova conta. Além disso, deve-se lembrar de permitir a exibição de pop-ups por parte do Conta Única, visando o correto funcionamento do login e do logout.'
              )}
            </p>
            <img
              src={home}
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela inicial do InMaster')}
              width="100%"
            />
            <img
              src={homeWithContaUnica}
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela inicial do InMaster com a pop-up de login do conta única aberta'
              )}
              width="100%"
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'para acesso ao InMaster você deve aceitar os termos de uso e a política de privacidade. Ambos conteúdo podem ser acessados pelos links disponíveis na tela.'
              )}
            </p>
            <div className={classNames(['d-flex justify-center fit-width'])}>
              <img
                src={contaUnicaWindow}
                width="50%"
                className={classNames(['mt-4'])}
                alt={t(
                  'Imagem mais detalhada do pop-up do conta única, no qual os links dos termos de uso e política de privacidade estão destacados'
                )}
              />
            </div>
          </div>
        )
      }
    ]
  },
  {
    section: t('Meus locais'),
    items: [
      {
        title: t('O que é um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Um local, ou um site, corresponde ao maior nível hierárquico pelo qual você poderá organizar a sua instalação.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                "Por exemplo, vamos supor que eu deseje instalar 50 APs na fábrica da Intelbras. Eu poderia nomear este meu local como 'Intelbras' e depois configurar os ambientes aos quais esses meus APs serão instalados."
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                "Agora, vamos supor que além da Intelbras eu também queira gerenciar os equipamentos instalados no shopping do município vizinho. Eu poderia então configurar um segundo local nomeado como 'Shopping Pedra Azul'."
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como criar um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Logo no seu primeiro acesso ao InMaster você será orientado para criar o seu primeiro local de instalação.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Uma vez criado o primeiro local na ferramenta, você poderá criar facilmente novos locais (no máximo 32) por meio do seguinte acesso.'
              )}
            </p>
            <img
              src={site}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão de criar local'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'não esqueça de determinar o time zone correto de cada local criado. Por se tratar de um sistema com acesso remoto e cujo acesso pode ser compartilhado, essa determinação poderá influenciar no entendimento de dados demonstrados que fazem uso de horário, por exemplo.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como excluir um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para excluir um local, clique no menu em forma de reticências que fica na parte direita do card de local. Em seguida serão exibidas algumas opções, basta clicar em "EXCLUIR LOCAL" e confirma no modal que será exibido.'
              )}
            </p>
            <img
              src={siteWithMenu}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão do menu em um dos locais'
              )}
            />
            <img
              src={siteWithMenuOpen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais com um dos menus dos locais aberto, no qual está destacado o botão de excluir o local'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'Caso haja somente um local cadastrado, não é possível excluí-lo, sendo necessário criar um novo para habilitar a opção de excluir.'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Meus ambientes'),
    items: [
      {
        title: t('O que é um ambiente?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O ambiente é o segundo nível hierárquico que você utilizará para organizar a sua instalação. Todo local cadastrado deverá conter, pelo menos, um ambiente vinculado a ele.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                "Por exemplo, vamos supor que eu criei um local denominado 'Intelbras' para instalar 50 APs na fábrica. Dentro deste local eu poderia criar ambientes diversos a fim de facilitar e organizar a instalação dos meus equipamentos, tais como: 'Recepção', 'Sala de RH', 'Área de produção' e etc."
              )}
            </p>
          </div>
        )
      },
      {
        title: 'Como criar um ambiente?',
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Sempre que criar um novo local será solicitado a você criar o primeiro ambiente para este local.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Uma vez criado o primeiro ambiente do local, você poderá criar novos ambientes (máximos 32 ambientes por local) para lhe ajudar na organização da sua instalação.'
              )}
            </p>
            <img
              src={place}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos ambientes de um local, no qual está destacado o botão de criar ambiente'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como excluir um ambiente?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para excluir um ambiente, clique no menu em forma de reticências que fica na parte direita do card de ambiente. Em seguida serão exibidas algumas opções, basta clicar em "EXCLUIR AMBIENTE" e confirma no modal que será exibido.'
              )}
            </p>
            <img
              src={placeWithMenu}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos ambientes de um local, no qual está destacado o botão de menu de um ambiente'
              )}
            />
            <img
              src={placeWithMenuOpen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos ambientes de um local, no qual está destacado a opção de excluir ambiente do menu'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'Caso haja somente um ambiente cadastrado no local, não é possível excluí-lo, sendo necessário criar um novo ambiente para habilitar a opção de excluir.'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Membros'),
    items: [
      {
        title: t('O que é um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O Membro nada mais é que um usuário do sistema que terá acesso ao local de outro usuário, podendo ser do tipo Gerente ou Convidado.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Membro Gerente tem acesso a gerencia completa do local, adição e remoção de membros.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Membro Convidado tem acesso a gerencia completa dos ambientes.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como adicionar um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para adicionar um membro a um local, deve-se estar na tela de meus locais e clicar no menu em forma de reticências, como mostrado em marrom na imagem abaixo. Após isso, clicar em "ADICIONAR MEMBRO".'
              )}
            </p>
            <img
              src={siteWithMenuOpenOnAddMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão de adicionar um membro no menu'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, deve aparecer um modal o qual deve pedir o e-mail do usuário e selecionar a opção de permissão para o membro.'
              )}
            </p>
            <img
              src={modalAddMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de adição de um membro')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Ao preencher o modal e finalizar o cadastro do membro, será exibido uma mensagem de alerta caso o e-mail informado não tenha conta no InMaster'
              )}
            </p>
            <img
              src={modalSuccessAddMemberWithoutRegister}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de êxito na adição de um membro que não possua cadastro no InMaster'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se o usuário já tiver conta no InMaster ao finalizar o cadastro do membro, deve aparecer uma mensagem informando que ele foi adicionado com sucesso.'
              )}
            </p>
            <img
              src={modalSuccessAddMemberWithRegister}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de êxito na adição de um membro que possua cadastro no InMaster'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Quando o membro logar no sistema vai se deparar com uma tag \'1 novo\' na aba de "COMPARTILHADOS COMIGO"'
              )}
            </p>
            <img
              src={sharedSiteWithNewSharedSite}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais compatilhados comigo, no qual possui uma notificação de que possui um novo local compartilhado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como visualizar os membros de um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para visualizar os membros de um local, deve-se estar na tela de locais e clicar no menu em forma de reticências, como mostrado em marrom na imagem abaixo. Após, clicar em "VISUALIZAR MEMBROS".'
              )}
            </p>
            <img
              src={siteWithMenuOpenOnViewMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão de visualizar os membros no menu'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, deve aparecer um drawer o qual deve conter uma listagem com os membros.'
              )}
            </p>
            <img
              src={drawerMembers}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de visualização dos membros do local')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                "A listagem dos membros deve exibir algumas informações como dados do membro, tipo de permissão, data de adição do membro. Quando o sistema não informar a data de adição do membro exibindo-a como 'Pendente' e mostrar o nome do usuário com '-', significa que o usuário foi adicionado ao local, mas não possui conta no InMaster (como ilustrado na imagem anterior)."
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como editar a permissão de um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para editar a permissão de um membro, deve-se estar na tela de listagem de membros e clicar no ícone de lápis.'
              )}
            </p>
            <img
              src={drawerMembersWithEditOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de visualização dos membros do local com a opção de editar um membro destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no ícone, deve aparecer um modal o qual deve permitir a troca de permissão do membro.'
              )}
            </p>
            <img
              src={modalEditMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de edição de um membro')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após realizar a alteração de permissão, o botão "CONTINUAR" deve ser habilitado.'
              )}
            </p>
            <img
              src={modalEditMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de edição de um membro com o nível de permissão editado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, será exibido uma mensagem de aviso sobre a atualização da permissão, então clique em "EDITAR" para finalizar.'
              )}
            </p>
            <img
              src={modalConfirmEditMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de confirmação de alteração do membro')}
            />
          </div>
        )
      },
      {
        title: t('Como excluir um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para excluir um membro, deve-se estar na tela de listagem de membros e clicar no ícone de lixeira.'
              )}
            </p>
            <img
              src={drawerMembersWithDeleteOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de visualização dos membros do local com a opção de excluir um membro destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no ícone, deve aparecer um modal para concluir a ação de excluir o membro.'
              )}
            </p>
            <img
              src={modalConfirmDeleteMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de confirmação de exclusão do membro')}
            />
          </div>
        )
      }
    ]
  },
  {
    section: t('Meus equipamentos'),
    items: [
      {
        title: t('Como adicionar um equipamento no InMaster?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para adicionar um equipamento você deve estar dentro de um ambiente previamente cadastrado no InMaster e preencher o MAC e senha do equipamento que você deseja vincular a este ambiente'
              )}
            </p>
            <img
              src={addNewDevice}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela de cadastro de um novo equipamento')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Tenha atenção para os seguintes pontos para realizar com sucesso a adição de um equipamento:'
              )}
            </p>
            <ul className={classNames(['mt-4 px-4'])}>
              <li>
                {t(
                  'Certifique-se que o AP esteja atualizado com a versão de firmware compatível com o InMaster e que ele seja um dos modelos suportados pela solução;'
                )}
              </li>
              <li>
                {t(
                  'Para que o processo de adição seja concluído é necessário que o equipamento esteja ligado - devidamente energizado - e disponível para comunicação;'
                )}
              </li>
              <li>
                {t(
                  'Um mesmo equipamento não pode ser adicionado em mais de um local ou em mais de um ambiente. Certifique-se de que o MAC do equipamento que você está querendo adicionar já não tenha sido adicionado pelo InMaster.'
                )}
              </li>
            </ul>
            <p className={classNames(['mt-4'])}>
              {t(
                'Após o equipamento ser adicionado no ambiente, ele aparecerá na tela de lista de equipamentos.'
              )}
            </p>
            <img
              src={viewAllDevices}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos adicionados com sucesso'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>
                {t('Importante:')}
              </span>{' '}
              {t(
                'o InMaster é compatível com todos os APs da Intelbras*; exceto: AP300, HS300 e linha BSPRO.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 1:')}</span>{' '}
              {t(
                'Para ser compatível com o InMaster, a versão de firmware do AP deve ser igual ou superior à 2.10.0.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 2:')}</span>{' '}
              {t(
                'Para o AP da linha AX a versão de firmware deve ser igual ou superior à 4.1.11'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como editar o nome de um equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Todo equipamento adicionado no InMaster pode ter o seu nome editado pelo usuário. Para tanto, acesse o local e ambiente desejado, selecione o equipamento e edite a descrição do seu nome.'
              )}
            </p>
            <img
              src={viewAllDevicesWithConfigurationOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com a opção de configuração do equipamento destacada'
              )}
            />
            <img
              src={viewDetailsDeviceWithEditName}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes de equipamento, no qual a opção de editar o nome do equipamento está destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como reiniciar um equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para reiniciar um equipamento, acesse o local e ambiente ao qual o equipamento que você deseja reiniciar esteja adicionado e selecione a opção para reiniciar o equipamento.'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithReboot}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com a opção de reiniciar o equipamento destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como editar as configurações de rádio do equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para proceder com qualquer ação que envolva configuração de um determinado equipamento, primeiramente acesse o local e o ambiente onde o equipamento em questão esteja adicionado e posteriormente acesse a aba de "CONFIGURAÇÕES POR RÁDIO" e em seguida "EDITAR CONFIGURAÇÕES"'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithRadioConfiguration}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes de equipamento, no qual a opção de configurações por rádio e editar configurações estão destacadas'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'As seguintes ações se encontram disponíveis para serem configuradas:'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithRadioConfigurationEnable}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes de equipamento, no qual mostra as opções de edição dos rádios habilitados'
              )}
            />

            <ul className={classNames(['mt-4 px-4'])}>
              <li>
                <span className={classNames(['text-bold'])}>{t('Canal')}:</span>{' '}
                {t(
                  'Frequência de rádio que o AP usa para transmitir e receber dados sem fio. A seleção do canal correto é importante para garantir que o sinal sem fio seja forte e estável, especialmente em ambientes onde muitos dispositivos sem fio estão em uso simultaneamente.'
                )}
              </li>
              <li>
                <span className={classNames(['text-bold'])}>
                  {t('Padrão IEEE 802.11')}:
                </span>{' '}
                {t(
                  'Emendas do padrão IEEE 802.11 que incluem especificações para as taxas de transferência de dados, a modulação do sinal de rádio, os canais de frequência, os métodos de autenticação e criptografia de segurança e muito mais.'
                )}
              </li>
              <li>
                <span className={classNames(['text-bold'])}>
                  {t('Largura de Banda')}:
                </span>{' '}
                {t(
                  'A largura de banda é uma medida da quantidade de dados que podem ser transmitidos em uma conexão de rede em um determinado período de tempo.'
                )}
              </li>
              <li>
                <span className={classNames(['text-bold'])}>
                  {t('Potência')}:
                </span>{' '}
                {t(
                  'Medida da força do sinal de rádio que ele emite para transmitir dados sem fio em uma rede. A potência é medida em decibéis (dBm) e indica a intensidade do sinal transmitido pelo AP.'
                )}
              </li>
            </ul>
          </div>
        )
      },
      {
        title: t('Como atualizar o firmware do equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente onde o equipamento em questão esteja adicionado e acesse a opção para atualização de firmware.'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithUpdateFirmware}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com a opção de atualizar firmware do equipamento destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Acessando a opção será demonstrada a versão atual do seu equipamento e também se há uma nova versão disponível para ser configurada.'
              )}
            </p>
            <img
              src={modalUpdateFirmware}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de atualização de firmware do equipamento desatualizado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Para atualizar, basta clicar sobre a opção disponível e acompanhar o status do processo de atualização do seu equipamento até sua efetivação.'
              )}
            </p>
            <img
              src={modalUpdateFirmwareWithLatestVersion}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de atualização de firmware do equipamento com firmware na última versão'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Também é possível escolher uma versão de firmware anterior, caso deseje regredir o firmware do equipamento. Para tanto, basta acessar a opção e verificar a lista de versões de firmwares compatíveis disponíveis para tanto.'
              )}
            </p>
            <img
              src={modalUpdateFirmwareWithChooseVersion}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de escolha de uma versão de firmware especifica para o equipamento ser atualizado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'recomendamos manter os equipamentos sempre atualizados com a última versão de firmware disponível para garantir o acesso a todos os recursos e para o seu melhor funcionamento.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como associar um wireless ao equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente onde o equipamento em questão esteja adicionado, selecione o equipamento, acesse a aba de "WIRELESS ASSOCIADAS" e clique em "ASSOCIAR WIRELESS", posteriormente a lista de wireless já configuradas aparecerá. Então marque o checkbox da wireless desejada e clique em "SALVAR".'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithAssociatedWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes do equipamento, com a opção de wireless associado destacada'
              )}
            />
            <img
              src={modalToChooseWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de escolher os wireless a serem associados'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                "você poderá criar uma nova rede wireless caso não haja nenhuma criada ou caso as que já estejam configuradas não abrangerem sua necessidade. Para tanto, se tiver dúvidas quanto a este processo, favor acessar o tópico 'Wireless' deste manual."
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como excluir um equipamento do ambiente?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente onde o equipamento em questão esteja adicionado, selecione o equipamento e posteriormente a opção excluir equipamento.'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithDelete}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes do equipamento, com a opção de excluir equipamento destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 1:')}</span>{' '}
              {t(
                'É possível também deletar pela listagem de equipamentos, bastando clicar no checkbox ao lado do equipamento que se desejar deletar, e depois clicar em "EXCLUIR EQUIPAMENTO (1)".'
              )}
            </p>
            <img
              src={viewAllDevicesWithOneChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com um equipamento selecionado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Também é possível excluir vários equipamentos de uma vez, basta selecionar múltiplos checkbox e apertar no botão vermelho "EXCLUIR EQUIPAMENTOS (2)", onde o valor entre parênteses representa a quantidade de equipamentos selecionadas para exclusão.'
              )}
            </p>
            <img
              src={viewAllDevicesWithTwoChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com dois equipamentos selecionados'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 2:')}</span>{' '}
              {t(
                'ao excluir um equipamento este será totalmente desvinculado do local e ambiente, deixará de ser monitorado pelo InMaster e voltará para o padrão de fábrica.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>
                {t('Importante:')}
              </span>{' '}
              {t(
                'por questão de segurança, um equipamento só pode ser adotado em um único local/ambiente do usuário. Para que este equipamento possa ser utilizado em uma outra conta ou local, ele deve ser obrigatoriamente excluído do ambiente ao qual está adotado'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Wireless'),
    items: [
      {
        title: t('Como criar uma nova wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente desejado, clique em "WIRELESS" e acesse a opção para criar uma nova wireless. Se não existir nenhuma rede wireless neste ambiente, a tela abaixo aparecerá. Para criar uma rede, deve-se clicar no botão de "+" em adicionar wireless.'
              )}
            </p>
            <img
              src={viewWirelessListEmpty}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela vázia da visualização dos wireless')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após isso, uma nova tela aparecerá com um formulário com os campos de nome, segurança e senha. A opção de dualband e duas abas: uma de configurações avançadas e outra de equipamentos.'
              )}
            </p>
            <img
              src={createNewWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela do formulário de criação de um novo wireless'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Nesta tela, deve-se obrigatoriamente preencher os campos de Nome e Senha. No campo de segurança da wireless há três opções: WPA2-PSK, WPA-PSK e Aberta. Caso seja escolhida a segurança aberta, o campo de senha desaparecerá.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Se o equipamento for dual band, há a possibilidade de escolher uma banda única para aquela rede, basta desativar o seletor de banda, Dual band, que fica abaixo do campo de senha, que duas opções aparecem, a de 2.4 GHz e de 5 GHz, selecionando uma dessas opções a rede criada irá transmitir o sinal apenas na frequência escolhida.'
              )}
            </p>
            <div
              className={classNames(['fit-width d-flex justify-center mt-4'])}>
              <img
                src={selectorFrequency}
                width="30%"
                alt={t('Imagem do seletor da frequência do wireless')}
              />
            </div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Então, basta clicar no botão "ADICIONAR WIRELESS" em verde que a rede será criada no ambiente. Só podem ser criadas 8 redes wireless por ambiente.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como associar equipamentos a uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Na mesma tela de criação da rede wireless, ao clicar na aba "EQUIPAMENTOS", uma lista contendo os equipamentos cadastrados no ambiente aparecerá, basta selecionar o marcador localizado a esquerda do nome da rede e clicar no botão de adicionar wireless.'
              )}
            </p>
            <img
              src={viewNewWirelessWithDevices}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de criação de uma wireless na aba de equipamentos com um selecionado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                "Um equipamento pode ser associado depois da criação da rede. Para isso, deve-se estar na tela de listagem de wireless e clicar no botão em formato de lápis a direita da rede, na coluna 'Ações'."
              )}
            </p>
            <img
              src={viewWirelessListWithEditButton}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização das wireless, com o botão de editar destacado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Logo um formulário semelhante ao de criação de wireless aparecerá. Deve-se, então, clicar em "EQUIPAMENTOS", selecionar o equipamento desejado e após clicar no botão "EDITAR WIRELESS" em verde.'
              )}
            </p>
            <img
              src={viewWirelessWithDevices}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de equipamentos com um selecionado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar a sensibilidade dos clientes?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'A sensibilidade determina o limite de sensibilidade em que ocorre a desconexão do cliente.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithSensitivity}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de sensibilidade de clientes destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como ocultar uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Ocultar rede wireless significa ocultar a visibilidade da rede, ou seja, deixar a rede oculta, com isso aumentando a privacidade e segurança.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithHide}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de ocultar wireless destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como isolar uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Isolar uma rede wireless é uma forma de aumentar a a privacidade e segurança da rede. Existem duas possibilidades de isolamento que podem ser ativadas juntas: isolar clientes e somente internet. Na configuração de isolar clientes o equipamento impede que clientes conectados dentro da mesma wireless possam se comunicar entre si.  Na opção de somente internet, os clientes conectados na rede somente acessarão a internet, não podendo acessar domínios de dentro da rede local.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithIsolateOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de opções de isolamento destacado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar uma VLAN?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Virtual Local Area Network (VLAN) é uma rede independente que se comunica com a rede física funcionando como uma forma de separação lógica para melhorar a administração, segurança e desempenho. Serve para segmentar e isolar as redes. Ao habilitar a VLAN, deve-se inserir um valor entre 1 e 4096 que se refere ao id da VLAN.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithVLAN}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de VLAN destacado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o limite de banda?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Os limites de banda servem para definir os limites de download e/ou upload em Mbps para os clientes na rede. Caso desabilitado, a banda da rede será ilimitada.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithQoS}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de limite de banda destacado'
              )}
            />
            <img
              src={fieldQoSEnable}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem do campo de limite de banda habilitado')}
            />
          </div>
        )
      },
      {
        title: t('Como excluir uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Dentro do ambiente que possui a rede wireless que deseja excluir, clique em "WIRELESS" no menu lateral esquerdo e, após, selecione o checkbox da rede a ser excluída.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Com o checkbox selecionado, o botão "EXCLUIR  WIRELESS (1)" aparecerá na tela, clicando-o uma janela de confirmação abrirá e para efetuar a exclusão basta apertar no botão verde "EXCLUIR".'
              )}
            </p>
            <img
              src={viewWirelessListWithOneChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização das wireless, no qual está selecionada uma wireless'
              )}
            />
            <img
              src={modalDeleteOneWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem do modal de exclusão de uma wireless')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Também é possível excluir várias redes de uma vez, basta selecionar múltiplos checkbox e apertar no botão vermelho "EXCLUIR WIRELESS (2)", o valor entre parênteses representa a quantidade de redes selecionadas, para excluir e depois no botão de "EXCLUIR" na janela de confirmação.'
              )}
            </p>
            <img
              src={viewWirelessListWithTwoChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização das wireless, no qual estão selecionadas duas wireless'
              )}
            />
            <img
              src={modalDeleteTwoWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem do modal de exclusão de duas ou mais wireless')}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Roaming?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Com o Roaming, o equipamento irá sugerir a transição para os dispositivos quando for conveniente. Opera somente em redes com criptografia WPA (WPA - PSK) ou WPA2 (WPA2 - PSK) e requer que o dispositivo do cliente tenha suporte a este protocolo.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithRoaming}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de roaming está destacada'
              )}
            />
            <img
              src={viewAdvancedWirelessWithButtonSave}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual o botão salvar está destacado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Band Steering?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                "Com o Band Steering o equipamento irá escolher a melhor frequência para o cliente baseado em métricas de rede e qualidade de sinal. Ocorre apenas entre redes wireless de mesmo nome. Há duas opções: 'balanceamento de clientes entre frequências' e 'preferencialmente 5 GHz'. Na opção de balanceamento, o equipamento irá realizar o balanceamento de carga entre as frequências. Na opção de preferencialmente 5 GHz, os clientes com suporte 802.11ac terão preferência para conexão em 5 GHz."
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithBandSteering}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de band steering está destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Beamforming?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O Beamforming garante que o sinal do WI-FI seja transmitido para dispositivos específicos dos clientes, evitando perdas durante a transmissão e melhorando o nível do sinal na área de 360º ao redor do equipamento.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithBeamforming}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de beamforming está destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Multi-User MIMO (MU-MIMO)?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O MU-MIMO garante maior performance aos clientes conectados na rede, realizando múltiplas transmissões de dados aos clientes simultaneamente. A funcionalidade requer que o dispositivo do cliente tenha suporte a esta tecnologia. Para o MU-MIMO ser ativado é necessário que o Beamforming também esteja ativo.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithMUMIMO}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de mu-mimo está destacada'
              )}
            />
          </div>
        )
      }
    ]
  },
  {
    section: t('Clientes'),
    items: [
      {
        title: t('Como verificar os clientes conectados?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Clientes conectados são as atuais conexões da rede wireless, ou seja, os dispositivos que estão conectados a certa rede wireless naquele instante de tempo com atualização dos dados em até 30 segundos.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para verificar os clientes conectados, deve-se acessar o ambiente no qual há as redes wireless que se deseja saber as atuais conexões e clicar no submenu de "CLIENTES CONECTADOS", do menu "CLIENTES" presente no menu lateral à esquerda da tela. Como indicado na imagem abaixo:'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Se há um ou mais clientes conectados, a tela abaixo é mostrada com os dados de MAC do cliente, o equipamento ao qual ele está conectado, a rede wireless, a frequência da rede, o tempo de conexão, a potência do sinal e quanto de dados foram consumidos em download e upload.'
              )}
            </p>
            <img
              src={viewConnectedClients}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela de visualização dos clientes conectados')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se naquele instante não houver nenhum cliente conectado, a seguinte tela é mostrada ao usuário:'
              )}
            </p>
            <img
              src={viewConnectedClientsEmpty}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos clientes conectados vazio'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como acessar o registro de conexões?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Os registros de conexões são os dados da conexão completa do cliente naquela rede wireless, ou seja, o registro só é feito após a desconexão do cliente na rede. Os registros são agrupados por local, portanto todas as conexões registradas nas redes presentes nos ambientes de um local serão mostradas na mesma tela.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para acessar a tela de registro de conexões, deve-se estar na tela de locais e clicar no menu em forma de reticências, como mostrado em marrom na imagem abaixo. Após, clicar em "RESGISTRO DE CONEXÕES".'
              )}
            </p>
            <img
              src={siteWithMenuOpenOnConnectionsLog}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de locais no qual a opção de registro de conexão está destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se não foi registrada nenhuma conexão nas redes wireless presentes nos ambientes desse local, a tela abaixo é mostrada ao cliente:'
              )}
            </p>
            <img
              src={viewConnectionsLogEmpty}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos registro de conexão vazia'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se há um ou mais registros, a tela abaixo é mostrada ao usuário com os dados de IP do cliente, MAC do cliente, o nome do cliente, o ambiente da rede wireless que houve a conexão, o MAC do equipamento associado a essa rede wireless, data e hora de início e fim da conexão, e, também um botão para realizar o download do dados.'
              )}
            </p>
            <img
              src={viewConnectionsLog}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela de visualização dos registros de conexão')}
            />
          </div>
        )
      },
      {
        title: t('Como exportar os registros de conexões?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para exportar os registros de conexões é necessário clicar no botão "DOWNLOAD DOS DADOS" para baixar um arquivo csv contendo todas as informações presentes em tela.'
              )}
            </p>
            <img
              src={viewConnectionsLogWithDownloadOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos registros de conexão, com a opção de download destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, uma mensagem irá aparecer na tela durante o download do arquivo exportado.'
              )}
            </p>
            <img
              src={viewConnectionsLogWithLoadingMensage}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da mensagem de aviso que irá baixar os registro')}
            />
          </div>
        )
      }
    ]
  }
];

import classNames from 'classnames';
import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import styles from './ButtonComeBack.module.css';

interface IButtonComeBack {
  onClick: () => void;
}

const ButtonComeBack = ({ onClick, ...props }: IButtonComeBack) => {
  return (
    <button
      type="button"
      id="btn-back-to-sites"
      className={classNames([styles.backToSitesButton, 'mr-3'])}
      onClick={onClick}
      {...props}>
      <MdKeyboardBackspace size={16} />
    </button>
  );
};

export { ButtonComeBack };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { useArrayCompare } from 'src/hooks/useFormCompare';
import { Alert } from 'ui-components/Alert';
import Label from 'ui-components/Label';
import Modal from 'ui-components/Modal';
import Table from 'ui-components/Table';
import { generateColumns } from 'src/utils/tableUtils';
import Tag from 'ui-components/Tags';
import { ButtonIcon } from 'ui-components/Button';
import { MdEdit } from 'react-icons/md';
import { IDeviceOnScreen, IResumedWireless } from '../../types';
import styles from './AssociatedWirelessModal.module.css';

interface IProps {
  show: boolean;
  associatedWireless: IResumedWireless[];
  updateDeviceMutationIsLoading: boolean;
  onClose: () => void;
  setSelectedWireless: (
    value: React.SetStateAction<IResumedWireless[]>
  ) => void;
  updateDevice: () => void;
  wirelessList: IResumedWireless[];
  selectedWireless: IResumedWireless[];
  deviceData: IDeviceOnScreen;
}

const AssociatedWirelessModal = ({
  show,
  associatedWireless,
  updateDeviceMutationIsLoading,
  onClose,
  setSelectedWireless,
  updateDevice,
  wirelessList,
  selectedWireless,
  deviceData
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const navigate = useNavigate();

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('security', t('Segurança'), 'left', 0),
    generateColumns('frequency', t('Frequência'), 'left', 0),
    generateColumns('isVisible', t('Visibilidade'), 'left', 0),
    generateColumns('action', t('Ações'), 'center', 0)
  ];

  const isFormEquals = useArrayCompare(
    deviceData?.wireless_list ?? [],
    selectedWireless || [],
    ['id', 'name']
  );

  const checkTagVisibility = (wireless: IResumedWireless) => {
    return (
      <div>
        {wireless.enabled ? (
          <div>
            {wireless.hidden ? (
              <Tag color="neutral-dark-4" outlined wide>
                {t('Oculta')}
              </Tag>
            ) : (
              <Tag color="blue" outlined wide>
                {t('Visível')}
              </Tag>
            )}
          </div>
        ) : (
          <Tag color="lightblue" outlined wide>
            {t('Desabilitada')}
          </Tag>
        )}
      </div>
    );
  };

  const checkIsFrequencySuportted = (wireless: IResumedWireless) => {
    return (
      (!deviceData.radio_list?.find((radio) => radio.band === '2.4') &&
        wireless.frequency.includes('2.4') &&
        !wireless.frequency.includes('5')) ||
      (!deviceData.radio_list?.find((radio) => radio.band === '5') &&
        wireless.frequency.includes('5') &&
        !wireless.frequency.includes('2.4'))
    );
  };

  const wirelessListFormatted = (
    wirelessNotFormattedList: IResumedWireless[]
  ) => {
    if (!wirelessNotFormattedList || !deviceData) {
      return [];
    }

    const wirelessRows = wirelessNotFormattedList.map((wireless) => {
      return {
        ...wireless,
        isVisible: checkTagVisibility(wireless),
        disabled: checkIsFrequencySuportted(wireless),
        action: (
          <ButtonIcon
            id={`edit-wireless-${wireless.name}`}
            onClick={() => {
              navigate(`/wireless/edit/${wireless.id}`);
            }}>
            <MdEdit size={24} />
          </ButtonIcon>
        )
      };
    });

    return wirelessRows;
  };

  return (
    <Modal
      show={show}
      title={
        associatedWireless && associatedWireless.length > 0
          ? t('Edite as Wireless associadas ao equipamento')
          : t('Associe Wireless ao equipamento')
      }
      width="800px"
      actions={[
        {
          disabled: updateDeviceMutationIsLoading,
          label: t('CANCELAR'),
          action: () => {
            onClose();
            setSelectedWireless(associatedWireless);
          }
        },
        {
          disabled: isFormEquals,
          isLoading: updateDeviceMutationIsLoading,
          label: t('SALVAR'),
          action: updateDevice
        }
      ]}>
      {wirelessList.length === 0 ? (
        <EmptyMessage
          height="60vh"
          title={t('Nenhuma wireless')}
          subtitle={t(
            'Ainda não há wireless disponível neste ambiente. Experimente adicionar uma nova'
          )}
          buttonText={t('ADICIONAR WIRELESS')}
          id="btn-no-wireless-equipament-details"
          action={() => navigate('/wireless/add')}
        />
      ) : (
        <div className={styles.tableContainer}>
          <Label>
            {t(
              'Selecione até 8 Wireless que serão transmitidas por esse equipamento'
            )}
          </Label>
          <div className="mt-2 mb-3">
            <Alert type="info">
              {t(
                'Verifique a frequência da Wireless e a capacidade de banda do seu equipamento para evitar incompatibilidades.'
              )}
            </Alert>
          </div>

          <Table
            columns={columns}
            rows={wirelessListFormatted(wirelessList)}
            onSelectRows={(selectedRows: IResumedWireless[]) => {
              setSelectedWireless(selectedRows);
            }}
            selectedRows={selectedWireless}
            elementToDisableRow="disabled"
            textTooltipDisableRow={t(
              'Este equipamento não suporta essa wireless'
            )}
          />
        </div>
      )}
    </Modal>
  );
};

export default AssociatedWirelessModal;

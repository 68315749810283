import { Tooltip } from 'inmaster-ui';
import React, { useState, useEffect } from 'react';
import styles from './Tab.module.css';

const Tabs = ({ tabs = [], activeTabIndex = 0, ...props }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabs = (newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  const checkClassesNames = (tab) => {
    const classes = [];
    if (tab.disabled) {
      classes.push(styles.disabled);
    }
    if (tab.label === activeTab) {
      classes.push(styles.active);
    }
    return classes.join(' ');
  };

  useEffect(() => {
    if (activeTabIndex < 0) return;
    if (tabs[activeTabIndex].label !== activeTab) {
      setActiveTab(tabs[activeTabIndex].label);
    }
  }, [activeTabIndex]);

  return (
    <div className={styles.Tabs}>
      <ul className={styles.nav}>
        {tabs.map((tab) => (
          <li
            key={tab.label}
            className={checkClassesNames(tab)}
            disabled={tab.disabled}
            onClick={() => {
              if (tab.disabled) return;

              handleTabs(tab.label);
              tab.onClick ? tab.onClick() : null;
            }}>
            <Tooltip
              content={tab.tooltip}
              disabled={tab.tooltip === undefined || tab.tooltip === ''}
              place="bottom"
              id={`tooltip-${tab.label}`}>
              {tab.label}
              {tab.badge}
            </Tooltip>
          </li>
        ))}
      </ul>
      <div>
        {activeTab === tabs[0].label
          ? tabs[0].component
          : tabs.map((data, index) => {
              if (activeTab === data.label) {
                return (
                  <div key={data.label + String(index)} {...props}>
                    {data.component}
                  </div>
                );
              }
              return false;
            })}
      </div>
    </div>
  );
};

export default Tabs;

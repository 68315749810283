export interface IAdvancedWirelessForm {
  assisted_roaming: boolean;
  fast_roaming: boolean;
  smart_handover: boolean;
  main_acess_point?: string;
  band_steering: boolean;
  band_steering_method: string;
  mu_mimo: boolean;
  beamforming: boolean;
}

export interface IAdvancedWirelessData {
  wireless_advanced: {
    id: string;
    created_at: string;
    updated_at: string;
    site: string;
    fast_roaming: boolean;
    beamforming: boolean;
    band_steering: 'loadbalance' | '5g_prioritization' | null;
    mu_mimo: boolean;
  };
}

export interface IUpdateAdvancedWireless {
  wireless_advanced: {
    fast_roaming: boolean;
    beamforming: boolean;
    band_steering: 'loadbalance' | '5g_prioritization' | null;
    mu_mimo: boolean;
  };
}

export const defaultValuesAdvancedWireless: IAdvancedWirelessForm = {
  assisted_roaming: false,
  fast_roaming: false,
  smart_handover: false,
  main_acess_point: undefined,
  band_steering: true,
  band_steering_method: 'loadbalance',
  mu_mimo: true,
  beamforming: true
};

import { RoleMember } from 'src/components/ModalCreateMembers/types';

// eslint-disable-next-line no-shadow
export enum ActiveTabSitesEnum {
  MY_SITES,
  SHARED_WITH_ME
}

export interface ISiteData {
  name: string;
  timezone: string;
}

export interface ISiteItemPage {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  timezone: string;
  showMenu?: boolean;
  user_count: number;
  place_count: number;
  has_acl: boolean;

  member?: {
    id: string;
    is_new_site_member: boolean;
    role: RoleMember;
  };
}

export interface ISharedSiteItemPage extends ISiteItemPage {
  member: {
    id: string;
    is_new_site_member: boolean;
    role: RoleMember;
  };
}

export interface IDeleteSiteData {
  checkContinueDelete: boolean;
}

export interface IResponsePostSiteData {
  site: {
    id: string;
    name: string;
    timezone: string;
  };
}

export interface ISelectedSiteData {
  id: string;
  name: string;
  member?: {
    id: string;
    is_new_site_member: boolean;
    role: RoleMember;
  };
}

export interface ISelectedSiteToMembersDrawerData {
  site: ISiteItemPage;
  roleMember: RoleMember;
}

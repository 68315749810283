import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTemplate } from 'src/hooks/useTemplate';
import Card from 'ui-components/Card';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import {
  MdAdd,
  MdFactCheck,
  MdHomeWork,
  MdMoreHoriz,
  MdPerson
} from 'react-icons/md';
import { timezones } from 'src/utils/timezones';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import api from 'src/services/api';
import { ISiteResponse } from 'src/services/api/urls/sites/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Tabs from 'ui-components/Tab';
import DuoToneIcons from 'src/components/DuoToneIcons';
import { EmptyMessage } from 'src/components/EmptyMessage';
import Badge from 'src/components/Badge';
import { ModalCreateMembers } from 'src/components/ModalCreateMembers';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import Loading from 'ui-components/Loading';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { Tooltip } from 'inmaster-ui';
import {
  ActiveTabSitesEnum,
  ISelectedSiteData,
  ISelectedSiteToMembersDrawerData,
  ISharedSiteItemPage,
  ISiteItemPage
} from './types';
import styles from './Sites.module.css';
import { MembersDrawer } from './MembersDrawer';
import { ModalExitSharedSite } from './ModalExitSharedSite';
import { ModalCreateOrEditSite } from './ModaCreateOrEditSite';
import { ModalRemoveSite } from './ModalRemoveSite';

interface IErrorResponseMessage {
  attr: string[];
  msg: string;
}
export interface IErrorResponse {
  response: {
    status: number;
    data: { message: IErrorResponseMessage[] };
  };
}

const Sites = () => {
  const { accessToken, setSiteTimezone } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation('translations', { keyPrefix: 'site' });

  const environment = process.env.REACT_APP_TYPE_ENV;

  const [showDrawer, setShowDrawer] = useState(false);

  const [showModalCreateMember, setShowModalCreateMember] = useState(false);

  const [showModalCreateSite, setShowModalCreateSite] = useState(false);

  const [createOrEditModalType, setCreateOrEditModalType] = useState<
    'create' | 'edit'
  >('create');

  const [showModalDeleteSite, setShowModalDeleteSite] = useState(false);

  const [sites, setSites] = useState<ISiteItemPage[]>([] as ISiteItemPage[]);

  const [sharedSites, setSharedSites] = useState<ISharedSiteItemPage[]>([]);

  const [canCreateLocal, setCanCreateLocal] = useState(false);

  const [deletingSiteIndex, setDeletingSiteIndex] = useState(-1);

  const [showModalConfirmSharedSiteExit, setShowModalConfirmSharedSiteExit] =
    useState(false);

  const [selectedSiteToAddMembers, setSelectedSiteToAddMembers] = useState<
    ISiteItemPage | ISharedSiteItemPage | null
  >(null);

  const [selectedSiteToMembersDrawer, setselectedSiteToMembersDrawer] =
    useState<ISelectedSiteToMembersDrawerData | null>(null);

  const [selectedSharedSiteToExit, setSelectedSharedSiteToExit] =
    useState<ISelectedSiteData | null>(null);

  const [selectedSiteToEdit, setSelectedSiteToEdit] = useState<
    ISiteItemPage | ISharedSiteItemPage | null
  >(null);

  const [newSharedSitesCount, setNewSharedSiteCount] = useState(0);

  const [activeTabIndex, setActiveTabIndex] = useLocalStorage(
    '@manager/activeTabSite',
    ActiveTabSitesEnum.MY_SITES
  );

  const [isAddingSite, setIsAddingSite] = useState(false);

  const [siteIdBeingEditedOrDeleted, setSiteIdBeingEditedOrDeleted] = useState<
    string | null
  >(null);

  const [isAboveInIcon, setIsAboveInIcon] = useState(false);

  useEffect(() => {
    if (sites.length < 32) {
      setCanCreateLocal(true);
    } else {
      setCanCreateLocal(false);
    }
  }, [sites]);

  const setShowMenuOfSite = (
    index: number,
    value: boolean,
    isSharedSite?: boolean
  ) => {
    if (isSharedSite) {
      setSharedSites((prev) => {
        const aux = [...prev];
        aux[index].showMenu = value;
        return aux;
      });
    } else {
      setSites((prev) => {
        const aux = [...prev];
        aux[index].showMenu = value;
        return aux;
      });
    }
  };

  // queries
  const {
    refetch: refetchSites,
    isFetchedAfterMount: isFetchedSitesAfterMount
  } = useQuery(
    ['sitesListQuery', accessToken?.user],
    () => {
      return api.sites.get('', true);
    },
    {
      onSuccess: ({ data }: { data: ISiteResponse }) => {
        if (data.sites.length === 0) {
          navigate('/first-access');
        }
        for (const site of data.sites as unknown as ISiteItemPage[]) {
          site.showMenu = false;
        }
        if (isAddingSite) {
          setIsAddingSite(false);
        }
        if (siteIdBeingEditedOrDeleted) {
          setSiteIdBeingEditedOrDeleted(null);
        }
        setSites(data.sites);
      }
    }
  );

  const { refetch: refetchNewSharedSitesCount } = useQuery(
    'newSharedSitesCountQuery',
    () => {
      return api.sites.shared.getNewSitesCount();
    },
    {
      onSuccess: ({ data }) => {
        setNewSharedSiteCount(data.new_sites_count);
      }
    }
  );

  const {
    refetch: refetchSharedSites,
    isFetchedAfterMount: isFetchedSharedSitesAfterMount
  } = useQuery(
    'sharedSitesListQuery',
    () => {
      return api.sites.shared.get('', true);
    },
    {
      enabled: activeTabIndex === ActiveTabSitesEnum.SHARED_WITH_ME,
      onSuccess: ({ data }) => {
        setSharedSites(data.sites);
      }
    }
  );

  const navigateToSite = () => {
    navigate('/sites', { replace: true });
  };

  const openModalExitSharedSite = (site: ISharedSiteItemPage) => {
    setSelectedSharedSiteToExit({
      id: site.id,
      name: site.name,
      member: site.member
    } as ISelectedSiteData);
    setShowModalConfirmSharedSiteExit(true);
  };

  const onDeleteSharedSite = () => {
    setSelectedSharedSiteToExit({} as ISelectedSiteData);
    setShowModalConfirmSharedSiteExit(false);
    refetchSharedSites();
    refetchNewSharedSitesCount();
    refetchSites();
  };

  const openModalCreateMember = (site: ISiteItemPage | ISiteItemPage) => {
    setShowModalCreateMember(true);
    setSelectedSiteToAddMembers(site);
  };

  const handleEditModal = (site: ISiteItemPage | ISharedSiteItemPage) => {
    setCreateOrEditModalType('edit');
    setSelectedSiteToEdit(site);
    setShowModalCreateSite(true);
  };

  const onDeleteSite = () => {
    refetchSites();
  };

  const onCreatingSite = () => {
    navigateToSite();
    setActiveTabIndex(0);
    refetchSites();
    refetchSharedSites();
  };

  const onEditingSite = () => {
    refetchSites();
    refetchSharedSites();
  };

  const openMembersDrawer = (
    site: ISiteItemPage,
    index: number,
    roleMember: RoleMember,
    isSharedSite?: boolean
  ) => {
    setselectedSiteToMembersDrawer({
      site,
      roleMember
    });
    setShowDrawer(true);
    setShowMenuOfSite(index, false, isSharedSite);
  };

  const handleOnSiteClick = (site: ISiteItemPage) => {
    if (!isAboveInIcon) {
      setSiteTimezone(site.timezone);
      return navigate(`/sites/${site.id}/places`);
    }
    return null;
  };

  const renderSites = () => {
    return (
      <Row>
        {isFetchedSitesAfterMount ? (
          <>
            {sites.map((site, index) => (
              <div className="fit-width mb-3" key={site.id}>
                <Card
                  clickable
                  className={classNames([
                    styles.cardSite,
                    'd-flex align-start'
                  ])}
                  borderColor="var(--color-brand-secondary-light)">
                  <div
                    className="d-flex py-7 pl-7 pr-8 fit-width justify-between align-center"
                    onClick={() => handleOnSiteClick(site)}
                    aria-hidden>
                    <div className="d-flex flex-column">
                      <span
                        className="title-sm-base mb-2"
                        id={`site-name-${site.name}`}>
                        {site.name}
                      </span>
                      <span
                        className="text-sm-base"
                        id={`site-timezone-${site.name}`}>
                        {site.timezone
                          ? timezones.find(
                              (item) => item.value === site.timezone
                            )?.label
                          : null}
                      </span>
                    </div>

                    <div className="d-flex">
                      {environment === 'development' && site.has_acl && (
                        <DuoToneIcons
                          className={styles.dueToneIcons}
                          value={1} // TODO: Colocar propriedade que contara a quantidade de ACLs enviadas pelo back
                          title={t('Lista de acesso')}
                          colorIcon="var(--color-brand-primary-base)"
                          icon={<MdFactCheck size={24} />}
                          onClick={() => {
                            navigate(`/sites/${site.id}/access-lists`);
                          }}
                          onMouseEnter={() => setIsAboveInIcon(true)}
                          onMouseLeave={() => setIsAboveInIcon(false)}
                        />
                      )}

                      <DuoToneIcons
                        className={styles.dueToneIcons}
                        value={site.place_count}
                        title={t('Ambientes')}
                        colorIcon="var(--color-brand-primary-base)"
                        icon={<MdHomeWork size={24} />}
                      />

                      <DuoToneIcons
                        onClick={() =>
                          openMembersDrawer(site, index, RoleMember.OWNER)
                        }
                        onMouseEnter={() => setIsAboveInIcon(true)}
                        onMouseLeave={() => setIsAboveInIcon(false)}
                        value={site.user_count}
                        title={t('Membros')}
                        colorIcon="var(--color-brand-primary-base)"
                        icon={<MdPerson size={28} />}
                      />
                    </div>
                  </div>

                  <div
                    className={classNames([
                      'd-flex justify-between py-4 pr-4 flex-column',
                      styles.rightCardBody
                    ])}>
                    <Menu
                      menuId={`menu-site-actions-${site.name}`}
                      items={
                        <>
                          <MenuItem
                            disabled={siteIdBeingEditedOrDeleted === site.id}
                            onClick={() => handleEditModal(site)}
                            aria-hidden="true"
                            id={`btn-edit-site-${site.name}`}>
                            <span className="text-sm-sm">
                              {t('Editar local')}
                            </span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => openModalCreateMember(site)}
                            aria-hidden="true"
                            disabled={site.user_count >= 120}
                            id={`btn-edit-site-${site.name}`}>
                            <span className="text-sm-sm">
                              {t('Adicionar membro')}
                            </span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              openMembersDrawer(site, index, RoleMember.OWNER);
                            }}
                            aria-hidden="true"
                            id={`btn-members-site-${site.name}`}>
                            <span className="text-sm-sm">
                              {t('Visualizar membros')}
                            </span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate(`/sites/${site.id}/connection-log`);
                            }}
                            aria-hidden="true"
                            id={`btn-members-site-${site.name}`}>
                            <span className="text-sm-sm">
                              {t('Registro de conexões')}
                            </span>
                          </MenuItem>
                          {environment === 'development' && (
                            <MenuItem
                              onClick={() => {
                                navigate(`/sites/${site.id}/access-lists`);
                              }}
                              aria-hidden="true"
                              id={`btn-acls-site-${site.name}`}>
                              <span className="text-sm-sm">
                                {t('Listas de acesso (ACL)')}
                              </span>
                            </MenuItem>
                          )}
                          {sites.length === 1 ? null : (
                            <MenuItem
                              disabled={siteIdBeingEditedOrDeleted === site.id}
                              onClick={() => {
                                setDeletingSiteIndex(index);
                                setShowMenuOfSite(index, false);
                                setShowModalDeleteSite(true);
                              }}
                              aria-hidden="true"
                              id={`btn-delete-site-${site.name}`}>
                              <span
                                className="text-sm-sm"
                                style={{
                                  color: 'var(--color-status-critical-base)'
                                }}>
                                {t('Excluir local')}
                              </span>
                            </MenuItem>
                          )}
                        </>
                      }
                      positionX="end"
                      width="200px"
                      onClose={() => setShowMenuOfSite(index, false)}
                      show={site.showMenu}>
                      <div
                        id={`btn-menu-site-actions-${site.name}`}
                        className={styles.menuActions}
                        aria-hidden="true"
                        onClick={() => setShowMenuOfSite(index, true)}>
                        <MdMoreHoriz size={24} />
                      </div>
                    </Menu>
                  </div>
                </Card>
              </div>
            ))}

            {isAddingSite && (
              <div className="fit-width">
                <Skeleton count={1} height={114} width="100%" />
              </div>
            )}
          </>
        ) : (
          <div className="fit-width">
            <Skeleton
              count={6}
              height={114}
              width="100%"
              className={styles.skeletonSites}
            />
          </div>
        )}
      </Row>
    );
  };

  const renderSharedSites = () => {
    return !sharedSites.length && isFetchedSharedSitesAfterMount ? (
      <div className="fit-width d-flex justify-center align-center">
        <EmptyMessage
          title={t('Nenhum local')}
          subtitle={t('Ainda não há locais compartilhados com você')}
          id="message-no-shared-sites"
          height="calc(50vh - 95px)"
        />
      </div>
    ) : (
      <Row>
        {isFetchedSharedSitesAfterMount ? (
          sharedSites.map((site, index) => (
            <div className="fit-width mb-3" key={site.id}>
              <Card
                clickable
                className={classNames([styles.cardSite, 'd-flex align-start'])}
                borderColor="var(--color-brand-secondary-light)">
                <div
                  className="d-flex  py-7 pl-7 pr-8 fit-width justify-between align-center"
                  onClick={() => handleOnSiteClick(site)}
                  aria-hidden>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-center mb-2">
                      <span
                        className="title-sm-base  mr-2"
                        id={`shared-site-name-${site.name}`}>
                        {site.name}
                      </span>

                      <Badge
                        color={
                          site.member.role === RoleMember.MANAGER
                            ? 'var(--color-neutral-dark-4)'
                            : 'var(--color-product-lightest)'
                        }
                        title={
                          site.member.role === RoleMember.MANAGER
                            ? t('gerente')
                            : t('convidado')
                        }
                        id={`shared-site-${site.name}-role-${site.member.role}`}
                      />

                      {site.member.is_new_site_member && (
                        <Badge
                          color="var(--color-neutral-light-5)"
                          backgroundColor="var(--color-status-critical-base)"
                          title={t('novo')}
                          className="ml-1"
                          id={`badge-new-shared-site-${site.name}`}
                        />
                      )}
                    </div>
                    <span
                      className="text-sm-base"
                      id={`shared-site-timezone-${site.name}`}>
                      {site.timezone
                        ? timezones.find((item) => item.value === site.timezone)
                            ?.label
                        : null}
                    </span>
                  </div>

                  <div className="d-flex">
                    {environment === 'development' && site.has_acl && (
                      <DuoToneIcons
                        className={styles.dueToneIcons}
                        value={1} // TODO: Colocar propriedade que contara a quantidade de ACLs enviadas pelo back
                        title={t('Lista de acesso')}
                        colorIcon="var(--color-brand-primary-base)"
                        icon={<MdFactCheck size={24} />}
                        onClick={() => {
                          navigate(`/sites/${site.id}/access-lists`);
                        }}
                        onMouseEnter={() => setIsAboveInIcon(true)}
                        onMouseLeave={() => setIsAboveInIcon(false)}
                      />
                    )}
                    <DuoToneIcons
                      className={styles.dueToneIcons}
                      value={site.place_count}
                      title={t('Ambientes')}
                      colorIcon="var(--color-brand-primary-base)"
                      icon={<MdHomeWork size={24} />}
                    />

                    <DuoToneIcons
                      onClick={() =>
                        openMembersDrawer(site, index, site.member.role, true)
                      }
                      onMouseEnter={() => setIsAboveInIcon(true)}
                      onMouseLeave={() => setIsAboveInIcon(false)}
                      value={site.user_count}
                      title={t('Membros')}
                      colorIcon="var(--color-brand-primary-base)"
                      icon={<MdPerson size={24} />}
                    />
                  </div>
                </div>

                <div
                  className={classNames([
                    'd-flex justify-between py-4 pr-4 flex-column',
                    styles.rightCardBody
                  ])}>
                  <Menu
                    menuId={`menu-shared-site-actions-${site.name}`}
                    items={
                      <>
                        {site.member.role === RoleMember.MANAGER && (
                          <>
                            <MenuItem
                              disabled={siteIdBeingEditedOrDeleted === site.id}
                              onClick={() => handleEditModal(site)}
                              aria-hidden="true"
                              id={`btn-edit-shared-site-${site.name}`}>
                              <span className="text-sm-sm">
                                {t('Editar local')}
                              </span>
                            </MenuItem>
                            <MenuItem
                              onClick={() => openModalCreateMember(site)}
                              aria-hidden="true"
                              disabled={site.user_count >= 120}
                              id={`btn-edit-shared-site-${site.name}`}>
                              <span className="text-sm-sm">
                                {t('Adicionar membro')}
                              </span>
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          onClick={() => {
                            openMembersDrawer(
                              site,
                              index,
                              site.member.role,
                              true
                            );
                          }}
                          aria-hidden="true"
                          id={`btn-members-shared-site-${site.name}`}>
                          <span className="text-sm-sm">
                            {t('Visualizar membros')}
                          </span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            navigate(`/sites/${site.id}/connection-log`);
                          }}
                          aria-hidden="true"
                          id={`btn-members-site-${site.name}`}>
                          <span className="text-sm-sm">
                            {t('Registro de conexões')}
                          </span>
                        </MenuItem>
                        {environment === 'development' && (
                          <MenuItem
                            onClick={() => {
                              navigate(`/sites/${site.id}/access-lists`);
                            }}
                            aria-hidden="true"
                            id={`btn-acls-site-${site.name}`}>
                            <span className="text-sm-sm">
                              {t('Listas de acesso (ACL)')}
                            </span>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => openModalExitSharedSite(site)}
                          aria-hidden="true"
                          id={`btn-exit-shared-site-${site.name}`}>
                          <span
                            className="text-sm-sm"
                            style={{
                              color: 'var(--color-status-critical-base)'
                            }}>
                            {t('Sair do local')}
                          </span>
                        </MenuItem>
                      </>
                    }
                    positionX="end"
                    width="200px"
                    onClose={() => setShowMenuOfSite(index, false, true)}
                    show={site.showMenu}>
                    <div
                      id={`btn-menu-shared-site-actions-${site.name}`}
                      className={styles.menuActions}
                      aria-hidden="true"
                      onClick={() => setShowMenuOfSite(index, true, true)}>
                      <MdMoreHoriz size={24} />
                    </div>
                  </Menu>
                </div>
              </Card>
            </div>
          ))
        ) : (
          <div className="fit-width">
            <Skeleton
              count={6}
              height={114}
              width="100%"
              className={styles.skeletonSites}
            />
          </div>
        )}
      </Row>
    );
  };

  const renderTabBadgeSharedWithMe = () => {
    return (
      <Badge
        color="var(--color-neutral-light-5)"
        backgroundColor="var(--color-status-critical-base)"
        title={`${newSharedSitesCount} ${
          newSharedSitesCount > 1 ? t('novos') : t('novo')
        }`}
        size="small"
        className="ml-1"
        id={`badge-new-shared-sites-count-${newSharedSitesCount}`}
      />
    );
  };

  const switchPages = () => {
    switch (activeTabIndex) {
      case ActiveTabSitesEnum.MY_SITES:
        return renderSites();
      case ActiveTabSitesEnum.SHARED_WITH_ME:
        return renderSharedSites();
      default:
        return null;
    }
  };

  const tabs = [
    {
      label: t('Meus Locais'),
      component: switchPages(),
      onClick: () => {
        setActiveTabIndex(ActiveTabSitesEnum.MY_SITES);
      }
    },
    {
      label: t('Compartilhados comigo'),
      component: switchPages(),
      onClick: () => {
        setActiveTabIndex(ActiveTabSitesEnum.SHARED_WITH_ME);
      },
      badge: newSharedSitesCount > 0 && renderTabBadgeSharedWithMe()
    }
  ];

  useTemplate('avatarNavbar');

  if (!isFetchedSitesAfterMount && sites.length === 0) {
    return <Loading value={40} indeterminate fullscreen show />;
  }

  return (
    <div className={styles.shellSites}>
      <MembersDrawer
        initMembersCount={selectedSiteToMembersDrawer?.site.user_count ?? 0}
        selectedSite={selectedSiteToMembersDrawer}
        showDrawer={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
        onMemberCreated={() => {
          refetchSites();
          refetchSharedSites();
        }}
        onMemberRemoved={() => {
          refetchSites();
          refetchSharedSites();
        }}
      />

      <ModalRemoveSite
        sites={sites}
        setSites={setSites}
        deletingSiteIndex={deletingSiteIndex}
        setDeletingSiteIndex={setDeletingSiteIndex}
        showModalDeleteSite={showModalDeleteSite}
        setShowModalDeleteSite={setShowModalDeleteSite}
        onRemoveSite={onDeleteSite}
        setSiteIdBeingEditedOrDeleted={setSiteIdBeingEditedOrDeleted}
      />
      <ModalCreateOrEditSite
        createOrEditModalType={createOrEditModalType}
        setCreateOrEditModalType={setCreateOrEditModalType}
        selectedSite={selectedSiteToEdit}
        showModalCreateSite={showModalCreateSite}
        setShowModalCreateSite={setShowModalCreateSite}
        onCreatingSite={onCreatingSite}
        onEditingSite={onEditingSite}
        setIsAddingSite={setIsAddingSite}
        setSiteIdBeingEditedOrDeleted={setSiteIdBeingEditedOrDeleted}
        setSites={setSites}
        setSharedSites={setSharedSites}
      />
      <ModalExitSharedSite
        showModalConfirmSharedSiteExit={showModalConfirmSharedSiteExit}
        sharedSite={selectedSharedSiteToExit}
        onDeleteSharedSite={onDeleteSharedSite}
        memberId={selectedSharedSiteToExit?.member?.id}
        setSharedSites={setSharedSites}
      />
      <ModalCreateMembers
        setShowModalCreateMember={setShowModalCreateMember}
        showModalCreateMember={showModalCreateMember}
        site={selectedSiteToAddMembers}
        onMemberCreated={(newMembersCount) => {
          setSites((prev) => {
            const newSites = [...prev];
            const siteIndex = newSites.findIndex(
              (site) => site.id === selectedSiteToAddMembers?.id
            );
            if (siteIndex !== -1) {
              newSites[siteIndex].user_count = newMembersCount;
            }
            return newSites;
          });
          refetchSites();
          refetchSharedSites();
        }}
      />
      <Grid fluid>
        <Row className="mb-7">
          <Col xs={12}>
            <div className="d-flex justify-between align-center">
              <h1
                className={classNames(['title-sm-xl ml-2', styles.title])}
                id="site-title-page">
                {t('LOCAIS')}
              </h1>
              <div className="d-flex ">
                <Tooltip
                  content={t('Limite de criação de 32 locais atingido')}
                  style={{ width: 190 }}
                  id="tooltip-limit-creation-site"
                  place="bottom"
                  disabled={canCreateLocal}>
                  {isFetchedSitesAfterMount ? (
                    <ButtonTextOutside
                      id="text-btn-open-modal-to-create-site"
                      value={t('CRIAR LOCAL')}
                      className={
                        canCreateLocal
                          ? classNames(['text-uppercase mr-7'])
                          : classNames([
                              'text-uppercase mr-7',
                              styles.textDisabled
                            ])
                      }>
                      <Button
                        onClick={() => setShowModalCreateSite(true)}
                        id="btn-open-modal-to-create-site"
                        disabled={!canCreateLocal}
                        rounded>
                        <MdAdd size={22} />
                      </Button>
                    </ButtonTextOutside>
                  ) : (
                    <Skeleton count={1} width={156} height={45} />
                  )}
                </Tooltip>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Card
            className={classNames([
              'fit-width fit-height',
              styles.cardOfSitesAndSharedSites
            ])}>
            <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
          </Card>
        </Row>
      </Grid>
    </div>
  );
};

export default Sites;

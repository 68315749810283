import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'src/components/ConfirmModal';

interface IConfirmDisableMethodCaptiveModal {
  showModalDisableMethod: boolean;
  isLoadingDisableMethod: boolean;
  onConfirmDisableMethod: () => void;
  onCancelDisableMethod: () => void;
}

const ConfirmDisableMethodCaptiveModal = ({
  showModalDisableMethod,
  isLoadingDisableMethod,
  onConfirmDisableMethod,
  onCancelDisableMethod
}: IConfirmDisableMethodCaptiveModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.activeMethodCaptiveButton'
  });

  return (
    <ConfirmModal
      showModal={showModalDisableMethod}
      isLoadingAction={isLoadingDisableMethod}
      width="570px"
      height="260px"
      onConfirm={onConfirmDisableMethod}
      onCancel={onCancelDisableMethod}>
      <span>
        {t(
          'Ao desativar o Captive, todas as wireless vinculadas deste ambiente retornarão à sua respectiva autenticação definida antes da ativação deste método, e os clientes serão desconectados da rede. Este processo poderá levar alguns minutos até ser concluído. Deseja continuar?'
        )}
      </span>
    </ConfirmModal>
  );
};

export { ConfirmDisableMethodCaptiveModal };

import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import {
  MdBroadcastOnPersonal,
  MdMoreHoriz,
  MdOutlineConnectedTv,
  MdRouter,
  MdWarning,
  MdWifi
} from 'react-icons/md';
import classNames from 'classnames';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import { Alert } from 'ui-components/Alert';
import { Breadcrumbs, Crumb } from 'ui-components/Breadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import DuoToneIcons from 'src/components/DuoToneIcons';
import Skeleton from 'react-loading-skeleton';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import {
  DevicesType,
  IPlacesResponse,
  ISiteResponseUnique
} from 'src/services/api/urls/sites/types';
import { useTemplate } from 'src/hooks/useTemplate';
import { ButtonComeBack } from 'src/components/ButtonComeBack';
import { Tooltip } from 'inmaster-ui';
import { IPlaceItemPage } from './types';
import styles from './Places.module.css';
import { ISelectedSiteData } from '../Sites/types';
import { ModalCreateOrEditPlace } from './ModalCreateOrEditPlace';
import { ModalRemovePlace } from './ModalRemovePlace';
import { IErrorResponse } from '../Sites';

export const renderSkeletonsOfPlaces = (numberOfSkeletons: number) => {
  const skeletons = [];
  for (let i = 0; i < numberOfSkeletons; i += 1) {
    skeletons.push(
      <Col xs={12} md={6} lg={4} xl={4} key={`skeletom-place${i}`}>
        <div className="ma-2">
          <Skeleton width="100%" height={195} />
        </div>
      </Col>
    );
  }
  return skeletons;
};

const Places = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'site.place' });

  const { setAccessToken } = useAuth();

  const [showModalDeletePlace, setShowModalDeletePlace] = useState(false);

  const [currentDeletingPlace, setCurrentDeletingPlace] =
    useState<IPlaceItemPage>({} as IPlaceItemPage);

  const [showModalCreateOrEditPlace, setShowModalCreateOrEditPlace] =
    useState(false);

  const [canCreatePlace, setCanCreatePlace] = useState(true);

  const [createOrEditModalType, setCreateOrEditModalType] = useState<
    'create' | 'edit'
  >('create');

  const [idOfPlaceToEdit, setIdOfPlaceToEdit] = useState('');

  const [namePlaceToEdit, setNamePlaceToEdit] = useState('');

  const [isAddingPlace, setIsAddingPlace] = useState(false);

  const [selectedSite, setSelectedSite] = useState<ISelectedSiteData>(
    {} as ISelectedSiteData
  );

  const [selectedPlace, setSelectedPlace] = useState<IPlaceItemPage>(
    {} as IPlaceItemPage
  );

  const [places, setPlaces] = useState<IPlaceItemPage[]>([]);

  const [placeIdBeingEditedOrDeleted, setPlaceIdBeingEditedOrDeleted] =
    useState<string | null>(null);

  const navigate = useNavigate();
  const { id: idSite } = useParams();

  useEffect(() => {
    if (places.length < 32) {
      setCanCreatePlace(true);
    } else {
      setCanCreatePlace(false);
    }
  }, [places]);

  const getSelectedSite = () => {
    return api.sites.get(idSite);
  };

  const { refetch: refetchSites, isFetched: isFetchedSite } = useQuery(
    ['selectedSiteQuery', idSite],
    getSelectedSite,
    {
      cacheTime: 0,
      onSuccess: ({ data }: { data: ISiteResponseUnique }) => {
        setSelectedSite({
          id: data.site.id,
          name: data.site.name,
          member: data.member
        } as ISelectedSiteData);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/error/not_found');
        }
      }
    }
  );

  const chooseNewSiteMemberStatus = useMutation(
    (data: { is_new_site_member: boolean }) => {
      return api.sites.members.patchNewSiteStatus(
        selectedSite.id,
        selectedSite.member?.id ?? '',
        data
      );
    },
    {
      onSuccess: async () => {
        refetchSites();
      }
    }
  );

  const getAllPlaces = () => {
    return api.sites.hefesto.places.get(selectedSite.id);
  };

  const { refetch: refetchListPlaces, isFetched } = useQuery(
    ['placesListQuery', idSite],
    getAllPlaces,
    {
      cacheTime: 0,
      enabled: isFetchedSite,
      onSuccess: ({ data }: { data: IPlacesResponse }) => {
        setPlaces(data.place_list);
        if (isAddingPlace) {
          setIsAddingPlace(false);
        }
        if (placeIdBeingEditedOrDeleted) {
          setPlaceIdBeingEditedOrDeleted(null);
        }
        if (selectedSite.member?.is_new_site_member) {
          chooseNewSiteMemberStatus.mutate({
            is_new_site_member: false
          });
        }
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/error/not_found');
        }
      }
    }
  );

  const choosePlace = useMutation(
    (data: { place_id: string }) => {
      return api.auth.put({
        place_id: data.place_id
      });
    },
    {
      onSuccess: (data) => {
        setAccessToken(data.data.access_token_data);
        if (selectedPlace.device_count === 0) {
          navigate('/first-device');
        } else {
          navigate('/devices');
        }
      }
    }
  );

  useEffect(() => {
    if (selectedPlace.id) {
      choosePlace.mutate({ place_id: selectedPlace.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  const handleEditModal = (place: IPlaceItemPage) => {
    setCreateOrEditModalType('edit');
    setIdOfPlaceToEdit(place.id);
    setNamePlaceToEdit(place.name);
    setShowModalCreateOrEditPlace(true);
  };

  const comeBackSites = () => {
    navigate('/sites', { replace: true });
  };

  const getAlertMessage = (place: IPlaceItemPage) => {
    if (place.configuration?.error_adoption)
      return t('Ocorreu um erro na adição dos equipamentos');
    if (place.configuration?.error_configuration)
      return t('Ocorreu um erro na configuração dos equipamentos');

    return '';
  };

  const setShowMenuOfPlace = (index: number, value: boolean) => {
    setPlaces((prev) => {
      const aux = [...prev];
      aux[index].showMenu = value;
      return aux;
    });
  };

  const openDeletingModal = (place: IPlaceItemPage, index: number) => {
    setCurrentDeletingPlace(place);
    setShowMenuOfPlace(index, false);
    setShowModalDeletePlace(true);
  };

  const onDeletingPlace = () => {
    refetchListPlaces();
  };

  const onEditingPlace = () => {
    refetchListPlaces();
  };

  const onCreatingPlace = () => {
    refetchListPlaces();
  };

  useTemplate('avatarNavbar');

  const checkIfValueIsValid = (
    value: string | number | boolean | undefined
  ) => {
    if (value === undefined || value === null || value === '') {
      return false;
    }
    return true;
  };

  const renderWarningIconIfValueIsInvalid = (
    value: string | number | boolean | undefined
  ) => {
    if (!checkIfValueIsValid(value)) {
      return (
        <Tooltip
          content={t('Falha na obtenção dos dados')}
          place="right"
          id="tooltip-warning">
          <MdWarning
            style={{ color: 'var(--color-status-alert-base)' }}
            size={20}
          />
        </Tooltip>
      );
    }
    return value;
  };

  const checkValueCaptivePortal = (value: boolean | undefined) => {
    if (!checkIfValueIsValid(value)) {
      return renderWarningIconIfValueIsInvalid(value);
    }

    if (value) {
      return t('Ativo');
    }
    return t('Inativo');
  };

  return (
    <div className={styles.shellPlaces}>
      <ModalRemovePlace
        currentDeletingPlace={currentDeletingPlace}
        setCurrentDeletingPlace={setCurrentDeletingPlace}
        showModalDeletePlace={showModalDeletePlace}
        setShowModalDeletePlace={setShowModalDeletePlace}
        selectedSite={selectedSite}
        onDeletingPlace={onDeletingPlace}
        setPlaceIdBeingEditedOrDeleted={setPlaceIdBeingEditedOrDeleted}
        setPlaces={setPlaces}
      />

      <ModalCreateOrEditPlace
        createOrEditModalType={createOrEditModalType}
        showModalCreateOrEditPlace={showModalCreateOrEditPlace}
        setCreateOrEditModalType={setCreateOrEditModalType}
        idOfPlaceToEdit={idOfPlaceToEdit}
        setIdOfPlaceToEdit={setIdOfPlaceToEdit}
        selectedSite={selectedSite}
        namePlace={namePlaceToEdit}
        setIsAddingPlace={setIsAddingPlace}
        setShowModalCreateOrEditPlace={setShowModalCreateOrEditPlace}
        onCreatingPlace={onCreatingPlace}
        onEditingPlace={onEditingPlace}
        setPlaceIdBeingEditedOrDeleted={setPlaceIdBeingEditedOrDeleted}
        setPlaces={setPlaces}
      />

      <Grid fluid>
        {isFetchedSite ? (
          <Breadcrumbs className="mb-6 ml-2">
            <Crumb>
              <div className="d-flex justify-start fit-width align-center">
                <ButtonComeBack onClick={() => comeBackSites()} />
                <Link to="/sites">
                  {[RoleMember.GUEST, RoleMember.MANAGER].includes(
                    selectedSite?.member?.role as RoleMember
                  )
                    ? t('Compartilhados comigo')
                    : t('Meus locais')}
                </Link>
              </div>
            </Crumb>
            <Crumb>{selectedSite.name}</Crumb>
          </Breadcrumbs>
        ) : (
          <div className="ml-2 mb-5">
            <Skeleton width={200} height={24} />
          </div>
        )}
        <div className={classNames([styles.bodyPlaces, 'py-3 px-2'])}>
          <Row className="mb-2">
            <Col xs={12}>
              <div className="d-flex justify-between align-center">
                {isFetchedSite ? (
                  <h1
                    className={classNames(['title-sm-lg ml-2', styles.title])}
                    id="place-title-page">
                    {t('Ambientes')} - {selectedSite.name}
                  </h1>
                ) : (
                  <div className="ml-2">
                    <Skeleton width={280} height={35} />
                  </div>
                )}
                {isFetched ? (
                  <Tooltip
                    content={t('Limite de criação de 32 ambientes atingido')}
                    style={{ width: 190 }}
                    place="bottom"
                    id="tooltip-cant-create-place"
                    disabled={canCreatePlace}>
                    <Button
                      className={styles.createPlaceButton}
                      onClick={() => setShowModalCreateOrEditPlace(true)}
                      id="btn-create-place"
                      disabled={!canCreatePlace}
                      outline
                      width={300}>
                      {t('CRIAR AMBIENTE')}
                    </Button>
                  </Tooltip>
                ) : (
                  <div className="mr-2">
                    <Skeleton width={280} height={45} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className={classNames(['d-flex flex-wrap'])}>
            {isFetched ? (
              <>
                {places.map((place, index) => (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                    key={place.id}
                    className="mb-3">
                    <Card
                      hoverable
                      focusable
                      clickable
                      shadow="3xl"
                      className={classNames([
                        'ma-2 fit-height',
                        styles.cardPlace
                      ])}
                      borderColor="var(--color-brand-secondary-light)">
                      <div
                        className={classNames([
                          'd-flex justify-end pt-4 pr-4',
                          styles.menuContainer
                        ])}>
                        <Menu
                          menuId={`menu-place-actions-${place.name}`}
                          items={
                            <>
                              <MenuItem
                                disabled={
                                  placeIdBeingEditedOrDeleted === place.id
                                }
                                onClick={() => handleEditModal(place)}
                                aria-hidden="true"
                                id={`btn-edit-place-${place.name}`}>
                                {t('Editar ambiente')}
                              </MenuItem>
                              {places.length === 1 ? null : (
                                <MenuItem
                                  disabled={
                                    placeIdBeingEditedOrDeleted === place.id
                                  }
                                  onClick={() =>
                                    openDeletingModal(place, index)
                                  }
                                  aria-hidden="true"
                                  id={`btn-delete-place-${place.name}`}>
                                  <span
                                    style={{
                                      color: 'var(--color-status-critical-base)'
                                    }}>
                                    {t('Excluir ambiente')}
                                  </span>
                                </MenuItem>
                              )}
                            </>
                          }
                          positionX="end"
                          width="150px"
                          onClose={() => setShowMenuOfPlace(index, false)}
                          show={place.showMenu}>
                          <div
                            id={`btn-menu-place-actions-${place.name}`}
                            className={styles.menuActions}
                            aria-hidden="true"
                            onClick={() => setShowMenuOfPlace(index, true)}>
                            <MdMoreHoriz size={24} />
                          </div>
                        </Menu>
                      </div>

                      <div className="d-flex fit-width">
                        <div
                          id={`place-${place.name}`}
                          className="d-flex flex-column fit-width px-4 pt-4"
                          onClick={() => {
                            setSelectedPlace(place);
                          }}
                          aria-hidden>
                          <div className="d-flex align-center mb-4">
                            <span>
                              {place.devices_type === DevicesType.CFTV && (
                                <Tooltip
                                  style={{ width: 275 }}
                                  place="bottom-start"
                                  id="tooltip-wom-cftv"
                                  content={t(
                                    'Para o correto funcionamento, apenas outros WOM podem ser adicionados a este ambiente'
                                  )}>
                                  <MdOutlineConnectedTv size={24} />
                                </Tooltip>
                              )}
                            </span>
                            <span
                              className="title-sm-base ml-1"
                              id={`place-name-${place.name}`}>
                              {place.name}
                            </span>
                          </div>

                          <div className="d-flex justify-between">
                            <DuoToneIcons
                              value={renderWarningIconIfValueIsInvalid(
                                place.device_count
                              )}
                              title={t('Equipamentos')}
                              colorIcon="var(--color-brand-primary-base)"
                              icon={<MdRouter size={25} />}
                            />

                            {place.devices_type !== DevicesType.CFTV && (
                              <DuoToneIcons
                                value={renderWarningIconIfValueIsInvalid(
                                  place.wireless_count
                                )}
                                title={t('Wireless')}
                                colorIcon="var(--color-brand-primary-base)"
                                icon={<MdWifi size={24} />}
                                className="pr-6"
                              />
                            )}
                          </div>

                          <div
                            className={classNames(
                              'd-flex',
                              'fit-width',
                              'mt-3'
                            )}>
                            {place.devices_type !== DevicesType.CFTV && (
                              <DuoToneIcons
                                value={checkValueCaptivePortal(
                                  place.is_captive_portal_enabled
                                )}
                                title={t('Captive Portal')}
                                colorIcon="var(--color-brand-primary-base)"
                                icon={<MdBroadcastOnPersonal size={24} />}
                                className="pr-6"
                              />
                            )}
                          </div>

                          <div
                            className={`mt-3 ${
                              place.configuration?.error_adoption ||
                              place.configuration?.error_configuration
                                ? ''
                                : styles.hiddenAlert
                            }`}
                            id={`place-alert-${place.name}`}>
                            <Alert type="warning">
                              {getAlertMessage(place)}
                            </Alert>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
                {isAddingPlace && (
                  <Col xs={12} md={6} lg={4} xl={4} className="">
                    <div className="mt-1 ml-2 mr-2">
                      <Skeleton width="100%" height={209} />
                    </div>
                  </Col>
                )}
              </>
            ) : (
              renderSkeletonsOfPlaces(6)
            )}
          </Row>
        </div>
      </Grid>
    </div>
  );
};

export { Places };

import React from 'react';
import Modal from 'ui-components/Modal';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmModal.module.css';

interface IConfirmModal {
  children: React.ReactNode;
  showModal: boolean;
  isLoadingAction: boolean;
  width?: string;
  height?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal = ({
  children,
  showModal,
  isLoadingAction,
  width,
  height,
  onConfirm,
  onCancel
}: IConfirmModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'confirmModal'
  });

  const modalActions = [
    {
      label: t('Não'),
      action: onCancel,
      disabled: isLoadingAction
    },
    {
      label: t('Sim'),
      action: onConfirm,
      isLoading: isLoadingAction
    }
  ];
  return (
    <Modal
      actions={modalActions}
      show={showModal}
      height={height || '190px'}
      width={width || '465px'}
      className={styles.container}>
      <div>{children}</div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  width: '465px',
  height: '190px'
};

export { ConfirmModal };

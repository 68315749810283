import React, { useEffect, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import styles from './Sidebar.module.css';
import logoImg from 'src/assets/logo-intelbras-verde.svg';
import SidebarAction from './SidebarAction/SidebarAction';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
function Sidebar({
  onClose = () => {},
  onOpen = () => {},
  sidebarShouldStartMinimized,
  actions = [],
  isLoading = false,
  fixed = false
}) {
  // States
  const [minimized, setMinimized] = useState(sidebarShouldStartMinimized);

  // Functions
  const toggleSidebarSize = () => {
    document.activeElement.blur();
    setMinimized((prev) => !prev);
  };

  // Effects
  useEffect(() => {
    if (minimized) {
      onClose();
    } else {
      onOpen();
    }
  }, [minimized]);

  useEffect(() => {
    if (sidebarShouldStartMinimized) {
      setMinimized(true);
    }
  }, []);

  return (
    <div
      id={minimized ? 'sidebar-minimized' : 'sidebar-full'}
      className={classNames([
        styles.sidebar,
        fixed ? styles.sidebarFixed : null,
        minimized ? styles.sidebarMinimized : null
      ])}>
      <div className={styles.sidebarHeaderBackground}>
        <div className={styles.shellOpenOrCloseButton}>
          <div
            id="open-or-close-sidebar-btn"
            className={styles.openOrCloseSidebarButton}
            onClick={() => toggleSidebarSize()}
            aria-hidden="true">
            <MdMenu />
          </div>
        </div>
        <div
          className={classNames([
            styles.sidebarHeader,
            minimized ? styles.sidebarHeaderMinimized : null
          ])}>
          <img src={logoImg} className={styles.logo} />
        </div>
      </div>
      <div className={styles.sidebarActions}>
        {isLoading ? (
          <div>
            <div className={classNames([styles.skeletonSideBarAction])}>
              <Skeleton height={22} baseColor="transparent" />
            </div>
            <div className={classNames([styles.skeletonSideBarAction])}>
              <Skeleton height={22} baseColor="transparent" />
            </div>
            <div className={classNames([styles.skeletonSideBarAction])}>
              <Skeleton height={22} baseColor="transparent" />
            </div>
            <div className={classNames([styles.skeletonSideBarAction])}>
              <Skeleton height={22} baseColor="transparent" />
            </div>
          </div>
        ) : (
          actions.map((action) => (
            <SidebarAction
              key={action.label}
              minimized={minimized}
              label={action.label}
              icon={action.icon}
              items={action.items}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Sidebar;

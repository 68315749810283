import React, { ReactNode } from 'react';
import { Navbar } from 'src/components/Navbar';
import { INavbarActions } from 'src/components/Navbar/types';

import styles from './NavbarTemplate.module.css';

interface ITemplateProps {
  children: ReactNode;
  navbarActions?: INavbarActions;
}
const NavbarTemplate = ({ children, navbarActions }: ITemplateProps) => {
  return (
    <div className={`${styles.container}`} id="view">
      <Navbar logo actions={navbarActions} fixed />
      {children}
    </div>
  );
};

NavbarTemplate.defaultProps = {
  navbarActions: {
    config: false,
    notification: false,
    avatar: false
  }
};

export { NavbarTemplate };

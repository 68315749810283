import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';
import logoAccountImg from 'src/assets/logo-conta-intelbras.svg';
import loginImg from 'src/assets/login.svg';
import { Button } from 'ui-components/Button';
import { Divider } from 'src/components/Divider';
import { useAuth } from 'src/hooks/useAuth';
import { useTemplate } from 'src/hooks/useTemplate';
import { eventGA } from 'src/utils/analytics';
import downloadGoogleImg from 'src/assets/download-app-google.svg';
import downloadAppleImg from 'src/assets/download-app-apple.svg';
import styles from './Login.module.css';

const Login = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'login' });
  const { login, accessToken } = useAuth();
  useTemplate('multiLanguageNavbar');

  if (accessToken) {
    return <Navigate to="/" replace />;
  }

  const storeLinks = [
    {
      link: 'https://play.google.com/store/apps/details?id=br.com.intelbras.inmaster.prod',
      image: downloadGoogleImg,
      alt: t('Download na Google Play Store')
    },
    {
      link: 'https://apps.apple.com/br/app/inmaster-intelbras/id6449433565',
      image: downloadAppleImg,
      alt: t('Download na Apple Store')
    }
  ];

  return (
    <Grid fluid>
      <Row className={styles.container}>
        <Col lg={4} className={styles.section}>
          <div />

          <div className={styles.content}>
            <h1 className={classNames([styles.welcomeText, 'title-xl-xl'])}>
              {t('Bem vindo ao InMaster!')}
            </h1>
            <p className={classNames([styles.description, 'text-xl-lg'])}>
              {t('Seu software de gerenciamento centralizado de equipamentos')}
            </p>

            <Divider />

            <div
              className={classNames([
                styles.actions,
                'd-flex flex-column align-center'
              ])}>
              <span className="text-xl-base text-uppercase">
                {t('Entrar com')}
              </span>
              <Button
                className="fit-width"
                id="button-login"
                onClick={() => {
                  eventGA('Usuário', 'Click', 'Login');
                  login();
                }}>
                <img
                  src={logoAccountImg}
                  alt={t('Conta Intelbras')}
                  width={190}
                />
              </Button>

              <div className={styles.actionsWithText}>
                <p className="text-xl-base">
                  {t('Não tem Conta Intelbras?')}
                  <span
                    id="link-register"
                    className={styles.accountLink}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      eventGA('Usuário', 'Click', 'Criar conta');
                      login();
                    }}
                    onKeyDown={({ key }) => (key === 'Enter' ? login() : null)}>
                    {t('Cadastre-se aqui')}
                  </span>
                </p>
                <p className="text-xl-base">
                  {t('Já tem cadastro?')}
                  <a
                    id="link-conta-unica"
                    href="https://portal.conta.intelbras.com/"
                    target="_blank"
                    className={styles.accountLink}
                    tabIndex={0}
                    onClick={() => {
                      eventGA(
                        'Usuário',
                        'Click',
                        'Gerenciar conta no conta única'
                      );
                    }}
                    rel="noreferrer">
                    {t('Clique aqui')}
                  </a>{' '}
                  {t('para gerenciar sua Conta Intelbras')}
                </p>
              </div>
            </div>
          </div>

          <Row
            middle="xs"
            center="xs"
            className={classNames([styles.footer, 'text-sm-xs'])}
          />
        </Col>
        <Col lg={8} className={styles.illustration}>
          <img src={loginImg} alt={t('Ilustração de login')} width="70%" />

          <div className={classNames(['d-flex align-start', styles.links])}>
            {storeLinks.map(({ link, image, alt }) => (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <img src={image} alt={alt} className={styles.imageLink} />
              </a>
            ))}
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export { Login };

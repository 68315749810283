import classNames from 'classnames';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonComeBack } from 'src/components/ButtonComeBack';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useTemplate } from 'src/hooks/useTemplate';
import { Breadcrumbs, Crumb } from 'ui-components/Breadcrumbs';
import Checkbox from 'ui-components/Checkbox';
import Label from 'ui-components/Label';
import { ActiveTabSitesEnum } from '../Sites/types';
import styles from './Informations.module.css';
import InfoModalWithIframe from '../../components/InfoModalWithIframe';

const Informations = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'informations'
  });

  const navigate = useNavigate();

  const [activeTabSiteIndex] = useLocalStorage('@manager/activeTabSite');

  const [showInfoModalWithIframe, setShowInfoModalWithIframe] = useState(false);
  const [activeIframe, setActiveIframe] = useState(0);

  const comeBackSites = () => {
    navigate('/sites');
  };

  useTemplate('avatarNavbar');

  const iframeList = [
    {
      title: t('Termos de uso Intelbras'),
      href: process.env.REACT_APP_TERMO_INTELBRAS_TERMS
    },
    {
      title: t('Política de Privacidade Intelbras'),
      href: process.env.REACT_APP_TERMO_INTELBRAS_PRIVACY_POLICY
    }
  ];

  return (
    <div className={classNames([styles.shellInformations])}>
      <InfoModalWithIframe
        show={showInfoModalWithIframe}
        onClose={() => setShowInfoModalWithIframe(false)}
        title={iframeList[activeIframe].title}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        href={iframeList[activeIframe].href!}
      />

      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Breadcrumbs className="mb-6 ml-2">
              <Crumb>
                <div className="d-flex justify-start fit-width align-center">
                  <ButtonComeBack onClick={() => comeBackSites()} />

                  <Link to="/sites">
                    {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      activeTabSiteIndex === ActiveTabSitesEnum.SHARED_WITH_ME
                        ? t('Compartilhados comigo')
                        : t('Meus locais')
                    }
                  </Link>
                </div>
              </Crumb>
              <Crumb>{t('Informações')}</Crumb>
            </Breadcrumbs>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pl-2 pr- py-7 pr-11">
            <h3 className="title-sm-lg">{t('Informações')}</h3>
            <br />
            <p className="text-xl-lg">
              {t(
                'O InMaster é uma solução cloud de gerenciamento de equipamentos de redes da Intelbras que possibilita a gestão segura, centralizada e escalável das suas redes de forma remota.'
              )}
            </p>
            <br />
            <p className="text-xl-lg">
              {t(
                'Você pode gerenciar seus diferentes locais de instalação (multi-site), organizando seus equipamentos em ambientes e compartilhando seus locais para acesso de outros membros, além de configurar os principais atributos da sua rede em poucos minutos.'
              )}
            </p>
            <br />
            <p className="text-xl-lg">
              {t(
                'Aproveite também e faça o download do nosso aplicativo na loja de sua preferência (disponível na Google Play e Apple Store) e amplie ainda mais seus benefícios.'
              )}
            </p>
            <br />
            <div className="d-flex ">
              <Checkbox checked disabled />
              <Label className="ml-3">
                {t('Concordo com os')}{' '}
                <span
                  id="link-terms"
                  className={styles.emphasisText}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setActiveIframe(0);
                    setShowInfoModalWithIframe(true);
                  }}
                  onKeyDown={({ key }) =>
                    key === 'Enter'
                      ? () => setShowInfoModalWithIframe(true)
                      : null
                  }>
                  {t('Termos de uso')}
                </span>
              </Label>
            </div>
            <br />
            <div className="d-flex ">
              <Checkbox checked disabled />
              <Label className="ml-3">
                {t('Concordo com a')}{' '}
                <span
                  id="link-politics"
                  className={styles.emphasisText}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setActiveIframe(1);
                    setShowInfoModalWithIframe(true);
                  }}
                  onKeyDown={({ key }) =>
                    key === 'Enter'
                      ? () => setShowInfoModalWithIframe(true)
                      : null
                  }>
                  {t('Política de privacidade')}
                </span>
              </Label>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export { Informations };

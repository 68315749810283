import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'src/components/ConfirmModal';

interface IConfirmChangeMethodCaptiveModal {
  showModalConfirmChange: boolean;
  isLoadingConfirmChange: boolean;
  onConfirmChange: () => void;
  onCancelChange: () => void;
}

const ConfirmChangeMethodCaptiveModal = ({
  showModalConfirmChange,
  isLoadingConfirmChange,
  onConfirmChange,
  onCancelChange
}: IConfirmChangeMethodCaptiveModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.activeMethodCaptiveButton'
  });

  return (
    <ConfirmModal
      showModal={showModalConfirmChange}
      isLoadingAction={isLoadingConfirmChange}
      width="570px"
      height="240px"
      onConfirm={onConfirmChange}
      onCancel={onCancelChange}>
      <span>
        {t(
          'Ao ativar este método de Captive, a forma de autenticação das wireless deste ambiente serão alteradas, e os clientes serão desconectados dessas redes. Este processo poderá levar alguns minutos até ser concluído.  Deseja continuar?'
        )}
      </span>
    </ConfirmModal>
  );
};

export { ConfirmChangeMethodCaptiveModal };

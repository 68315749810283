import classNames from 'classnames';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { useTemplate } from 'src/hooks/useTemplate';
import Tabs from 'ui-components/Tab';
import api from 'src/services/api';
import { useQuery } from 'react-query';
import { IGetCaptivePortal } from 'src/services/api/urls/captivePortal/types';
import { useAuth } from 'src/hooks/useAuth';
import { MdInfo } from 'react-icons/md';
import styles from './CaptivePortal.module.css';
import { PersonalizeCaptivePortal } from './PersonalizeCaptivePortal';
import { SetUpMethod } from './SetUpMethod';
import { IMethodTypeName, IMethodsTypes } from './SetUpMethod/types';
import AssociatedWireless from './AssociatedWireless';
import { ICaptivePortal } from './types';
import { CaptiveInfoModal } from './CaptiveInfoModal';

const CaptivePortal = () => {
  useTemplate('menuAndFullNavbar');

  const METHOD_TAB = 0;

  const PAGE_CAPTIVE_TAB = 1;

  const WIRELESS_ASSOCIATED_TAB = 2;

  const { accessToken } = useAuth();

  const { t } = useTranslation('translations', { keyPrefix: 'captivePortal' });

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const [defaultCaptivePortal, setDefaultCaptivePortal] =
    React.useState<ICaptivePortal>({} as ICaptivePortal);

  const [isLoadingFormCaptive, setIsLoadingFormCaptive] = React.useState(false);

  const [showCaptiveInfoModal, setShowCaptiveInfoModal] = React.useState(false);

  const [methodsTypes, setMethodsTypes] = React.useState<IMethodsTypes>({
    password: {
      nameType: t('Senha Simples'),
      statusMethod: false
    },
    cpf: {
      nameType: t('Cadastro Pessoa Física'),
      statusMethod: false
    },
    individual: {
      nameType: t('Cadastro Individual'),
      statusMethod: false
    },
    voucher: {
      nameType: t('Voucher'),
      statusMethod: false
    }
  });

  const getCaptivePortal = () => {
    return api.captivePortal.captive.getOrCreate(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const {
    isFetchedAfterMount: isFetchedCaptivePortal,
    refetch: refetchCaptivePortal
  } = useQuery('getCaptivePortal', getCaptivePortal, {
    onSuccess: ({ data }: { data: IGetCaptivePortal }) => {
      if (data) {
        setDefaultCaptivePortal({
          ...data.captive_portal,
          is_blocked_to_activate: data.is_blocked_to_activate
        } as ICaptivePortal);

        setMethodsTypes((previousValue) => {
          if (data.captive_portal && data.captive_portal.mode) {
            previousValue[data.captive_portal.mode as IMethodTypeName] = {
              ...previousValue[data.captive_portal.mode as IMethodTypeName],
              statusMethod: true
            };
          }

          return {
            ...previousValue
          };
        });
      } else {
        setDefaultCaptivePortal({} as ICaptivePortal);
      }
    },
    onError: () => {
      setDefaultCaptivePortal({} as ICaptivePortal);
    }
  });

  const tabsOptionsCaptivePortal = [
    {
      label: t('Configurar método'),
      component: (
        <SetUpMethod
          methodsTypes={methodsTypes}
          defaultCaptivePortal={defaultCaptivePortal}
          isFetchedCaptivePortal={isFetchedCaptivePortal}
          isLoadingFormCaptive={isLoadingFormCaptive}
        />
      ),
      onClick: () => setActiveTabIndex(METHOD_TAB)
    },
    {
      label: t('Página do Captive'),
      disabled: !defaultCaptivePortal.mode,
      tooltip: !defaultCaptivePortal.mode
        ? t('Ative o Captive para personalizar a página')
        : t('Página para personalização do Captive'),
      component: (
        <PersonalizeCaptivePortal
          defaultCaptivePortal={defaultCaptivePortal}
          methodsTypes={methodsTypes}
          isFetchedCaptivePortal={isFetchedCaptivePortal}
          isLoadingFormCaptive={isLoadingFormCaptive}
          setActiveTab={setActiveTabIndex}
          setIsLoadingFormCaptive={setIsLoadingFormCaptive}
          refetchCaptivePortal={refetchCaptivePortal}
        />
      ),
      onClick: () => setActiveTabIndex(PAGE_CAPTIVE_TAB)
    },
    {
      label: t('Wireless vinculadas'),
      disabled: !defaultCaptivePortal.mode,
      tooltip: !defaultCaptivePortal.mode
        ? t('Ative o Captive para gerenciar as wireless vinculadas')
        : t('Gerencie as wireless vinculadas ao Captive'),
      component: (
        <AssociatedWireless
          defaultCaptivePortal={defaultCaptivePortal}
          isFetchedCaptivePortal={isFetchedCaptivePortal}
          setActiveTab={setActiveTabIndex}
        />
      ),
      onClick: () => setActiveTabIndex(WIRELESS_ASSOCIATED_TAB)
    }
  ];

  const handleOpenCaptiveInfoModal = () => {
    setShowCaptiveInfoModal(true);
  };

  return (
    <Grid fluid>
      <CaptiveInfoModal
        setShowModal={setShowCaptiveInfoModal}
        showModal={showCaptiveInfoModal}
      />
      <Row>
        <Col xs={12} className="mb-7 d-flex flex-column">
          <div>
            <h2 className="d-flex">
              <span
                className={classNames([
                  'title-xl-base text-uppercase',
                  styles.titlePage
                ])}>
                {t('CAPTIVE PORTAL - INTELBRAS')}
              </span>
              <span
                className={classNames([
                  'd-flex align-center ml-2',
                  styles.iconInfo
                ])}
                aria-hidden="true"
                onClick={handleOpenCaptiveInfoModal}>
                <MdInfo size={24} />
              </span>
            </h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Tabs
            tabs={tabsOptionsCaptivePortal}
            activeTabIndex={activeTabIndex}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export { CaptivePortal };

import classNames from 'classnames';
import React from 'react';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import Label from 'ui-components/Label';
import Toggle from 'ui-components/Toggle';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { isUrl } from 'src/utils/regexUtils';
import styles from './FormForPreviewCaptive.module.css';
import { IPersonalizeForm } from '../PersonalizeCaptivePortal/types';
import { SimplePasswordForm } from './SimplePasswordForm';
import { IndividualRegisterForm } from './IndividualRegisterForm';
import { VoucherForm } from './VoucherForm';
import { CPFRecordForm } from './CPFRecordForm';

interface IFormForPreviewCaptive {
  isFetchedCaptivePortal: boolean;
  viewFormData: IPersonalizeForm | null;
  methodCaptive: string | null;
}
const FormForPreviewCaptive = ({
  isFetchedCaptivePortal,
  viewFormData,
  methodCaptive
}: IFormForPreviewCaptive) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.formForPreviewCaptive'
  });

  const getImageWithoutCache = () => {
    let image = viewFormData!.captivePortal.header_image_url;
    const imageIsUrl = isUrl(viewFormData!.captivePortal.header_image_url);

    if (imageIsUrl) {
      image = `${
        viewFormData!.captivePortal.header_image_url
      }?${Math.random()}`;
    }

    return image;
  };

  const renderFormForMethodType = () => {
    switch (methodCaptive) {
      case 'password':
        return <SimplePasswordForm />;
      case 'individual':
        return <IndividualRegisterForm />;
      case 'voucher':
        return <VoucherForm />;
      case 'cpf':
        return <CPFRecordForm />;
      default:
        return <div />;
    }
  };

  const renderSkeleton = () => {
    return (
      <div
        className={classNames([
          'd-flex',
          'flex-column',
          'pt-4',
          'align-center',
          viewFormData?.captivePortal.header_image_url &&
            styles.cardContentWithImage,
          'fit-width'
        ])}>
        <div className="fit-width" style={{ maxWidth: '510px' }}>
          <Skeleton width="100%" height={150} />
        </div>

        <Card
          className={classNames([
            'fit-width px-11 pb-8 pt-8',
            styles.cardBody,
            styles.cardBodyWithImage
          ])}
          borderColor="var(--color-neutral-light-2)">
          <div className="mb-2">
            <Skeleton width="100%" height={30} />
          </div>
          <div className="mb-4">
            <Skeleton width="100%" height={20} />
          </div>
          <div>
            <Skeleton width="100%" height={50} />
          </div>

          <div className="d-flex mt-4">
            <Skeleton width="100%" height={30} />
          </div>

          <div className="fit-width d-flex align-center justify-center mt-7">
            <Skeleton width={100} height={40} />
          </div>
        </Card>
      </div>
    );
  };

  if (!isFetchedCaptivePortal) {
    return renderSkeleton();
  }
  return (
    <div
      className={classNames([
        'd-flex',
        'flex-column',
        'pt-4',
        'align-center',
        viewFormData?.captivePortal.header_image_url &&
          styles.cardContentWithImage,
        'fit-width'
      ])}>
      {viewFormData?.captivePortal.header_image_url && (
        <img
          src={getImageWithoutCache()}
          alt={t('Imagem do formulário')}
          className={classNames(['fit-width', styles.logo])}
        />
      )}
      <Card
        className={classNames([
          `fit-width px-11 pb-8 ${
            viewFormData?.captivePortal.header_image_url ? 'pt-8' : 'pt-10'
          }`,
          styles.cardBody,
          viewFormData?.captivePortal.header_image_url &&
            styles.cardBodyWithImage
        ])}
        borderColor="var(--color-neutral-light-2)">
        <div
          className="d-flex flex-column align-center"
          style={{ color: 'var(--color-neutral-light-9)' }}>
          <span className="title-xl-base fit-width text-center">
            {viewFormData?.captivePortal.pageTitle}
          </span>
          <span className="text-sm-base fit-width text-center">
            {viewFormData?.captivePortal.pageText}
          </span>
        </div>

        <div className="mt-8">{renderFormForMethodType()}</div>

        <div className="d-flex mt-4">
          <Toggle
            color="var(--color-status-ok-base)"
            checked
            id="toggle-terms"
          />
          <Label className="ml-4">
            {t('Concordo com os')}{' '}
            <span
              id="link-terms"
              className={styles.emphasisText}
              role="button"
              tabIndex={0}>
              {t('Termos de uso')}
            </span>
          </Label>
        </div>

        <Button
          className="fit-width d-flex align-center justify-center mt-7"
          id="button-submit">
          {t('ENTRAR')}
        </Button>
      </Card>
    </div>
  );
};

export { FormForPreviewCaptive };

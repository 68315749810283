import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useTemplate } from 'src/hooks/useTemplate';

import { useToast } from 'src/hooks/useToast';

import api from 'src/services/api';
import Card from 'ui-components/Card';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRadioData } from 'src/services/api/urls/devices/types';
import { useNotification } from 'src/hooks/useNotification';

import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { eventGA } from 'src/utils/analytics';
import {
  IDeviceOnScreen,
  IErrorResponse,
  IFrequencyFormat,
  IResumedWireless,
  ISecurityFormat,
  IUpdateDeviceWireless
} from './types';
import Tabs from '../../../libs/conecte-se/ui-components/Tab/index';
import RadioSettings from './Radio/RadioSettings';
import AssociatedWirelessModal from './AssociatedWireless/Modal';
import AssociatedWirelessList from './AssociatedWireless/List';
import { HeaderDeviceDetails } from '../HeaderDeviceDetails';
import { DeviceManager } from './DeviceManager';

const DeviceDetails = () => {
  const params = useParams();

  const { addToast } = useToast();

  const { triggerNotificationAnimationWithText } = useNotification();

  useTemplate('menuAndFullNavbar');

  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const [showWirelessModal, setShowWirelessModal] = useState(false);

  const [deviceOnScreen, setDeviceOnScreen] = useState({} as IDeviceOnScreen);

  const [selectedWireless, setSelectedWireless] = useState<IResumedWireless[]>(
    []
  );

  const [associatedWireless, setAssociatedWireless] = useState<
    IResumedWireless[]
  >([]);

  const [wirelessList, setWirelessList] = useState<IResumedWireless[]>([]);

  const [radios, setRadios] = useState<IRadioData[] | null>(null);

  const [radiosUpdatedData, setRadiosUpdatedData] = useState<
    IRadioData[] | null
  >(null);

  const getWireless = async () => {
    return api.wireless.getWirelessOrderByDevice(params.id as string);
  };

  const frequencyFormat: IFrequencyFormat = {
    2.4: '2.4 GHz',
    5: '5 GHz',
    dualband: '2.4GHz | 5GHz'
  };

  const securityFormat: ISecurityFormat = {
    Open: t('Aberta'),
    'WPA-PSK': 'WPA-PSK',
    'WPA2-PSK': 'WPA2-PSK'
  };

  const { refetch: refetchWireless } = useQuery(
    ['wirelessQuery', params.id],
    getWireless,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: { wireless: IResumedWireless[] } }) => {
        const wirelessListFormatted = data.wireless.map((wireless) => {
          wireless.frequency =
            frequencyFormat[wireless.frequency as keyof IFrequencyFormat];

          wireless.security = wireless.is_captive_portal_enabled
            ? t('Captive Portal')
            : securityFormat[wireless.security as keyof ISecurityFormat];

          return wireless;
        });

        setWirelessList(wirelessListFormatted);
      }
    }
  );

  const getDeviceOnScreen = () => {
    return api.devices.hefesto.details.get(params.id);
  };

  const {
    refetch: refetchDeviceInfo,
    isFetchedAfterMount: isFetchedAfterMountDeviceInfo
  } = useQuery(['deviceScreenQuery', params.id], getDeviceOnScreen, {
    refetchOnWindowFocus: false,
    onSuccess: ({ data }: { data: IDeviceOnScreen }) => {
      data = { ...data, device: { ...data.device } };
      setDeviceOnScreen(data);
      setSelectedWireless(data.wireless_list ? data.wireless_list : []);
      setAssociatedWireless(data.wireless_list ? data.wireless_list : []);
      setRadios(data.radio_list || []);

      setRadiosUpdatedData(data.radio_list || []);
    }
  });

  const setAndOrderNewWirelessList = ({
    wireless_list_add_ids,
    wireless_list_remove_ids
  }: IUpdateDeviceWireless) => {
    setAssociatedWireless((prev) => {
      const newAssociatedWireless = prev.filter((wireless) => {
        return !wireless_list_remove_ids.includes(wireless.id);
      });
      const newWirelessToAdd = selectedWireless.filter((wireless) => {
        return wireless_list_add_ids.some((id) => id === wireless.id);
      });
      return _.sortBy(
        [...newAssociatedWireless, ...newWirelessToAdd],
        ['name']
      );
    });
  };

  const updateDeviceMutation = useMutation(
    ({
      wireless_list_add_ids,
      wireless_list_remove_ids
    }: IUpdateDeviceWireless) => {
      return api.devices.details.put(params.id as string, {
        wireless_list_add_ids,
        wireless_list_remove_ids
      });
    },
    {
      onSuccess: (
        data,
        { wireless_list_add_ids, wireless_list_remove_ids }
      ) => {
        setAndOrderNewWirelessList({
          wireless_list_add_ids,
          wireless_list_remove_ids
        });
        if (associatedWireless.length > 0) {
          addToast('success', t('Redes Wireless editadas com sucesso'));
          eventGA('Equipamentos', 'Click', 'Adicionar mais wireless');
        } else if (selectedWireless.length === 1) {
          addToast('success', t('Rede Wireless associada com sucesso'));
          eventGA('Equipamentos', 'Click', 'Adicionar primeira wireless');
        } else {
          addToast('success', t('Redes Wireless associadas com sucesso'));
          eventGA('Equipamentos', 'Click', 'Adicionar mais wireless');
        }
        triggerNotificationAnimationWithText(t('CONFIGURANDO EQUIPAMENTO'));
        setShowWirelessModal(false);
        refetchWireless();
        refetchDeviceInfo();
        setShowWirelessModal(false);
      },
      onError: (error: IErrorResponse) => {
        setShowWirelessModal(false);
        refetchDeviceInfo();
        if (error.response.status === 304) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        }
      }
    }
  );

  const tabs = [
    {
      label: t('Wireless associadas'),
      component: (
        <AssociatedWirelessList
          deviceOnScreen={deviceOnScreen}
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
          deviceWirelessList={associatedWireless}
          openWirelessModal={() => setShowWirelessModal(true)}
        />
      )
    },
    {
      label: t('Configurações por rádio'),
      component: (
        <RadioSettings
          deviceOnScreen={deviceOnScreen}
          radios={radios}
          hasWirelessAssociated={associatedWireless.length > 0}
          radiosUpdatedData={radiosUpdatedData}
          setRadios={setRadios}
          setRadiosUpdatedData={setRadiosUpdatedData}
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
          refetchDeviceInfo={refetchDeviceInfo}
        />
      )
    },
    {
      label: t('Gerenciamento'),
      component: isFetchedAfterMountDeviceInfo ? (
        <DeviceManager
          deviceOnScreen={deviceOnScreen}
          refetchDeviceInfo={refetchDeviceInfo}
        />
      ) : (
        <Skeleton height={213} />
      )
    }
  ];

  const updateDevice = () => {
    const selectedWirelessListIds = selectedWireless.map(
      (wireless) => wireless.id
    );

    const associatedWirelessIds = associatedWireless.map(
      (wireless) => wireless.id
    );

    const wireless_list_add_ids = selectedWirelessListIds.filter((id) => {
      return !associatedWireless.some((wireless) => wireless.id === id);
    });

    const wireless_list_remove_ids = associatedWirelessIds.filter((id) => {
      return !selectedWirelessListIds.some((wireless) => wireless === id);
    });

    updateDeviceMutation.mutate({
      wireless_list_add_ids,
      wireless_list_remove_ids
    });
  };

  return (
    <Grid fluid>
      <AssociatedWirelessModal
        show={showWirelessModal}
        associatedWireless={associatedWireless}
        updateDeviceMutationIsLoading={updateDeviceMutation.isLoading}
        onClose={() => setShowWirelessModal(false)}
        setSelectedWireless={setSelectedWireless}
        updateDevice={updateDevice}
        wirelessList={wirelessList}
        selectedWireless={selectedWireless}
        deviceData={deviceOnScreen}
      />
      <Card className="pa-7">
        <HeaderDeviceDetails
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
          refetchDeviceInfo={refetchDeviceInfo}
          selectedDevice={deviceOnScreen}
          setUpdatedDevice={setDeviceOnScreen}
          hasConnectedClient
          hasRebootDevice
          hasUpdateFirmware
          hasWireless
        />
        <Row className="mt-8">
          <Col xs={12}>
            <Tabs tabs={tabs} />
          </Col>
        </Row>
      </Card>
    </Grid>
  );
};

export default DeviceDetails;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { eventGA } from 'src/utils/analytics';
import classNames from 'classnames';
import { generateColumns } from 'src/utils/tableUtils';
import Table from 'ui-components/Table';
import Tag from 'ui-components/Tags';
import _, { isNull } from 'lodash';
import api from 'src/services/api';
import { useMutation, useQuery } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import { IUpdateAssociatedWirelessCaptivePortal } from 'src/services/api/urls/wireless/types';
import { useNavigate } from 'react-router-dom';
import { useArrayCompare } from 'src/hooks/useFormCompare';
import { Button } from 'ui-components/Button';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useNotification } from 'src/hooks/useNotification';
import {
  ISecurityFormat,
  IResumedWireless,
  IResumedWirelessRow,
  IFrequencyFormat
} from './types';
import styles from './AssociatedWireless.module.css';
import { ICaptivePortal } from '../types';

interface IProps {
  defaultCaptivePortal: ICaptivePortal | null;
  isFetchedCaptivePortal: boolean;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const AssociatedWireless = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  setActiveTab
}: IProps) => {
  const METHOD_TAB = 0;

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.associatedWireless'
  });

  const { addToast } = useToast();

  const navigate = useNavigate();

  const frequencyFormat: IFrequencyFormat = {
    2.4: '2.4 GHz',
    5: '5 GHz',
    dualband: '2.4GHz | 5GHz'
  };

  const securityFormat: ISecurityFormat = {
    Open: t('Aberta'),
    'WPA-PSK': 'WPA-PSK',
    'WPA2-PSK': 'WPA2-PSK'
  };

  const { triggerNotificationAnimationWithText } = useNotification();

  const [associatedWireless, setAssociatedWireless] = useState<
    IResumedWireless[]
  >([]);

  const [selectedWireless, setSelectedWireless] = useState<IResumedWireless[]>(
    []
  );

  const [wirelessList, setWirelessList] = useState<IResumedWireless[]>([]);

  const [
    confirmUpdateAssociatedWireless,
    setShowConfirmUpdateAssociatedWireless
  ] = useState(false);

  const [
    isLoadingUpdateAssociatedWirelessCaptive,
    setIsLoadingUpdateAssociatedWirelessCaptive
  ] = useState(false);

  const isFormEquals = useArrayCompare(
    associatedWireless ?? [],
    selectedWireless,
    ['id', 'name']
  );

  const getWireless = async () => {
    return api.wireless.get();
  };

  const {
    isFetchedAfterMount: isFetchedWirelessList,
    refetch: refecthWirelessList
  } = useQuery(
    ['wirelessToAssociateQuery', defaultCaptivePortal],
    getWireless,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: { wireless: IResumedWireless[] } }) => {
        const wirelessListFormatted = data.wireless.map((wireless) => {
          wireless.frequency =
            frequencyFormat[wireless.frequency as keyof IFrequencyFormat];

          return wireless;
        });

        setWirelessList(wirelessListFormatted);

        setAssociatedWireless(
          wirelessListFormatted.filter((w) => w.is_captive_portal_enabled)
        );

        setSelectedWireless(
          wirelessListFormatted.filter((w) => w.is_captive_portal_enabled)
        );
      }
    }
  );

  const getNotAssociatedWireless = () => {
    const selectedWirelessSet = new Set(selectedWireless.map((w) => w.id));

    const disassociatedWirelessList = [
      ...wirelessList.filter(
        (wireless) => !selectedWirelessSet.has(wireless.id)
      )
    ];

    return disassociatedWirelessList.filter((selectedWirelessToDisassociate) =>
      associatedWireless.find(
        (wireless) =>
          wireless.id === selectedWirelessToDisassociate.id &&
          wireless.is_captive_portal_enabled
      )
    );
  };

  const getAssociatedWireless = () => {
    return selectedWireless.filter(
      (selectedWirelessToAssociate) =>
        !associatedWireless.find(
          (wireless) =>
            wireless.id === selectedWirelessToAssociate.id &&
            wireless.is_captive_portal_enabled
        )
    );
  };

  const putUpdateAssociatedWireless = () => {
    const data: IUpdateAssociatedWirelessCaptivePortal = {
      wireless_list: []
    };

    const changedAssociatedWireless = getAssociatedWireless();

    const changedDisassociatedWireless = getNotAssociatedWireless();

    if (changedAssociatedWireless.length > 0) {
      data.wireless_list.push({
        ids: changedAssociatedWireless.map((wireless) => wireless.id),
        data: {
          is_captive_portal_enabled: true
        }
      });
    }

    if (changedDisassociatedWireless.length > 0) {
      data.wireless_list.push({
        ids: changedDisassociatedWireless.map((wireless) => wireless.id),
        data: {
          is_captive_portal_enabled: false
        }
      });
    }
    return api.wireless.putAssociateWirelessCaptivePortal(data);
  };

  const changedWireless = [
    ...getAssociatedWireless(),
    ...getNotAssociatedWireless()
  ];

  const checkIfWirelessHasDevices = () => {
    return changedWireless.some((wireless) => wireless.devices_ids.length > 0);
  };

  const getListOfIdsAssociatedWithWireless = (
    wirelessToGetIds: IResumedWireless[]
  ) => {
    const devicesIdsAssociatedToWireless = [] as string[];

    wirelessToGetIds.map((wireless) => {
      devicesIdsAssociatedToWireless.push(...wireless.devices_ids);
      return wireless;
    });

    return _.uniq(devicesIdsAssociatedToWireless);
  };

  const generatePluralOrSingular = () => {
    const hasMoreThanOneDevice =
      getListOfIdsAssociatedWithWireless(changedWireless).length > 1;

    if (hasMoreThanOneDevice) {
      return t('CONFIGURANDO EQUIPAMENTOS');
    }

    return t('CONFIGURANDO EQUIPAMENTO');
  };

  const updateAssociatedWirelessMutation = useMutation(
    'updateAssociatedWirelessCaptive',
    putUpdateAssociatedWireless,
    {
      onSuccess: () => {
        refecthWirelessList();
        addToast(
          'success',
          t('As alterações em Wireless Vinculadas estão sendo aplicadas')
        );
        if (checkIfWirelessHasDevices()) {
          triggerNotificationAnimationWithText(generatePluralOrSingular());
        }
        setIsLoadingUpdateAssociatedWirelessCaptive(false);
      },
      onError: () => {
        setIsLoadingUpdateAssociatedWirelessCaptive(false);
      }
    }
  );

  const isFetchedCaptiveAndHasNotMode =
    !isLoadingUpdateAssociatedWirelessCaptive &&
    isFetchedCaptivePortal &&
    (isNull(defaultCaptivePortal) || isNull(defaultCaptivePortal.mode));

  const isFetchedWirelessAndCaptive =
    isFetchedWirelessList &&
    isFetchedCaptivePortal &&
    !isLoadingUpdateAssociatedWirelessCaptive;

  const isFetchedWirelessAndCaptiveAndEmptyWirelessList =
    isFetchedWirelessAndCaptive && wirelessList.length === 0;

  const editAssociateWirelessOfCaptivePortal = () => {
    setIsLoadingUpdateAssociatedWirelessCaptive(true);
    eventGA(
      'Captive Portal',
      'Click',
      'Tentar salvar o associação de wireless com captive portal'
    );
    updateAssociatedWirelessMutation.mutate();
  };

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('security', t('Segurança'), 'left', 0),
    generateColumns('frequency', t('Frequência'), 'left', 0),
    generateColumns('isVisible', t('Visibilidade'), 'left', 0)
  ];

  const renderSkeletons = (numberOfSkeletons = 8) => {
    const skeletons = [<Skeleton height={50} className="mb-1" />];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 mx-2"
          key={`${i}-skeleton-table-wireless-associated`}>
          <Skeleton count={1} height={30} width={30} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
        </div>
      );
    }
    return skeletons;
  };

  const renderLabelFrequency = (frequency: string) => {
    if (frequency === 'dualband' || frequency === '2.4GHz | 5GHz') {
      return <span>2.4GHz | 5GHz</span>;
    }
    return <span>{frequency}GHz</span>;
  };

  const createRowToDisplay = (
    wirelessListToDisplay: IResumedWireless[]
  ): IResumedWirelessRow[] => {
    return wirelessListToDisplay.map((wireless) => {
      return {
        ...wireless,
        frequency: renderLabelFrequency(wireless.frequency),
        security: wireless.is_captive_portal_enabled
          ? t('Captive Portal')
          : securityFormat[wireless.security as keyof ISecurityFormat],
        isVisible: (
          <div>
            {wireless.enabled ? (
              <div>
                {wireless.hidden ? (
                  <Tag color="neutral-dark-4" outlined wide>
                    {t('Oculta')}
                  </Tag>
                ) : (
                  <Tag color="blue" outlined wide>
                    {t('Visível')}
                  </Tag>
                )}
              </div>
            ) : (
              <Tag color="lightblue" outlined wide>
                {t('Desabilitada')}
              </Tag>
            )}
          </div>
        )
      };
    });
  };

  if (isFetchedCaptiveAndHasNotMode) {
    return (
      <EmptyMessage
        height="calc(100vh - 380px)"
        minHeight="190px"
        disabled={!isFetchedWirelessList}
        title={t('Nenhuma visualização')}
        subtitle={
          <div>
            <span>{t('Acesse a aba')}</span>
            <span
              id="btnRedirectSetUpMethodCaptivePortal"
              onClick={() => setActiveTab(METHOD_TAB)}
              className={classNames(styles.defaultMethod, 'ml-1', 'text-bold')}
              aria-hidden="true">
              {t('Configurar método')}{' '}
            </span>
            <span>{t('e ative um dos métodos de Captive Portal')}</span>
          </div>
        }
        id="message-no-enabled-captive"
      />
    );
  }
  return (
    <div>
      <ConfirmModal
        showModal={confirmUpdateAssociatedWireless}
        isLoadingAction={isLoadingUpdateAssociatedWirelessCaptive}
        width="540px"
        height="260px"
        onCancel={() => {
          setShowConfirmUpdateAssociatedWireless(false);
          setSelectedWireless(associatedWireless);
        }}
        onConfirm={() => {
          editAssociateWirelessOfCaptivePortal();
          setShowConfirmUpdateAssociatedWireless(false);
        }}>
        {t(
          'Ao prosseguir com a ação, a forma de autenticação desta wireless será alterada e os clientes dessa rede serão desconectados.'
        )}
        <br />
        {t(
          'Este processo poderá levar alguns minutos até ser configurado. Deseja continuar?'
        )}
      </ConfirmModal>

      {isFetchedWirelessAndCaptiveAndEmptyWirelessList ? (
        <EmptyMessage
          height="calc(100vh - 380px)"
          minHeight="190px"
          title={t('Nenhuma wireless')}
          subtitle={t(
            'Ainda não há wireless disponível neste ambiente. Experimente adicionar uma nova'
          )}
          buttonText={t('Adicionar wireless')}
          id="btn-no-wireless-captive-portal"
          action={() => navigate('/wireless/add')}
        />
      ) : (
        <>
          <div className="d-flex flex-column">
            <div className="fit-width d-flex align-center">
              <span className="title-xl-base">{t('Wireless vinculadas')}</span>
            </div>
            <span className="mt-3 mb-7">
              {t(
                'Abaixo, você encontra as redes wireless vinculadas ao Captive Portal, que podem ser configuradas sempre que necessário. Ao realizar esta ação, você estará aplicando o método de Captive Portal na segurança das wireless selecionadas'
              )}
            </span>
          </div>
          {isFetchedWirelessAndCaptive ? (
            <Table
              columns={columns}
              rows={createRowToDisplay(wirelessList)}
              onSelectRows={(selectedRows: IResumedWireless[]) => {
                setSelectedWireless(selectedRows);
              }}
              selectedRows={selectedWireless}
            />
          ) : (
            renderSkeletons()
          )}
          <div className="d-flex align-center fit-width justify-end mt-4">
            {isFetchedWirelessAndCaptive ? (
              <Button
                type="submit"
                disabled={isFormEquals || !isFetchedWirelessAndCaptive}
                isLoading={isLoadingUpdateAssociatedWirelessCaptive}
                onClick={() => setShowConfirmUpdateAssociatedWireless(true)}
                id="btn-associate-wireless">
                {t('Salvar')}
              </Button>
            ) : (
              <Skeleton count={1} height={50} borderRadius={10} width={100} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AssociatedWireless;

/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import Select from 'ui-components/Select';
import { IOption } from './types';

interface IProps {
  stepMinute?: number;
  value: IOption;
  onChange: (value: IOption) => void;
  placeholder?: string;
  id?: string;
  isInvalid?: boolean;
}

const InputTime = ({
  stepMinute,
  onChange,
  value,
  placeholder,
  id,
  isInvalid
}: IProps) => {
  const [options, setOptions] = useState<IOption[]>([]);

  const updateOptions = () => {
    const newOptions = [];

    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += stepMinute!) {
        // eslint-disable-next-line no-continue
        if (hours === 0 && minutes === 0) continue;

        let label = '';
        if (hours > 0) {
          label += `${hours}h `;
        }
        if (minutes > 0) {
          label += `${String(minutes).padStart(2, '0')}min`;
        }

        // Calculando o timestamp com base nas horas e minutos
        const newValue = new Date(0, 0, 0, hours, minutes).getTime();

        newOptions.push({
          id: newValue.toString(),
          label,
          value: newValue.toString()
        });

        if (hours === 23 && minutes === 55) break;
      }
    }

    setOptions(newOptions);
  };

  useEffect(() => {
    updateOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      onChange={(newValue) => {
        const option = options.find((item) => item.id === newValue);
        if (option) {
          option.value = Number(option.value);
          onChange(option);
        }
      }}
      options={options}
      placeholder={placeholder}
      value={value ? value.value : undefined}
      id={id}
      isInvalid={isInvalid}
      alignItems="center"
      variant="secundary"
    />
  );
};

InputTime.defaultProps = {
  stepMinute: 5,
  placeholder: '',
  id: '',
  isInvalid: false
};

export { InputTime };
